import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Field, formValues, reduxForm } from 'redux-form';
import { required } from '../../../../utils/validators';
import Card from '../../../components/Card/Card';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Input from '../../../components/Input/Input';
import Textarea from '../../../components/TextArea/TextArea';
import styles from './CreateSnippetForm.module.css';

import ENDPOINTS from '../../../../constants/endpoints';
import ROUTES from '../../../../constants/routes';
import { SNIPPET_TYPES } from '../../../../constants/snippets';
import Multiselect from '../../../components/MultiSelect/MultiSelect';

function CreateSnippetForm({ handleSubmit, onSubmit, initialValues, type }) {
  const navigate = useNavigate();
  const parameters = useParams();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.section}>
        <Card title="Snippet Details">
          <Field
            placeholder="Text of Snippet"
            validate={[required]}
            type="text"
            name="name"
            label="Text of Snippet"
            initialState={initialValues.name}
            component={Textarea}
          />
          <Field
            placeholder="Description"
            validate={[required]}
            type="text"
            name="description"
            label="Description"
            component={Input}
          />
          <Field
            placeholder="Group"
            validate={[required]}
            name="group_snippet_id"
            isSingle
            async
            url={`${ENDPOINTS.DATA.SNIPPET_GROUP}`}
            label="Group"
            component={Multiselect}
          />
          <button
            type="button"
            onClick={() => navigate(ROUTES.ADMIN.CREATE_SNIPPET_GROUP)}
            className="button link"
          >
            Add New Snippet Group
          </button>
          {parameters.id ? (
            initialValues && initialValues.type ? (
              <p className={styles.type}>Type: {initialValues.type.label}</p>
            ) : (
              ''
            )
          ) : (
            <Field
              placeholder="Type"
              validate={[required]}
              name="type"
              isSingle
              options={Object.entries(SNIPPET_TYPES).map((type) => ({
                label: type[1],
                value: type[0],
              }))}
              label="Type"
              component={Multiselect}
            />
          )}
          {type && type.value === 'label' && (
            <Field
              placeholder="Tooltip"
              type="text"
              name="tooltip"
              label="Tooltip"
              initialState={initialValues.tooltip}
              component={Textarea}
            />
          )}
          <Field
            name="is_active"
            label={() => 'Is active'}
            component={Checkbox}
          />
          <button className="button primary-button center">Save Snippet</button>
        </Card>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'create-snippet-form',
  enableReinitialize: true,
})(formValues('type')(CreateSnippetForm));

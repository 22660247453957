export const required = (value) => {
  if (!value || String(value).trim().length === 0) return 'Field is required';
};

export const wholeNumber = (value) => {
  if (!/^-*\d+$/.test(String(value))) {
    return 'Must be whole numeric value';
  }
};

export const number = (value) => {
  if (!/^[+-]?(\d+\.?\d*|\.\d+)$/.test(String(value))) {
    return 'Value must be numeric';
  }
};

export const numberOrEmpty = (value) => {
  if (!value) return;
  if (!/^[+-]?(\d+\.?\d*|\.\d+)$/.test(String(value))) {
    return 'Value must be numeric';
  }
};

export const percent = (value) => {
  if (+value < 0 || +value > 100) {
    return 'Value must be in range between 0 and 100';
  }
};

export const positivePercent = (value) => {
  if (+value <= 0 || +value > 100) {
    return 'Value must be in greater than 0 and less than 100';
  }
};

export const positiveNumber = (value) => {
  if (+value > 0) return;
  return 'Number must be greater than 0';
};

export const nonNegativeNumber = (value) => {
  if (+value >= 0) return;
  return 'Number must be greater or equal to 0';
};

export const positiveNumberOrEmpty = (value) => {
  if (!value || +value > 0) return;
  return 'Number must be greater than 0';
};

export const notEmpty = (value) => {
  if (!value || value.length === 0) {
    return 'At least one item must be chosen';
  }
};

export const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password ? 'Passwords do not match' : undefined;

export const newPasswordsMustMatch = (value, allValues) =>
  value !== allValues.new_password
    ? 'New password and confirm password fields do not match'
    : undefined;

import React, { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import swal from '@sweetalert/with-react';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import {
  createMarket,
  deleteMarket,
  getMarkets,
  updateMarket,
} from '../../../redux/actions/markets';
import {
  formatDollarValues,
  formatNumberWithComma,
  formatPercentValues,
} from '../../../utils/formatters';
import Dialog from '../../components/Dialog/Dialog';
import FormDialog from '../../components/FormDialog/FormDialog';
import Pagination from '../../components/Pagination/Pagination';
import AddMarketForm from '../../forms/AddMarketForm/AddMarketForm';
import styles from './MarketsPage.module.css';
import { getSnippetBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';
import MarketSearchForm from '../../forms/MarketSearchForm/MarketSearchForm';
import MarketFilterForm from '../../forms/MarketFilterForm/MarketFilterForm';
import { usePrevious } from '../../../hooks/usePrevious';

function MarketsPage() {
  const currentItems = useSelector((state) => state.markets.markets);
  const snippets = useSelector((state) => state.snippets.snippets);
  const [sortBy, setSortBy] = useState({});
  const total = useSelector((state) => state.markets.total);
  const [selectedPage, setSelectedPage] = useState(0);
  const isFirstRender = useRef(true);
  const navigate = useNavigate();
  const [ITEMS_PER_PAGE, setItemsPerPage] = useState(20);
  const [filters, setFilters] = useState({});
  const previous = usePrevious({ filters, selectedPage });
  const dispatch = useDispatch();
  const cashRule = useSelector(
    (state) => state.auth.user.color_data_cash_return
  )[0];
  const grossUpRule = useSelector(
    (state) => state.auth.user.color_data_gross_yield
  )[0];

  const getMarketsData = (page, onSuccess) => {
    const filtersData = {};
    for (const [key, value] of Object.entries(filters)) {
      if (Array.isArray(value) && value.length > 0) {
        filtersData[`filters[${key}]`] = value
          .map((item) => item.value)
          .join(',');
      } else if (value) {
        filtersData[`filters[${key}]`] = value;
      }
    }
    dispatch(
      getMarkets({
        page: page + 1,
        per_page: ITEMS_PER_PAGE,
        ...filtersData,
        ...sortBy,
        onSuccess,
      })
    );
  };

  useEffect(() => {
    if (
      previous &&
      selectedPage === previous.selectedPage &&
      filters !== previous.filters &&
      selectedPage !== 0
    ) {
      setSelectedPage(0);
      navigate(location.pathname);
    } else {
      getMarketsData(selectedPage);
    }
  }, [selectedPage, filters]);

  useEffect(() => {
    setSelectedPage(0);
    getMarketsData(0);
  }, [ITEMS_PER_PAGE]);

  useEffect(() => {
    if (!isFirstRender.current) {
      getMarketsData(selectedPage);
    } else {
      isFirstRender.current = false;
    }
  }, [sortBy]);

  const handleSort = (field) => {
    setSortBy({
      ...sortBy,
      [`sort[${field}]`]:
        sortBy[`sort[${field}]`] === 'asc'
          ? 'desc'
          : sortBy[`sort[${field}]`] === 'desc'
          ? undefined
          : 'asc',
    });
  };

  const handlePageClick = (event) => {
    setSelectedPage(event.selected);
  };

  const addMarket = (values, onSuccess) => {
    dispatch(
      createMarket({
        payload: {
          name: values.name,
          states: values.states.map((state) => state.value),
          // cities: values.cities.map((city) => city.value),
        },
        onSuccess,
      })
    );
  };

  const updateMarketData = (id, values, onSuccess) => {
    dispatch(
      updateMarket({
        payload: {
          name: values.name,
          states: values.states.map((state) => state.value),
          // cities: values.cities.map((city) => city.value),
        },
        id,
        page: selectedPage + 1,
        per_page: ITEMS_PER_PAGE,
        onSuccess,
      })
    );
  };

  const editMarket = (market) => {
    confirmAlert({
      overlayClassName: 'overlay',
      customUI: ({ onClose }) => (
        <FormDialog
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_MARKET_FORM.EDIT_MARKET
          )}
          onClose={onClose}
          form={
            <AddMarketForm
              editMode
              onSubmit={(values) => {
                updateMarketData(market.id, values, () => {
                  getMarketsData(selectedPage, onClose);
                });
              }}
              initialValues={{
                name: market.name,
                cities: market.cities.map((city) => ({
                  label: city.city_name,
                  value: city.city_id,
                  state: city.state_id,
                })),
                states: market.states.map((state) => ({
                  label: state.name,
                  value: state.state_id,
                })),
              }}
            />
          }
        />
      ),
    });
  };

  const addNewMarket = () => {
    confirmAlert({
      overlayClassName: 'overlay',
      closeOnClickOutside: false,
      customUI: ({ onClose }) => (
        <FormDialog
          onClose={() => {
            swal({
              closeOnClickOutside: false,
              closeOnEsc: false,
              content: (
                <p>Do you really want to leave without saving changes?</p>
              ),
              buttons: {
                cancel: {
                  text: 'Cancel',
                  visible: true,
                  className: 'button outlined-secondary-button -thin',
                  closeModal: true,
                },
                confirm: {
                  text: 'OK',
                  visible: true,
                  className: 'button primary-button -thin',
                  closeModal: true,
                },
              },
              icon: 'warning',
            }).then(function (isConfirm) {
              if (isConfirm) {
                onClose();
              }
            });
          }}
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_MARKET_FORM.CREATE_MARKET_TITLE
          )}
          form={
            <AddMarketForm
              onSubmit={(values) => {
                addMarket(values, () => {
                  getMarketsData(selectedPage, onClose);
                });
              }}
              initialValues={{ name: '', cities: [], states: [] }}
            />
          }
        />
      ),
    });
  };

  const removeMarket = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <Dialog
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_MARKET_FORM.DELETE_MARKET_TITLE
          )}
          text={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_MARKET_FORM.DELETE_MARKET_SUBTITLE
          )}
          buttons={[
            {
              type: 'button',
              text: 'Delete',
              onClick: () => {
                dispatch(
                  deleteMarket({
                    id,
                    page: selectedPage + 1,
                    per_page: ITEMS_PER_PAGE,
                  })
                );
                onClose();
              },
              className: 'secondary-button',
            },
            {
              type: 'button',
              text: 'Cancel',
              onClick: onClose,
              className: 'secondary-button',
            },
          ]}
        />
      ),
    });
  };

  const openFilterDialog = () => {
    confirmAlert({
      overlayClassName: 'overlay',
      closeOnClickOutside: false,
      customUI: ({ onClose }) => (
        <FormDialog
          onClose={onClose}
          title={getSnippetBySlug(snippets, SNIPPETS.MARKET_FILTER_FORM.TITLE)}
          form={
            <MarketFilterForm
              initialValues={filters}
              onSubmit={(values) => {
                setFilters({ ...filters, ...values });
                onClose();
              }}
            />
          }
        />
      ),
    });
  };

  const numberOfFilters = Object.values(
    Object.fromEntries(
      Object.entries(filters).filter(([key]) => {
        return !key.endsWith('to') && key !== 'name';
      })
    )
  ).filter((item) => (Array.isArray(item) ? item.length > 0 : !!item)).length;

  const resetFilters = () => {
    setFilters(
      Object.fromEntries(
        Object.entries(filters).filter(([key]) => key === 'search')
      )
    );
  };

  return (
    <div>
      <div className={styles.header}>
        <h2 className={`subtitle ${styles.title}`}>
          {getSnippetBySlug(snippets, SNIPPETS.MARKETS_PAGE.TITLE)}
          <span className={styles.number}>- {total}</span>
        </h2>
        <button
          type="button"
          data-cy="add-new-market-button"
          onClick={() => {
            addNewMarket();
          }}
          className="button withImg primary-button -thin"
        >
          <span className="material-icons">add</span>
          <span>
            {getSnippetBySlug(
              snippets,
              SNIPPETS.MARKETS_PAGE.ADD_NEW_MARKET_BUTTON
            )}
          </span>
        </button>
      </div>
      <div className={styles.filters}>
        <div>
          <MarketSearchForm
            onSubmit={(values) => {
              setFilters({ ...filters, ...values });
            }}
          />
        </div>
        <button
          type="button"
          onClick={() => {
            openFilterDialog();
          }}
          className={`button withImg primary-button ${styles.filterButton}`}
        >
          <span className="material-icons">filter_alt</span>
          <span>
            {getSnippetBySlug(snippets, SNIPPETS.PROPERTIES_PAGE.FILTER_BUTTON)}
          </span>
          {numberOfFilters > 0 && (
            <span className={`breadcrumb filled-warning ${styles.number}`}>
              {numberOfFilters}
            </span>
          )}
        </button>
        <button
          type="button"
          style={{ visibility: numberOfFilters ? 'visible' : 'hidden' }}
          onClick={resetFilters}
          className="button primary-button"
        >
          {getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTIES_PAGE.RESET_FILTER_BUTTON
          )}
        </button>
      </div>
      {total === 0 ? (
        'No markets yet'
      ) : (
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th colSpan={2} className={`subheader ${styles.withBorder}`}>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.MARKETS_PAGE.MARKET_DATA_SUBHEADER
                  )}
                </th>
                <th colSpan={11} className={`subheader ${styles.withBorder}`}>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.MARKETS_PAGE.AVERAGE_SUBHEADER
                  )}
                </th>
                <th className="subheader" />
              </tr>
              <tr>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.MARKET_PAGE_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('name')}>
                      {(sortBy['sort[name]'] === 'asc' ||
                        !sortBy['sort[name]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[name]'] === 'desc' ||
                        !sortBy['sort[name]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th className={styles.withBorder}>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.PROPERTY_COUNT_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('properties_count')}>
                      {(sortBy['sort[properties_count]'] === 'asc' ||
                        !sortBy['sort[properties_count]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_count]'] === 'desc' ||
                        !sortBy['sort[properties_count]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_LIST_PRICE_COL
                      )}
                    </span>
                    <div
                      onClick={() => handleSort('properties_avg_list_price')}
                    >
                      {(sortBy['sort[properties_avg_list_price]'] === 'asc' ||
                        !sortBy['sort[properties_avg_list_price]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_list_price]'] === 'desc' ||
                        !sortBy['sort[properties_avg_list_price]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_BEDROOMS_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('properties_avg_bedrooms')}>
                      {(sortBy['sort[properties_avg_bedrooms]'] === 'asc' ||
                        !sortBy['sort[properties_avg_bedrooms]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_bedrooms]'] === 'desc' ||
                        !sortBy['sort[properties_avg_bedrooms]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_BATHROOMS_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('properties_avg_bathrooms')}>
                      {(sortBy['sort[properties_avg_bathrooms]'] === 'asc' ||
                        !sortBy['sort[properties_avg_bathrooms]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_bathrooms]'] === 'desc' ||
                        !sortBy['sort[properties_avg_bathrooms]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_PROPERTY_TAXES_COL
                      )}
                    </span>
                    <div
                      onClick={() =>
                        handleSort('properties_avg_property_taxes')
                      }
                    >
                      {(sortBy['sort[properties_avg_property_taxes]'] ===
                        'asc' ||
                        !sortBy['sort[properties_avg_property_taxes]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_property_taxes]'] ===
                        'desc' ||
                        !sortBy['sort[properties_avg_property_taxes]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_REVENUE_COL
                      )}
                    </span>
                    <div
                      onClick={() =>
                        handleSort('properties_avg_projected_rent')
                      }
                    >
                      {(sortBy['sort[properties_avg_projected_rent]'] ===
                        'asc' ||
                        !sortBy['sort[properties_avg_projected_rent]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_projected_rent]'] ===
                        'desc' ||
                        !sortBy['sort[properties_avg_projected_rent]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_DAILY_RATE
                      )}
                    </span>
                    <div
                      onClick={() =>
                        handleSort('properties_avg_average_daily_rate')
                      }
                    >
                      {(sortBy['sort[properties_avg_average_daily_rate]'] ===
                        'asc' ||
                        !sortBy['sort[properties_avg_average_daily_rate]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_average_daily_rate]'] ===
                        'desc' ||
                        !sortBy['sort[properties_avg_average_daily_rate]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_OCCUPANCY_COL
                      )}
                    </span>
                    <div
                      onClick={() =>
                        handleSort('properties_avg_projected_occupancy')
                      }
                    >
                      {(sortBy['sort[properties_avg_projected_occupancy]'] ===
                        'asc' ||
                        !sortBy[
                          'sort[properties_avg_projected_occupancy]'
                        ]) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_projected_occupancy]'] ===
                        'desc' ||
                        !sortBy[
                          'sort[properties_avg_projected_occupancy]'
                        ]) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_NOI_COL
                      )}
                    </span>
                    <div
                      onClick={() =>
                        handleSort('properties_avg_net_operating_income')
                      }
                    >
                      {(sortBy['sort[properties_avg_net_operating_income]'] ===
                        'asc' ||
                        !sortBy[
                          'sort[properties_avg_net_operating_income]'
                        ]) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_net_operating_income]'] ===
                        'desc' ||
                        !sortBy[
                          'sort[properties_avg_net_operating_income]'
                        ]) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_CAP_RATE
                      )}
                    </span>
                    <div onClick={() => handleSort('properties_avg_cap_rate')}>
                      {(sortBy['sort[properties_avg_cap_rate]'] === 'asc' ||
                        !sortBy['sort[properties_avg_cap_rate]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_cap_rate]'] === 'desc' ||
                        !sortBy['sort[properties_avg_cap_rate]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_GROSS
                      )}
                    </span>
                    <div
                      onClick={() => handleSort('properties_avg_gross_yield')}
                    >
                      {(sortBy['sort[properties_avg_gross_yield]'] === 'asc' ||
                        !sortBy['sort[properties_avg_gross_yield]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_gross_yield]'] === 'desc' ||
                        !sortBy['sort[properties_avg_gross_yield]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th className={styles.withBorder}>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.MARKETS_PAGE.AVERAGE_CASH_ON_CASH_COL
                      )}
                    </span>
                    <div
                      onClick={() =>
                        handleSort('properties_avg_cash_on_cash_return')
                      }
                    >
                      {(sortBy['sort[properties_avg_cash_on_cash_return]'] ===
                        'asc' ||
                        !sortBy[
                          'sort[properties_avg_cash_on_cash_return]'
                        ]) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[properties_avg_cash_on_cash_return]'] ===
                        'desc' ||
                        !sortBy[
                          'sort[properties_avg_cash_on_cash_return]'
                        ]) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className={styles.marketName}>
                        <span>{item.name}</span>
                        {item.property_count < 3 && (
                          <span
                            className={`material-icons ${styles.info}`}
                            data-tip
                            data-for="count-warning"
                          >
                            info
                          </span>
                        )}
                        <ReactTooltip
                          id="count-warning"
                          place="top"
                          effect="solid"
                        >
                          {getSnippetBySlug(
                            snippets,
                            SNIPPETS.MARKETS_PAGE.WARNING_TOOLTIP
                          )}
                        </ReactTooltip>
                      </div>
                    </td>
                    <td className={styles.withBorder}>
                      {formatNumberWithComma(item.property_count)}
                    </td>
                    <td>{formatDollarValues(item.average_list_price)}</td>
                    <td>{formatNumberWithComma(item.average_bedrooms || 0)}</td>
                    <td>
                      {formatNumberWithComma(item.average_bathrooms || 0)}
                    </td>
                    <td>{formatDollarValues(item.average_property_taxes)}</td>
                    <td>{formatDollarValues(item.average_revenue)}</td>
                    <td>{formatDollarValues(item.average_daily_rate)}</td>
                    <td>{formatPercentValues(item.average_occupancy)}</td>
                    <td>{formatDollarValues(item.average_noi)}</td>
                    <td>
                      <span
                        className={`${
                          item.average_cap_rate < 5
                            ? 'filled-error'
                            : item.average_cap_rate < 7
                            ? 'filled-warning'
                            : 'filled-success'
                        } breadcrumb`}
                      >
                        {formatPercentValues(item.average_cap_rate)}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`${
                          item.average_gross_yield < +grossUpRule.red
                            ? 'filled-error'
                            : item.average_gross_yield < +grossUpRule.yellow
                            ? 'filled-warning'
                            : 'filled-success'
                        } breadcrumb`}
                      >
                        {formatPercentValues(item.average_gross_yield)}
                      </span>
                    </td>
                    <td className={styles.withBorder}>
                      <span
                        className={`${
                          item.average_cash_on_cash_return < +cashRule.red
                            ? 'filled-error'
                            : item.average_cash_on_cash_return <
                              +cashRule.yellow
                            ? 'filled-warning'
                            : 'filled-success'
                        } breadcrumb`}
                      >
                        {formatPercentValues(item.average_cash_on_cash_return)}
                      </span>
                    </td>
                    <td className={styles.buttons}>
                      <button
                        type="button"
                        className="button action-button"
                        onClick={() => navigate(`/markets/${item.id}`)}
                      >
                        <span className="material-icons">visibility</span>
                      </button>
                      <button
                        type="button"
                        className="button action-button"
                        onClick={() => editMarket(item)}
                      >
                        <span className="material-icons">edit</span>
                      </button>
                      <button
                        type="button"
                        className="button action-button"
                        onClick={() => removeMarket(item.id)}
                      >
                        <span className="material-icons">delete</span>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      {total !== 0 && (
        <Pagination
          onPageChange={handlePageClick}
          selectedPage={selectedPage}
          numberPerPageOptions={[20, 30, 50, 100]}
          numberPerPage={ITEMS_PER_PAGE}
          onNumberPerPageChanged={setItemsPerPage}
          pageCount={Math.ceil(total / ITEMS_PER_PAGE)}
        />
      )}
    </div>
  );
}

export default MarketsPage;

import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Range from '../../components/Range/Range';
import styles from './SetColorsForm.module.css';
import { getSnippetBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';

function SetColorsForm({ handleSubmit, onSubmit, form, max, marks }) {
  const snippets = useSelector((state) => state.snippets.snippets);
  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values, form))}>
      <div className={styles.rangeContainer}>
        <Field
          name={`${form}.colors`}
          className={styles.colors}
          component={Range}
          props={{
            count: 3,
            min: 0,
            step: 0.1,
            marks,
            dotsColor: 'var(--color-primary)',
            color: [
              'var(--color-error)',
              'var(--color-warning)',
              'var(--color-primary-dark)',
            ],
            max,
            formatter: (value) => `${value}%`,
          }}
        />
      </div>
      <button className={`button primary-button ${styles.saveButton}`}>
        {getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.CASH_ON_CASH_1_RULE_BUTTON
        )}
      </button>
    </form>
  );
}

export default reduxForm({})(SetColorsForm);

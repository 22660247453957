import { LOG_OUT, SET_USER } from '../action-types/auth';
import { UPDATE_AVATAR } from '../action-types/profile';

const initialState = {
  user: undefined,
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          thumb: action.src,
        },
      };
    case LOG_OUT:
      localStorage.removeItem('token');
      return {
        ...state,
        user: null,
      };
    case SET_USER:
      if (!action.data) {
        return {
          ...state,
          user: null,
        };
      }
      if (action.data.token) {
        localStorage.setItem('token', action.data.token);
      }
      return {
        ...state,
        user: action.data.user,
      };
    default:
      return state;
  }
};

export default formReducer;

import ENDPOINTS from '../../constants/endpoints';
import {
  ADD_VARIABLE_COST,
  REMOVE_VARIABLE_COST,
  SET_CITIES,
  SET_CLIENTS,
  SET_STATES,
  SET_VARIABLE_COSTS,
} from '../action-types/data';
import { apiAction } from './api';

export const getStates = () => {
  return apiAction({
    url: ENDPOINTS.DATA.STATES,
    onSuccess: (dispatch, _, data) => dispatch(setStates(data.data)),
    method: 'GET',
  });
};

export const getClients = (parameters) => {
  return apiAction({
    url: ENDPOINTS.USER.CLIENTS,
    method: 'GET',
    onSuccess: (dispatch, _, data) =>
      dispatch(
        setClients({
          data: data.data,
          total: data.meta.total,
        })
      ),
    data: parameters,
  });
};

export const getFluidValues = (variableExpenseId, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.FLUID_VALUES(variableExpenseId),
    method: 'GET',
    onSuccess: (_dispatch, _form, data) => {
      onSuccess(data.states_value);
    },
  });
};

export const getCities = (data) => {
  return apiAction({
    url: ENDPOINTS.DATA.CITIES,
    onSuccess: (dispatch, _, data) => dispatch(setCities(data.data)),
    method: 'GET',
    data,
  });
};

export const getVariableCosts = (parameters) => {
  return apiAction({
    url: ENDPOINTS.DATA.VARIABLE_COSTS,
    onSuccess: (dispatch, _, data) => dispatch(setVariableCosts(data.data)),
    method: 'GET',
    data: parameters,
  });
};

export const setStates = (data) => {
  return {
    type: SET_STATES,
    data,
  };
};

export const setClients = (data) => {
  return {
    type: SET_CLIENTS,
    data,
  };
};

export const setCities = (data) => {
  return {
    type: SET_CITIES,
    data,
  };
};

export const setVariableCosts = (data) => {
  return {
    type: SET_VARIABLE_COSTS,
    data,
  };
};

export const removeVariableCost = (value) => {
  return {
    type: REMOVE_VARIABLE_COST,
    value,
  };
};

export const addVariableCost = (value) => {
  return {
    type: ADD_VARIABLE_COST,
    value,
  };
};

import React from 'react';
import DatePicker from 'react-datepicker';
import styles from './DatePicker.module.css';

function DateInput({ input, meta: { touched, error }, placeholder }) {
  return (
    <div className={styles.fieldContainer}>
      <DatePicker
        {...input}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText={placeholder}
        className={styles.input}
        dateFormat="MM/dd/yyyy"
        selected={input.value ? input.value : null}
      />
      {touched && error && <span className="field-error-label">{error}</span>}
    </div>
  );
}

export default DateInput;

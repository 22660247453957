import React from 'react';
import placeholder from 'public/assets/images/avatar-placeholder.jpg';
import styles from './Avatar.module.css';

function Avatar({ src }) {
  return (
    <div className={styles.avatar}>
      <img src={src || placeholder} alt="Avatar" />
    </div>
  );
}

export default Avatar;

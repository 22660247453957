import { SET_PROPERTIES } from '../action-types/properties';

const initialState = {
  properties: null,
  total: null,
};

const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROPERTIES:
      return {
        ...state,
        properties: action.properties,
        total: action.count,
      };
    default:
      return state;
  }
};

export default propertiesReducer;

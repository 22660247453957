import ENDPOINTS from '../../constants/endpoints';
import { LOG_OUT, SET_USER } from '../action-types/auth';
import { apiAction } from './api';
import { setGeneralSuccessMessage, setSuccessSubmitMessage } from './form';
import { onFailure } from './general';

export const register = (data, form) => {
  return apiAction({
    url: ENDPOINTS.AUTH.REGISTER,
    onSuccess: onRegistrationSuccess,
    onFailure,
    method: 'POST',
    data,
    form,
  });
};

export const login = (data, form, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.AUTH.LOGIN,
    onSuccess: onLoginSuccess,
    onFailure,
    method: 'POST',
    data,
    form,
  });
};

export const forgotPassword = (data, form) => {
  return apiAction({
    url: ENDPOINTS.AUTH.FORGOT_PASSWORD,
    onSuccess: onResetPasswordSuccess,
    onFailure,
    method: 'POST',
    data,
    form,
  });
};

export const resetPassword = (data, form) => {
  return apiAction({
    url: ENDPOINTS.AUTH.RESET_PASSWORD,
    onSuccess: onChangePasswordSuccess,
    onFailure,
    method: 'POST',
    data,
    form,
  });
};

export const getUser = () => {
  return apiAction({
    url: ENDPOINTS.USER.GET_INFO,
    onSuccess: (dispatch, _, data) => {
      getUserSuccess(dispatch, _, data);
    },
    onFailure: (dispatch) => dispatch(setUser(null)),
    method: 'GET',
  });
};

export const getProfile = (id, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.USER.GET_PROFILE(id),
    method: 'GET',
    onSuccess: (_, form, data) => {
      onSuccess && onSuccess(data.data);
    },
  });
};

export const deleteUserById = (id, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.USER.DELETE_BY_ID(id),
    method: 'DELETE',
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(setGeneralSuccessMessage('User has been deleted successfully'));
    },
  });
};

export const removeUser = () => {
  return apiAction({
    url: ENDPOINTS.USER.DELETE_USER,
    onSuccess: onDeleteUserSuccess,
    method: 'DELETE',
  });
};

export const setUser = (data) => ({
  type: SET_USER,
  data,
});

export const logOut = () => ({
  type: LOG_OUT,
});

// handlers
export const onDeleteUserSuccess = (dispatch) => {
  dispatch(logOut());
};

export const onRegistrationSuccess = (dispatch, form, data) => {
  dispatch(
    setSuccessSubmitMessage(
      form,
      `Account activation link sent to your email address: ${data.data.user.email}. Please follow the link inside to continue.`
    )
  );
};

export const onChangePasswordSuccess = (dispatch, form) => {
  dispatch(setSuccessSubmitMessage(form, 'Password changed successfully.'));
};

export const onResetPasswordSuccess = (dispatch, form) => {
  dispatch(
    setSuccessSubmitMessage(
      form,
      'Email with further instructions has been sent successfully'
    )
  );
};

export const onLoginSuccess = (dispatch, _, data) => {
  dispatch(setUser(data.data));
};

export const getUserSuccess = (dispatch, _, data) => {
  dispatch(setUser({ user: data.data }));
};

import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, formValues, getFormValues, reduxForm } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import CURRENCY_TYPES from '../../../constants/currency';
import ENDPOINTS from '../../../constants/endpoints';
import { usePrevious } from '../../../hooks/usePrevious';
import {
  addVariableCost,
  getVariableCosts,
  removeVariableCost,
} from '../../../redux/actions/data';
import {
  getCosts,
  getSum,
  removeExpenses,
} from '../../../redux/actions/properties';
import {
  formatNumberWithComma,
  removeCommasFromNumber,
} from '../../../utils/formatters';
import {
  number,
  percent,
  positiveNumber,
  required,
} from '../../../utils/validators';
import Input from '../../components/Input/Input';
import Multiselect from '../../components/MultiSelect/MultiSelect';
import styles from './VariableCostsForm.module.css';
import { getSnippetBySlug, getTooltipBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';

function VariableCostsForm({
  handleSubmit,
  onSubmit,
  form,
  change,
  market_id,
  property_id,
  variableCosts,
  selected_currency: selectedCurrency,
  selected_variable_cost: selectedVariableCost,
  projectedRent,
  currentPropertyId,
  listing_site_fees,
  repairs_maintenance,
  property_management_fee,
  zillow_url,
  state,
  editMode
}) {
  const [uploadFromAnotherPropertyForm, setUploadFromAnotherPropertyForm] =
    useState(false);
  const [isRecalculateVisible, setRecalculateVisible] = useState(false);
  const [mounted, setMounted] = useState(false);

  const defaultOptions = useSelector((state) => state.data.variableCosts)
  ?.filter((cost) => {
    return variableCosts &&
      variableCosts.costs &&
      variableCosts.costs.length > 0
      ? cost.key === 'custom' ||
          (cost.key !== 'listing_site_fees' &&
            cost.key !== 'repairs_maintenance' &&
            cost.key !== 'property_management_fee' &&
            !variableCosts.costs
              .map((cost) => cost.value)
              .includes(cost.key) &&
            cost.available)
      : cost.key !== 'listing_site_fees' &&
          cost.key !== 'repairs_maintenance' &&
          cost.key !== 'property_management_fee' &&
          cost.available;
  })
  ?.map((cost) => ({
    default: cost?.default,
    label: cost.name,
    value: cost.key,
    currency: cost.measurements,
    tooltip: cost.description,
    defaultValue: cost.default_value,
  }));

  // const selectedOptions = useSelector((state) => state.data.variableCosts)
  //   ?.filter((cost) => {
  //     return (
  //       cost.key !== 'listing_site_fees' &&
  //       cost.key !== 'repairs_maintenance' &&
  //       cost.key !== 'property_management_fee' &&
  //       cost.default &&
  //       cost.available
  //     );
  //   })
  //   ?.map((cost) => ({
  //     label: cost.name,
  //     value: cost.key,
  //     currency: cost.measurements,
  //     tooltip: cost.description,
  //     defaultValue: cost.default_value,
  //   }));

  const selectedOptions = [];

  const dispatch = useDispatch();
  const snippets = useSelector((state) => state.snippets.snippets);

  function addSelectedOptions(selectedOptions) {
    const data = selectedOptions
      .filter(
        (option) =>
          !variableCosts.costs.map((cost) => cost.value).includes(option.value)
      )
      .map((option) => ({
        ...option,
        month: Number(option.defaultValue).toFixed(2),
        year: Number(option.defaultValue * 12).toFixed(2),
      }));
    change('costs', [
      ...data,
      ...(variableCosts && variableCosts.costs ? variableCosts.costs : []),
    ]);
  }

  const uploadCosts = () => {
    dispatch(
      getCosts({
        id: property_id.value,
        onSuccess: (response) => {
          dispatch(
            removeExpenses(currentPropertyId, () => {
              const costs = response.custom_expenses.map((cost) => ({
                label: cost.name,
                value: cost.key,
                currency: cost.measurements,
                month: cost.value.toFixed(2),
                year:
                  cost.measurements === CURRENCY_TYPES.DOLLAR
                    ? (cost.value * 12).toFixed(2)
                    : ((projectedRent * cost.value) / 100).toFixed(2),
              }));
              change('costs', costs);
              recalculateSum(costs);
              setUploadFromAnotherPropertyForm(false);
            })
          );
        },
      })
    );
  };

  // const previous = usePrevious({ variableCosts, selectedOptions });

  useEffect(() => {
    if (
      selectedOptions &&
      selectedOptions.length > 0 &&
      variableCosts &&
      variableCosts.costs
    ) {
      addSelectedOptions(selectedOptions);
    }
  }, [selectedOptions]);

  // useEffect(() => {
  //   if (previous && !previous.variableCosts && variableCosts) {
  //     recalculateSum(variableCosts.costs);
  //   }
  // }, [variableCosts]);

  const recalculateSum = (costs) => {
    const customExpenses = costs.map((cost) => ({
      name: cost.value,
      value: cost.month,
      measurements: cost.currency,
    }));
    const data = {
      custom_expenses: customExpenses,
      property_management_fee: property_management_fee.month,
      listing_site_fees: listing_site_fees.month,
      repairs_maintenance: repairs_maintenance.month,
      projected_rent: projectedRent,
      onSuccess: (data) => {
        change('total.month', (data.sum_variable_expenses / 12).toFixed(2));
        change('total.year', data.sum_variable_expenses.toFixed(2));
        setRecalculateVisible(false);
      },
    };
    dispatch(getSum(data));
  };

  useEffect(() => {
    dispatch(getVariableCosts({ state, zillow_url }));
  }, []);

  useEffect(() => {
    if (defaultOptions?.length && !mounted && !editMode) {
      const defaultOptionsDefault = defaultOptions.filter(defaultOption => defaultOption?.default);

      change('costs', 
        defaultOptionsDefault.map(defaultOption => {
          if (defaultOption.value === 'custom') {
            return {
              ...defaultOption,
              currency: defaultOption.value,
              month: Number(0).toFixed(2),
              year: Number(0).toFixed(2),
            }
          }

          return {
            ...defaultOption,
            month: Number(defaultOption.defaultValue || 0).toFixed(2),
            year: Number(defaultOption.defaultValue * 12 || 0).toFixed(2),
          }
        })
      );
      change('selected_variable_cost', null);
      change('selected_currency', null);

      setMounted(true);
    }
  }, [defaultOptions]);

  useEffect(() => {
    if (selectedVariableCost) {
      addOption();
    }
  }, [selectedVariableCost]);

  useEffect(() => {
    if (
      selectedVariableCost &&
      selectedVariableCost.value === 'custom' &&
      selectedCurrency
    ) {
      addOption();
    }
  }, [selectedCurrency]);

  const addOption = () => {
    setRecalculateVisible(true);

    change('costs', [
      selectedVariableCost.value === 'custom'
        ? {
            ...selectedVariableCost,
            custom_id: new Date().getTime(),
            currency: selectedCurrency?.value ?? 'currency',
            month: Number(0).toFixed(2),
            year: Number(0).toFixed(2),
          }
        : {
            ...selectedVariableCost,
            month: Number(selectedVariableCost.defaultValue || 0).toFixed(2),
            year: Number(selectedVariableCost.defaultValue * 12 || 0).toFixed(
              2
            ),
          },
      ...variableCosts.costs,
    ]);
    change('selected_variable_cost', null);
    change('selected_currency', null);
    if (selectedVariableCost.value !== 'custom') {
      dispatch(removeVariableCost(selectedVariableCost.value));
    }
  };

  const onBlurFormatter = (value) => {
    if (value === '') {
      return '0.00';
    }
    return value.length > 0
      ? (+removeCommasFromNumber(value)).toFixed(2)
      : value;
  };

  const onFocusFormatter = (e) => {
    if (
      String(e.target.value).length > 0 &&
      Number.isInteger(+removeCommasFromNumber(e.target.value))
    ) {
      if (+e.target.value === 0) {
        change(e.target.name, '');
      } else {
        change(
          e.target.name,
          (+removeCommasFromNumber(e.target.value)).toFixed(0)
        );
      }
    }
  };

  const removeOption = (field) => {
    if (field?.custom_id) {
      change(
        'costs',
        variableCosts.costs.filter((cost) => cost?.custom_id !== field?.custom_id)
      );
    } else {
      change(
        'costs',
        variableCosts.costs.filter((cost) => cost?.id !== field?.id)
      );
    }
    dispatch(addVariableCost(field));
    setRecalculateVisible(true);
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit((values) => {
        onSubmit(values, form);
      })}
    >
      {uploadFromAnotherPropertyForm ? (
        <div className={styles.uploadForm}>
          <button
            type="button"
            className="button withImg outlined-secondary-button -thin"
            onClick={() => setUploadFromAnotherPropertyForm(false)}
          >
            <span className="material-icons">chevron_left</span>
            <span>Back</span>
          </button>
          <Field
            placeholder="Market"
            validate={[required]}
            name="market_id"
            onChange={() => {
              change('property_id', null);
            }}
            isSingle
            async
            url={`${ENDPOINTS.MARKETS.GET_MARKETS}`}
            label="Market"
            component={Multiselect}
          />
          <Field
            placeholder="Property"
            validate={[required]}
            name="property_id"
            isSingle
            disabled={!market_id}
            key={market_id && market_id.value}
            mappingFunc={(data) => ({
              id: data.key,
              name: data.value,
            })}
            async
            url={ENDPOINTS.PROPERTIES.PROPERTIES_BY_MARKET(
              market_id && market_id.value
            )}
            label="Property"
            component={Multiselect}
          />
          <button
            className="button primary-button center"
            type="button"
            disabled={!property_id || !market_id}
            onClick={uploadCosts}
          >
            Upload
          </button>
        </div>
      ) : (
        <>
          <div className={styles.selectors}>
            <div className={styles.fields}>
              <Field
                placeholder="Select variable expenses"
                name="selected_variable_cost"
                label="Variable Expenses"
                isSingle
                options={defaultOptions || []}
                component={Multiselect}
              />
              {selectedVariableCost && selectedVariableCost.value === 'custom' && (
                <Field
                  placeholder="Select currency"
                  name="selected_currency"
                  label="Expense Type"
                  isSingle
                  options={[
                    {
                      label: '$',
                      value: CURRENCY_TYPES.DOLLAR,
                    },
                    {
                      label: '%',
                      value: CURRENCY_TYPES.PERCENT,
                    },
                  ]}
                  component={Multiselect}
                />
              )}
              <button
                type="button"
                className="button outlined-secondary-button"
                onClick={() => setUploadFromAnotherPropertyForm(true)}
              >
                <span>Upload Data From Another Property</span>
              </button>
            </div>
          </div>
          {!selectedOptions ? (
            <p>Loading...</p>
          ) : (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Monthly</th>
                  <th>Annual</th>
                </tr>
              </thead>
              <tbody>
                {variableCosts &&
                variableCosts.costs &&
                variableCosts.costs.length > 0 ? (
                  <>
                    {variableCosts.costs.map((cost, index) => (
                      <tr key={`${cost.value}-${index}`}>
                        <td>
                          {cost.value === 'custom' ? (
                            <Field
                              placeholder="Name of Variable Expenses"
                              validate={[required]}
                              type="text"
                              className={styles.input}
                              name={`costs[${index}].label`}
                              component={Input}
                            />
                          ) : (
                            <span className={styles.labelWrapper}>
                              <span>{cost.label}</span>
                              {cost.tooltip && (
                                <span
                                  className={`material-icons ${styles.tooltip}`}
                                  data-tip
                                  data-for={`tooltip-${cost.value}`}
                                >
                                  info
                                </span>
                              )}
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            placeholder={
                              cost.currency === CURRENCY_TYPES.PERCENT
                                ? '0.00%'
                                : '$0.00'
                            }
                            validate={
                              cost.currency === CURRENCY_TYPES.PERCENT
                                ? [required, number, percent]
                                : [required, number, positiveNumber]
                            }
                            onChange={
                              cost.currency === CURRENCY_TYPES.DOLLAR
                                ? (e) => {
                                    change(
                                      `costs[${index}].year`,
                                      (
                                        +removeCommasFromNumber(
                                          e.target.value
                                        ) * 12
                                      ).toFixed(2)
                                    );
                                    setRecalculateVisible(true);
                                  }
                                : (e) => {
                                    change(
                                      `costs[${index}].year`,
                                      (projectedRent *
                                        +removeCommasFromNumber(
                                          e.target.value
                                        )) /
                                        100
                                    );
                                    setRecalculateVisible(true);
                                  }
                            }
                            prefix={
                              cost.currency === CURRENCY_TYPES.DOLLAR ? '$' : ''
                            }
                            suffix={
                              cost.currency === CURRENCY_TYPES.PERCENT
                                ? '%'
                                : ''
                            }
                            onBlurFormatter={onBlurFormatter}
                            onFocusFormatter={onFocusFormatter}
                            type="text"
                            className={styles.input}
                            name={`costs[${index}].month`}
                            normalize={(value) => removeCommasFromNumber(value)}
                            format={(value) => {
                              if (!value) {
                                return '';
                              }
                              return formatNumberWithComma(
                                removeCommasFromNumber(value)
                              );
                            }}
                            component={Input}
                          />
                        </td>
                        <td>
                          {cost.currency === CURRENCY_TYPES.PERCENT ? (
                            <Field
                              placeholder="0.00%"
                              type="text"
                              disabled
                              format={(value) =>
                                `$${formatNumberWithComma(
                                  (+removeCommasFromNumber(value) * 12).toFixed(
                                    2
                                  )
                                )}/year ($${formatNumberWithComma(
                                  (+removeCommasFromNumber(value)).toFixed(2)
                                )}/month)`
                              }
                              className={`${styles.input} ${styles.text}`}
                              name={`costs[${index}].year`}
                              component={Input}
                            />
                          ) : (
                            <Field
                              placeholder="$0.00"
                              validate={[required, number, positiveNumber]}
                              type="text"
                              className={styles.input}
                              onBlurFormatter={onBlurFormatter}
                              onFocusFormatter={onFocusFormatter}
                              prefix="$"
                              onChange={(e) => {
                                change(
                                  `costs[${index}].month`,
                                  (
                                    +removeCommasFromNumber(e.target.value) / 12
                                  ).toFixed(2)
                                );
                                setRecalculateVisible(true);
                              }}
                              name={`costs[${index}].year`}
                              normalize={(value) =>
                                removeCommasFromNumber(value)
                              }
                              format={(value) => {
                                if (!value) {
                                  return '';
                                }
                                return formatNumberWithComma(
                                  removeCommasFromNumber(value)
                                );
                              }}
                              component={Input}
                            />
                          )}
                        </td>
                        <td>
                          <button
                            style={{
                              visibility:
                                selectedOptions &&
                                selectedOptions.find(
                                  (option) => option.value === cost.value
                                )
                                  ? 'hidden'
                                  : 'visible',
                            }}
                            type="button"
                            onClick={() => removeOption(cost)}
                            className="button action-button"
                          >
                            <span className="material-icons">delete</span>
                          </button>
                        </td>
                        {cost.tooltip && (
                          <ReactTooltip
                            id={`tooltip-${cost.value}`}
                            place="top"
                            effect="solid"
                          >
                            <p
                              dangerouslySetInnerHTML={{ __html: cost.tooltip }}
                            />
                          </ReactTooltip>
                        )}
                      </tr>
                    ))}
                  </>
                ) : null}
                <tr className={styles.fees}>
                  <td className={styles.labelWrapper}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.VARIABLE_EXPENSES_FORM.LISTING_SITE_FEES_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.VARIABLE_EXPENSES_FORM.LISTING_SITE_FEES_LABEL
                    ) && (
                      <>
                        <span
                          className={`material-icons ${styles.tooltip}`}
                          data-tip
                          data-for="listing-site-fees"
                        >
                          info
                        </span>
                        <ReactTooltip
                          className="tooltip"
                          id="listing-site-fees"
                        >
                          {getTooltipBySlug(
                            snippets,
                            SNIPPETS.VARIABLE_EXPENSES_FORM
                              .LISTING_SITE_FEES_LABEL
                          )}
                        </ReactTooltip>
                      </>
                    )}
                  </td>
                  <td>
                    <Field
                      placeholder="0.00%"
                      validate={[required, number, percent]}
                      type="text"
                      suffix="%"
                      onBlurFormatter={onBlurFormatter}
                      onFocusFormatter={onFocusFormatter}
                      className={styles.input}
                      onChange={(e) => {
                        change(
                          `listing_site_fees.year`,
                          e.target.value
                            ? (projectedRent *
                                +removeCommasFromNumber(e.target.value)) /
                                100
                            : Number(0).toFixed(2)
                        );
                        setRecalculateVisible(true);
                      }}
                      name="listing_site_fees.month"
                      normalize={(value) => removeCommasFromNumber(value)}
                      format={(value) => {
                        if (!value) {
                          return '';
                        }
                        return formatNumberWithComma(
                          removeCommasFromNumber(value)
                        );
                      }}
                      component={Input}
                    />
                  </td>
                  <td>
                    <Field
                      placeholder="$0.00"
                      type="text"
                      disabled
                      format={(value) =>
                        `$${formatNumberWithComma(
                          (+removeCommasFromNumber(value) * 12).toFixed(2)
                        )}/year ($${formatNumberWithComma(
                          (+removeCommasFromNumber(value)).toFixed(2)
                        )}/month)`
                      }
                      className={`${styles.input} ${styles.text}`}
                      name="listing_site_fees.year"
                      component={Input}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={styles.labelWrapper}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.VARIABLE_EXPENSES_FORM.REPAIRS_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.VARIABLE_EXPENSES_FORM.REPAIRS_LABEL
                    ) && (
                      <>
                        <span
                          className={`material-icons ${styles.tooltip}`}
                          data-tip
                          data-for="repairs"
                        >
                          info
                        </span>
                        <ReactTooltip className="tooltip" id="repairs">
                          {getTooltipBySlug(
                            snippets,
                            SNIPPETS.VARIABLE_EXPENSES_FORM.REPAIRS_LABEL
                          )}
                        </ReactTooltip>
                      </>
                    )}
                  </td>
                  <td>
                    <Field
                      placeholder="0.00%"
                      validate={[required, number, percent]}
                      type="text"
                      suffix="%"
                      onBlurFormatter={onBlurFormatter}
                      onFocusFormatter={onFocusFormatter}
                      className={styles.input}
                      onChange={(e) => {
                        change(
                          `repairs_maintenance.year`,
                          (projectedRent *
                            +removeCommasFromNumber(e.target.value)) /
                            100
                        );
                        setRecalculateVisible(true);
                      }}
                      name="repairs_maintenance.month"
                      normalize={(value) => removeCommasFromNumber(value)}
                      format={(value) => {
                        if (!value) {
                          return '';
                        }
                        return formatNumberWithComma(
                          removeCommasFromNumber(value)
                        );
                      }}
                      component={Input}
                    />
                  </td>
                  <td>
                    <Field
                      placeholder="$0.00"
                      type="text"
                      disabled
                      format={(value) => {
                        return `$${formatNumberWithComma(
                          (+removeCommasFromNumber(value) * 12).toFixed(2)
                        )}/year \n($${formatNumberWithComma(
                          (+removeCommasFromNumber(value)).toFixed(2)
                        )}/month)`;
                      }}
                      className={`${styles.input} ${styles.text}`}
                      name="repairs_maintenance.year"
                      component={Input}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={styles.labelWrapper}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.VARIABLE_EXPENSES_FORM.MANAGEMENT_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.VARIABLE_EXPENSES_FORM.MANAGEMENT_LABEL
                    ) && (
                      <>
                        <span
                          className={`material-icons ${styles.tooltip}`}
                          data-tip
                          data-for="management"
                        >
                          info
                        </span>
                        <ReactTooltip className="tooltip" id="management">
                          {getTooltipBySlug(
                            snippets,
                            SNIPPETS.VARIABLE_EXPENSES_FORM.MANAGEMENT_LABEL
                          )}
                        </ReactTooltip>
                      </>
                    )}
                  </td>
                  <td>
                    <Field
                      placeholder="0.00%"
                      validate={[required, number, percent]}
                      type="text"
                      suffix="%"
                      onBlurFormatter={onBlurFormatter}
                      onFocusFormatter={onFocusFormatter}
                      className={styles.input}
                      onChange={(e) => {
                        change(
                          `property_management_fee.year`,
                          (projectedRent *
                            +removeCommasFromNumber(e.target.value)) /
                            100
                        );
                        setRecalculateVisible(true);
                      }}
                      name="property_management_fee.month"
                      normalize={(value) => removeCommasFromNumber(value)}
                      format={(value) => {
                        if (!value) {
                          return '';
                        }
                        return formatNumberWithComma(
                          removeCommasFromNumber(value)
                        );
                      }}
                      component={Input}
                    />
                  </td>
                  <td>
                    <Field
                      placeholder="$0.00"
                      type="text"
                      disabled
                      format={(value) => {
                        return `$${formatNumberWithComma(
                          (+removeCommasFromNumber(value) * 12).toFixed(2)
                        )}/year \n($${formatNumberWithComma(
                          (+removeCommasFromNumber(value)).toFixed(2)
                        )}/month)`;
                      }}
                      className={`${styles.input} ${styles.text}`}
                      name="property_management_fee.year"
                      component={Input}
                    />
                  </td>
                </tr>
                <tr className={styles.total}>
                  <td className={styles.labelWrapper}>
                    <span>TOTAL:</span>
                  </td>
                  <td>
                    <Field
                      type="text"
                      disabled
                      placeholder="$0.00"
                      prefix="$"
                      className={styles.input}
                      format={(value) =>
                        `${formatNumberWithComma(
                          removeCommasFromNumber(value)
                        )}`
                      }
                      name="total.month"
                      component={Input}
                    />
                  </td>
                  <td>
                    <Field
                      type="text"
                      disabled
                      placeholder="$0.00"
                      prefix="$"
                      className={styles.input}
                      format={(value) =>
                        `${formatNumberWithComma(
                          removeCommasFromNumber(value)
                        )}`
                      }
                      name="total.year"
                      component={Input}
                    />
                  </td>
                  <td>
                    <button
                      style={{
                        visibility: isRecalculateVisible ? 'visible' : 'hidden',
                      }}
                      onClick={() => recalculateSum(variableCosts.costs)}
                      type="button"
                      className="button primary-button center"
                    >
                      Recalculate
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div className={styles.buttons}>
            <button type="submit" className="button primary-button">
              <span>Save Changes</span>
            </button>
          </div>
        </>
      )}
    </form>
  );
}

export default connect((state) => ({
  variableCosts: getFormValues('variable-costs-form')(state),
}))(
  reduxForm({
    form: 'variable-costs-form',
  })(
    formValues(
      'market_id',
      'property_id',
      'selected_variable_cost',
      'selected_currency',
      'listing_site_fees',
      'property_management_fee',
      'repairs_maintenance'
    )(VariableCostsForm)
  )
);

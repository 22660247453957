import ROUTES from '../../constants/routes';
import { SET_MENU_ITEM_ACTIVE } from '../action-types/menu';

const initialState = {
  menu: [
    {
      name: 'Dashboard',
      url: ROUTES.USER.HOME,
      active: false,
    },
    {
      name: 'Markets',
      url: ROUTES.USER.MARKETS,
      active: false,
    },
    {
      name: 'Properties',
      url: ROUTES.USER.PROPERTIES,
      active: false,
    },
  ],
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU_ITEM_ACTIVE:
      return {
        ...state,
        menu: state.menu.map((item) => ({
          ...item,
          active: item.url === action.url,
        })),
      };
    default:
      return state;
  }
};

export default menuReducer;

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import apiMiddleware from './middlewares/api-middleware';
import apiReducer from './reducers/api';
import formSubmitReducer from './reducers/form';
import authReducer from './reducers/auth';
import marketsReducer from './reducers/markets';
import dataReducer from './reducers/data';
import propertiesReducer from './reducers/properties';
import menuReducer from './reducers/menu';
import dashboardReducer from './reducers/dashboard';
import snippetsReducer from './reducers/snippets';
import snippetGroupsReducer from './reducers/snippetGroup';
import variableExpensesReducer from './reducers/variableExpenses';

const reducers = combineReducers({
  api: apiReducer,
  form: formReducer,
  formSubmit: formSubmitReducer,
  auth: authReducer,
  markets: marketsReducer,
  data: dataReducer,
  properties: propertiesReducer,
  menu: menuReducer,
  dashboard: dashboardReducer,
  snippets: snippetsReducer,
  snippetGroups: snippetGroupsReducer,
  variableExpenses: variableExpensesReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk, apiMiddleware))
);

export default store;

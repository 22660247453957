import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  numberOrEmpty,
  positiveNumberOrEmpty,
} from '../../../utils/validators';
import DatePicker from '../../components/DatePicker/DatePicker';
import Input from '../../components/Input/Input';
import styles from './MarketFilterForm.module.css';

const validate = (values) => {
  const errors = {};
  if (
    (values.properties_avg_gross_yield_from &&
      !values.properties_avg_gross_yield_to) ||
    (!values.properties_avg_gross_yield_from &&
      values.properties_avg_gross_yield_to)
  ) {
    errors.gross_yield_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (
    +values.properties_avg_gross_yield_from >
    +values.properties_avg_gross_yield_to
  ) {
    errors.gross_yield_error = 'From value must be less or equal than to value';
  }
  if (
    (values.properties_avg_cap_rate_from && !properties_avg_cap_rate_to) ||
    (!values.properties_avg_cap_rate_from && values.properties_avg_cap_rate_to)
  ) {
    errors.cap_rate_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (
    +values.properties_avg_cap_rate_from > +values.properties_avg_cap_rate_to
  ) {
    errors.cap_rate_error = 'From value must be less or equal than to value';
  }
  if (
    (values.properties_avg_cash_on_cash_return_from &&
      !values.properties_avg_cash_on_cash_return_to) ||
    (!values.properties_avg_cash_on_cash_return_from &&
      values.properties_avg_cash_on_cash_return_to)
  ) {
    errors.cash_on_cash_return_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (
    +values.properties_avg_cash_on_cash_return_from >
    +values.properties_avg_cash_on_cash_return_to
  ) {
    errors.cash_on_cash_return_error =
      'From value must be less or equal than to value';
  }

  if (
    (values.created_at_from && !values.created_at_to) ||
    (!values.created_at_from && values.created_at_to)
  ) {
    errors.created_at_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (values.created_at_from > values.created_at_to) {
    errors.created_at_error = 'From value must be less or equal than to value';
  }

  if (
    (values.properties_count_from && !values.properties_count_to) ||
    (!values.properties_count_from && values.properties_count_to)
  ) {
    errors.properties_count_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (values.properties_count_from > values.properties_count_to) {
    errors.properties_count_error =
      'From value must be less or equal than to value';
  }

  return errors;
};

function MarketFilterForm({ onSubmit, handleSubmit, change }) {
  const onLastDaysSelected = (days) => {
    const d = new Date();
    d.setDate(d.getDate() - days + 1);
    change('created_at_from', d);
    change('created_at_to', new Date());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div>
        <h4>Average Gross Yield</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="From"
            name="properties_avg_gross_yield_from"
            component={Input}
          />
          <span>To</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="To"
            name="properties_avg_gross_yield_to"
            component={Input}
          />
        </div>
        <Field type="hidden" name="gross_yield_error" component={Input} />
      </div>
      <div>
        <h4>Average Cap Rate</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="From"
            name="properties_avg_cap_rate_from"
            component={Input}
          />
          <span>To</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="To"
            name="properties_avg_cap_rate_to"
            component={Input}
          />
        </div>
        <Field type="hidden" name="cap_rate_error" component={Input} />
      </div>
      <div>
        <h4>Average Cash-on-Cash Return</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="From"
            name="properties_avg_cash_on_cash_return_from"
            component={Input}
          />
          <span>To</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="To"
            name="properties_avg_cash_on_cash_return_to"
            component={Input}
          />
        </div>
        <Field
          type="hidden"
          name="cash_on_cash_return_error"
          component={Input}
        />
      </div>
      <div>
        <h4>Сreation Date</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            name="created_at_from"
            component={DatePicker}
            placeholder="Select Date"
          />
          <span>To</span>
          <Field
            name="created_at_to"
            component={DatePicker}
            placeholder="Select Date"
          />
        </div>
        <div className={styles.dateButtons}>
          <button
            type="button"
            className="button -thin outlined-primary-button"
            onClick={() => onLastDaysSelected(7)}
          >
            Last 7 days
          </button>
          <button
            type="button"
            className="button -thin outlined-primary-button"
            onClick={() => onLastDaysSelected(30)}
          >
            Last 30 days
          </button>
          <button
            type="button"
            className="button -thin outlined-primary-button"
            onClick={() => onLastDaysSelected(90)}
          >
            Last 90 days
          </button>
        </div>
        <Field type="hidden" name="created_at_error" component={Input} />
      </div>
      <div>
        <h4>Property Count</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            validate={[positiveNumberOrEmpty]}
            placeholder="From"
            name="properties_count_from"
            component={Input}
          />
          <span>To</span>
          <Field
            validate={[positiveNumberOrEmpty]}
            placeholder="To"
            name="properties_count_to"
            component={Input}
          />
        </div>
        <Field type="hidden" name="properties_count_error" component={Input} />
      </div>
      <button className="button primary-button center">Apply Filters</button>
    </form>
  );
}

export default reduxForm({
  form: 'market-filter-form',
  validate,
})(MarketFilterForm);

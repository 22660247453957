import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  reduxForm,
  Field,
  formValues,
  change as globalChange,
  submit,
  getFormSyncErrors,
} from 'redux-form';
import ReactTooltip from 'react-tooltip';
import swal from '@sweetalert/with-react';
import zillowImage from 'public/assets/images/zillow.png';
import airdnaLogo from 'public/assets/images/AirDNA-logo.png';
import CURRENCY_TYPES from '../../../constants/currency';
import ENDPOINTS from '../../../constants/endpoints';
import RENTAL_TYPES from '../../../constants/rental-type';
import { getCities, getStates } from '../../../redux/actions/data';
import { createMarket } from '../../../redux/actions/markets';
import {
  getOverview,
  getRentalizer,
  getSum,
  parseData,
} from '../../../redux/actions/properties';
import {
  formatNumberWithComma,
  removeCommasFromNumber,
} from '../../../utils/formatters';
import {
  number,
  numberOrEmpty,
  percent,
  positiveNumber,
  positiveNumberOrEmpty,
  required,
  wholeNumber,
} from '../../../utils/validators';
import Card from '../../components/Card/Card';
import FormDialog from '../../components/FormDialog/FormDialog';
import Input from '../../components/Input/Input';
import Multiselect from '../../components/MultiSelect/MultiSelect';
import NumberInput from '../../components/NumberInput/NumberInput';
import AddMarketForm from '../AddMarketForm/AddMarketForm';
import VariableCostsForm from '../VariableCostsForm/VariableCostsForm';
import styles from './CreatePropertyForm.module.css';
import { getSnippetBySlug, getTooltipBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';
import Textarea from '../../components/TextArea/TextArea';
import { getMonthNameByNumber } from '../../../utils/date';

const fields = [
  ['parsing_url'],
  ['market_id', 'rental_type', 'need_appreciation_assumption'],
  ['address', 'state', 'city', 'zip', 'bedrooms', 'bathrooms', 'notes'],
  [
    'list_price',
    'purchase_price',
    'closing_costs_percent',
    'closing_costs',
    'down_payment_percent',
    'interest_rate',
    'loan_term',
  ],
  [
    'projected_rent',
    'monthly_projected_rent',
    'average_daily_rate',
    'projected_occupancy',
    'cleaning_fees_revenue',
    'overview',
  ],
  [
    'property_taxes',
    'rehub_budget',
    'furnishing_budget',
    'cleaning_fees_expenses',
    'average_days_per_stay',
    'rent_growth_assumption',
    'appreciation_assumption',
    'expense_growth_assumption',
    'tax_increase_assumption',
  ],
];

function CreatePropertyForm({
  state: selectedState,
  change,
  onSubmit,
  handleSubmit,
  editMode,
  parsing_url,
  photos,
  form,
  variableCosts,
  projected_rent,
  variable_expenses,
  listing_site_fees,
  repairs_maintenance,
  property_management_fee,
  currentPropertyId,
  closing_costs_percent,
  step,
  onStepClick,
  invalid,
  stepStatuses,
  onStepSubmitted,
  errors,
  purchase_price,
  stepRefs,
  address,
  bedrooms,
  bathrooms,
  guests,
  overview,
  zip,
  city,
}) {
  const [isMonthlyAnalysisTableShown, setMonthlyAnalysisTableShown] =
    useState(false);

  useEffect(() => {
    dispatch(getStates());
  }, []);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      for (const error of Object.keys(errors)) {
        const step = fields.findIndex((stepFields) =>
          stepFields.includes(error)
        );
        if (stepStatuses[step] === 'completed') {
          onStepSubmitted(step, 'error');
        }
      }
    }
  }, [errors]);

  const dispatch = useDispatch();
  const states = useSelector((state) =>
    state.data.states?.map((state) => ({
      label: state.city_name,
      value: state.state_id,
      shortName: state.short_name,
    }))
  );

  const cities = useSelector((state) =>
    state.data.cities?.map((city) => ({
      label: city.name,
      value: city.city_id,
      state: city.state_id,
    }))
  );

  const snippets = useSelector((state) => state.snippets.snippets);
  const [isResaveMessageShown, setResaveMessageShown] = useState(false);

  const loadCities = (stateIds) => {
    dispatch(getCities({ state_ids: stateIds }));
  };

  const onStateChanged = (value) => {
    change('city', null);
    if (value) {
      loadCities([value.value]);
    }
  };

  const onParseData = (data) => {
    dispatch(getCities({ state_ids: [data.state] }));
    change('address', data.address);
    change('zip', data.zip);
    change('bedrooms', data.bedrooms);
    change('bathrooms', data.bathrooms);
    change('list_price', data.list_price.toFixed(2));
    change('square_footage', data.square_footage.toFixed(2));
    change('city', {
      value: data.city,
      label: data.city_name,
    });
    change('state', {
      value: data.state,
      label: data.state_name,
      shortName: data.state_short_name,
    });
    change('property_taxes', data.tax ? data.tax.toFixed(2) : '');
  };

  const onParsePhoto = (data) => {
    change('photos', data);
  };

  const getInformation = () => {
    dispatch(
      parseData({ url: parsing_url, onSuccess: [onParsePhoto, onParseData] })
    );
  };

  const saveVariableCosts = (values) => {
    dispatch(globalChange(form, 'variableCosts', values.costs));
    dispatch(globalChange(form, 'listing_site_fees', values.listing_site_fees));
    dispatch(
      globalChange(form, 'repairs_maintenance', values.repairs_maintenance)
    );
    dispatch(
      globalChange(
        form,
        'property_management_fee',
        values.property_management_fee
      )
    );

    const customExpenses = values.costs.map((cost) => ({
      name: cost.value,
      value: cost.month,
      measurements: cost.currency,
      id: cost.id,
    }));

    const data = {
      custom_expenses: customExpenses,
      projected_rent: projected_rent / 12,
      listing_site_fees: values.listing_site_fees.month,
      repairs_maintenance: values.repairs_maintenance.month,
      property_management_fee: values.property_management_fee.month,
    };
    dispatch(
      getSum({
        ...data,
        onSuccess: (data) => {
          onStepSubmitted(6, 'completed');
          change('variable_expenses', data.sum_variable_expenses.toFixed(2));
        },
      })
    );
  };

  const openVariableCostsModal = () => {
    confirmAlert({
      overlayClassName: `overlay ${styles.variableCostsDialog}`,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => (
        <FormDialog
          title={editMode ? 'Edit Variable Expenses' : 'Add Variable Expenses'}
          onClose={() => {
            swal({
              closeOnClickOutside: false,
              closeOnEsc: false,
              content: (
                <p>Do you really want to leave without saving changes?</p>
              ),
              buttons: {
                cancel: {
                  text: 'Cancel',
                  visible: true,
                  className: 'button outlined-secondary-button -thin',
                  closeModal: true,
                },
                confirm: {
                  text: 'OK',
                  visible: true,
                  className: 'button primary-button -thin',
                  closeModal: true,
                },
              },
              icon: 'warning',
            }).then(function (isConfirm) {
              if (isConfirm) {
                onClose();
              }
            });
          }}
          form={
            <VariableCostsForm
              editMode={editMode}
              state={selectedState.label}
              zillow_url={parsing_url}
              initialValues={{
                costs: variableCosts
                  ? variableCosts.map((cost) => ({
                      ...cost,
                      month: removeCommasFromNumber(cost.month),
                      year: removeCommasFromNumber(cost.year),
                    }))
                  : [],
                listing_site_fees: listing_site_fees || {
                  month: Number(0).toFixed(2),
                  year: Number(0).toFixed(2),
                },
                repairs_maintenance: repairs_maintenance || {
                  month: Number(user.default_capital_expenditures || 0).toFixed(
                    2
                  ),
                  year: Number(
                    user.default_capital_expenditures * 12 || 0
                  ).toFixed(2),
                },
                property_management_fee: property_management_fee || {
                  month: Number(
                    user.default_property_management_fee || 0
                  ).toFixed(2),
                  year: Number(
                    user.default_property_management_fee * 12 || 0
                  ).toFixed(2),
                },
                total: {
                  month: variable_expenses
                    ? (variable_expenses / 12).toFixed(2)
                    : Number(0).toFixed(2),
                  year: variable_expenses
                    ? (+variable_expenses).toFixed(2)
                    : Number(0).toFixed(2),
                },
              }}
              currentPropertyId={currentPropertyId}
              projectedRent={projected_rent ? projected_rent / 12 : 0}
              onSubmit={(values) => {
                setResaveMessageShown(false);
                saveVariableCosts(values);
                onClose();
              }}
            />
          }
        />
      ),
    });
  };

  const addMarket = (values, onSuccess) => {
    dispatch(
      createMarket({
        payload: {
          name: values.name,
          cities: values.cities.map((city) => city.value),
        },
        onSuccess,
      })
    );
  };

  const createNewMarket = () => {
    confirmAlert({
      overlayClassName: 'overlay',
      closeOnClickOutside: false,
      customUI: ({ onClose }) => (
        <FormDialog
          onClose={() => {
            swal({
              closeOnClickOutside: false,
              closeOnEsc: false,
              content: (
                <p>Do you really want to leave without saving changes?</p>
              ),
              buttons: {
                cancel: {
                  text: 'Cancel',
                  visible: true,
                  className: 'button outlined-secondary-button -thin',
                  closeModal: true,
                },
                confirm: {
                  text: 'OK',
                  visible: true,
                  className: 'button primary-button -thin',
                  closeModal: true,
                },
              },
              icon: 'warning',
            }).then(function (isConfirm) {
              if (isConfirm) {
                onClose();
              }
            });
          }}
          title="Add New Market"
          form={
            <AddMarketForm
              onSubmit={(values) => {
                addMarket(values, () => {
                  change('market_id', []);
                  onClose();
                });
              }}
              initialValues={{ name: '', cities: [], states: [] }}
            />
          }
        />
      ),
    });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const onBlurFormatter = (value, optional = false) => {
    const stringValue = String(value);
    if (stringValue === '') {
      return optional ? '' : '0.00';
    }

    return stringValue.length > 0
      ? (+removeCommasFromNumber(stringValue)).toFixed(2)
      : value;
  };

  const onFocusFormatter = (e) => {
    if (
      String(e.target.value).length > 0 &&
      Number.isInteger(+removeCommasFromNumber(e.target.value))
    ) {
      if (+e.target.value === 0) {
        change(e.target.name, '');
      } else {
        change(
          e.target.name,
          (+removeCommasFromNumber(e.target.value)).toFixed(0)
        );
      }
    }
  };

  const onProjectedRentChange = (value) => {
    if (variableCosts) {
      if (+variable_expenses > 0) {
        setResaveMessageShown(true);
      }
      change(
        'variableCosts',
        variableCosts.map((cost) => {
          if (cost.currency === CURRENCY_TYPES.PERCENT) {
            return {
              ...cost,
              year: (value * +cost.month) / 12 / 100,
            };
          }
          return { ...cost };
        })
      );
    }
    if (listing_site_fees) {
      change(
        'listing_site_fees.year',
        (value * listing_site_fees.month) / 12 / 100
      );
    }
    if (repairs_maintenance) {
      change(
        'repairs_maintenance.year',
        (value * repairs_maintenance.month) / 12 / 100
      );
    }
    if (property_management_fee) {
      change(
        'property_management_fee.year',
        (value * property_management_fee.month) / 12 / 100
      );
    }
  };

  const recalculateVariableExpenses = () => {
    const customExpenses = variableCosts.map((cost) => ({
      name: cost.value,
      value: cost.month,
      measurements: cost.currency,
    }));
    const data = {
      custom_expenses: customExpenses,
      property_management_fee: property_management_fee.month,
      listing_site_fees: listing_site_fees.month,
      repairs_maintenance: repairs_maintenance.month,
      projected_rent: projected_rent / 12,
      onSuccess: (data) => {
        change('variable_expenses', data.sum_variable_expenses.toFixed(2));
        setResaveMessageShown(false);
      },
    };
    dispatch(getSum(data));
  };

  const importDataFromAirdna = () => {
    const addressToSend = `${address}, ${city.label}, ${selectedState.shortName} ${zip}`;
    dispatch(
      getRentalizer(
        {
          address: addressToSend,
          bedrooms,
          bathrooms,
          guests,
        },
        (data) => {
          change('projected_rent', onBlurFormatter(data.annual_projected_rent));
          change(
            'monthly_projected_rent',
            onBlurFormatter(data.annual_projected_rent / 12)
          );
          change(
            'average_daily_rate',
            onBlurFormatter(data.average_daily_rate)
          );
          change(
            'projected_occupancy',
            onBlurFormatter(data.projected_occupancy)
          );
          dispatch(
            getOverview({ address: addressToSend }, (data) => {
              const overview = [];
              for (const [year, overviewYearData] of Object.entries(data)) {
                for (const data of overviewYearData) {
                  overview.push({
                    ...data,
                    year,
                    average_daily_rate: onBlurFormatter(
                      data.average_daily_rate
                    ),
                    projected_occupancy: onBlurFormatter(
                      data.projected_occupancy
                    ),
                  });
                }
              }
              change('overview', overview);
            })
          );
        }
      )
    );
  };

  const submitStep = (step) => {
    dispatch(submit('create-property-form'));
    if (!invalid) {
      onStepClick(step);
      onStepSubmitted(step - 1, 'completed');
    }
  };

  useEffect(() => {
    if (step === 6 && stepStatuses[6] !== 'completed') {
      openVariableCostsModal();
    }
  }, [step]);

  return (
    <form
      onSubmit={handleSubmit((values) => onSubmit({ ...values, isSave: true }))}
    >
      <div ref={stepRefs[0]} className={styles.section}>
        <Card
          className={styles.zillow}
          ref={stepRefs[0]}
          isContentVisible={step === 0}
          active={step === 0}
          onClick={() => onStepClick(0)}
          actionButton={
            stepStatuses[0] === 'error' ? (
              <span className="error">has errors</span>
            ) : (
              ''
            )
          }
          disabled={stepStatuses[0] === 'uncompleted'}
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_PROPERTY_PAGE.REALTOR_SUBTITLE
          )}
        >
          <div>
            <div className={styles.zillowLogo}>
              <a href="http://www.zillow.com/" target="_blank" rel="noreferrer">
                <img src={zillowImage} alt="Real Estate on Zillow" />
              </a>
            </div>
            <div className={styles.fields}>
              <div>
                <Field
                  placeholder={getSnippetBySlug(
                    snippets,
                    SNIPPETS.CREATE_PROPERTY_PAGE.REALTOR_PLACEHOLDER,
                    true
                  )}
                  type="text"
                  name="parsing_url"
                  url={`${ENDPOINTS.PROPERTIES.GET_SIMPLY_RETS_DATA}`}
                  label={
                    <div>
                      <div className={styles.label}>
                        {getSnippetBySlug(
                          snippets,
                          SNIPPETS.CREATE_PROPERTY_PAGE.REALTOR_LABEL
                        )}
                        {getTooltipBySlug(
                          snippets,
                          SNIPPETS.CREATE_PROPERTY_PAGE.REALTOR_LABEL
                        ) && (
                          <span
                            className={`material-icons ${styles.info}`}
                            data-tip
                            data-for="parsing_url"
                          >
                            info
                          </span>
                        )}
                      </div>
                      <ReactTooltip className="tooltip" id="parsing_url">
                        {getTooltipBySlug(
                          snippets,
                          SNIPPETS.CREATE_PROPERTY_PAGE.REALTOR_LABEL
                        )}
                      </ReactTooltip>
                    </div>
                  }
                  component={Input}
                />
                <button
                  type="button"
                  onClick={getInformation}
                  disabled={!parsing_url}
                  className="button primary-button"
                >
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.CREATE_PROPERTY_PAGE.GET_INFORMATION_BUTTON
                  )}
                </button>
                <button
                  type="button"
                  className="button outlined-secondary-button"
                  onClick={() => onStepClick(1)}
                >
                  Skip
                </button>
              </div>
              {photos && (
                <img src={photos.small_photo} className={styles.photo} />
              )}
            </div>
            {photos && photos.small_photo && (
              <button
                type="button"
                className="button primary-button"
                onClick={() => submitStep(1)}
              >
                Next step
              </button>
            )}
            <p className="copyright">
              Zillow, Inc., 2006-2016. Use is subject to 
              <a
                href="https://www.zillow.com/z/corp/terms/"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{' '}
              <a
                href="https://www.zillow.com/z/zestimate/"
                target="_blank"
                rel="noreferrer"
              >
                What's a Zestimate?
              </a>
            </p>
          </div>
        </Card>
      </div>
      <div ref={stepRefs[1]} className={styles.section}>
        <Card
          ref={stepRefs[1]}
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_PROPERTY_PAGE.GENERAL_SUBTITLE
          )}
          active={step === 1}
          disabled={stepStatuses[1] === 'uncompleted'}
          actionButton={
            stepStatuses[1] === 'error' ? (
              <span className="error">has errors</span>
            ) : (
              ''
            )
          }
          onClick={() => onStepClick(1)}
          isContentVisible={step === 1}
          className={styles.general}
        >
          <div className={styles.fields}>
            <div>
              <Field
                placeholder={getSnippetBySlug(
                  snippets,
                  SNIPPETS.CREATE_PROPERTY_PAGE.MARKET_PLACEHOLDER,
                  true
                )}
                validate={[required]}
                name="market_id"
                isSingle
                async
                url={`${ENDPOINTS.MARKETS.GET_MARKETS}`}
                label={
                  <div>
                    <div className={styles.label}>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.MARKET_LABEL
                      )}
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.MARKET_LABEL
                      ) && (
                        <span
                          className={`material-icons ${styles.info}`}
                          data-tip
                          data-for="market_id"
                        >
                          info
                        </span>
                      )}
                    </div>
                    <ReactTooltip className="tooltip" id="market_id">
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.MARKET_LABEL
                      )}
                    </ReactTooltip>
                  </div>
                }
                component={Multiselect}
              />
              <button
                type="button"
                onClick={createNewMarket}
                className="button link"
              >
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.CREATE_PROPERTY_PAGE.NEW_MARKET_BUTTON
                )}
              </button>
            </div>
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.RENTAL_TYPE_PLACEHOLDER,
                true
              )}
              validate={[required]}
              name="rental_type"
              disabled
              isSingle
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.RENTAL_TYPE_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.RENTAL_TYPE_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="rental_type"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="rental_type">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.RENTAL_TYPE_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              options={Object.entries(RENTAL_TYPES).map((type) => ({
                label: type[1],
                value: type[0],
              }))}
              component={Multiselect}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.APPRECIATION_PLACEHOLDER,
                true
              )}
              validate={[required, number, percent]}
              onFocusFormatter={onFocusFormatter}
              onBlurFormatter={onBlurFormatter}
              type="text"
              change={change}
              suffix="%"
              name="need_appreciation_assumption"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.APPRECIATION_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.APPRECIATION_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="appreciation"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="appreciation">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.APPRECIATION_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={NumberInput}
            />
          </div>
          <button
            type="button"
            className="button primary-button"
            onClick={() => submitStep(2)}
          >
            Next Step
          </button>
        </Card>
      </div>
      <div ref={stepRefs[2]} className={styles.section}>
        <Card
          ref={stepRefs[2]}
          onClick={() => onStepClick(2)}
          active={step === 2}
          disabled={stepStatuses[2] === 'uncompleted'}
          actionButton={
            stepStatuses[2] === 'error' ? (
              <span className="error">has errors</span>
            ) : (
              ''
            )
          }
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_PROPERTY_PAGE.PROPERTY_DETAILS_SUBTITLE
          )}
          isContentVisible={step === 2}
          className={styles.propertyDetails}
        >
          <div className={styles.fields}>
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.ADDRESS_PLACEHOLDER,
                true
              )}
              validate={[required]}
              type="text"
              name="address"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.ADDRESS_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.ADDRESS_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="address"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="address">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.ADDRESS_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.STATE_PLACEHOLDER,
                true
              )}
              validate={[required]}
              name="state"
              onChange={onStateChanged}
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.STATE_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.STATE_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="state"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="state">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.STATE_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              isSingle
              options={states || []}
              component={Multiselect}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.CITY_PLACEHOLDER,
                true
              )}
              validate={[required]}
              name="city"
              isSingle
              disabled={!selectedState}
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.CITY_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.CITY_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="city"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="city">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.CITY_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              options={cities || []}
              component={Multiselect}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.ZIP_PLACEHOLDER,
                true
              )}
              validate={[required, wholeNumber]}
              type="text"
              name="zip"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.ZIP_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.ZIP_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="zip"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="zip">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.ZIP_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={Input}
            />
            <div className={styles.bedroomsBlock}>
              <Field
                placeholder={getSnippetBySlug(
                  snippets,
                  SNIPPETS.CREATE_PROPERTY_PAGE.BEDROOMS_PLACEHOLDER,
                  true
                )}
                validate={[required, wholeNumber, positiveNumber]}
                type="text"
                change={change}
                name="bedrooms"
                label={
                  <div>
                    <div className={styles.label}>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.BEDROOMS_LABEL
                      )}
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.BEDROOMS_LABEL
                      ) && (
                        <span
                          className={`material-icons ${styles.info}`}
                          data-tip
                          data-for="bedrooms"
                        >
                          info
                        </span>
                      )}
                    </div>
                    <ReactTooltip className="tooltip" id="bedrooms">
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.BEDROOMS_LABEL
                      )}
                    </ReactTooltip>
                  </div>
                }
                component={NumberInput}
              />
              <Field
                placeholder={getSnippetBySlug(
                  snippets,
                  SNIPPETS.CREATE_PROPERTY_PAGE.BATHROOMS_PLACEHOLDER,
                  true
                )}
                validate={[required, wholeNumber, positiveNumber]}
                type="text"
                change={change}
                name="bathrooms"
                label={
                  <div>
                    <div className={styles.label}>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.BATHROOMS_LABEL
                      )}
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.BATHROOMS_LABEL
                      ) && (
                        <span
                          className={`material-icons ${styles.info}`}
                          data-tip
                          data-for="bathrooms"
                        >
                          info
                        </span>
                      )}
                    </div>
                    <ReactTooltip className="tooltip" id="bathrooms">
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.BATHROOMS_LABEL
                      )}
                    </ReactTooltip>
                  </div>
                }
                component={NumberInput}
              />
            </div>
            <div className={styles.bedroomsBlock}>
              <Field
                placeholder={getSnippetBySlug(
                  snippets,
                  SNIPPETS.CREATE_PROPERTY_PAGE.FOOTAGE_PLACEHOLDER,
                  true
                )}
                validate={[numberOrEmpty, positiveNumberOrEmpty]}
                type="text"
                change={change}
                name="square_footage"
                onBlurFormatter={(value) => onBlurFormatter(value, true)}
                onFocusFormatter={onFocusFormatter}
                normalize={(value) => removeCommasFromNumber(value)}
                format={(value) => {
                  if (!value) {
                    return '';
                  }
                  return formatNumberWithComma(removeCommasFromNumber(value));
                }}
                label={
                  <div>
                    <div className={styles.label}>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.FOOTAGE_LABEL
                      )}
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.FOOTAGE_LABEL
                      ) && (
                        <span
                          className={`material-icons ${styles.info}`}
                          data-tip
                          data-for="footage"
                        >
                          info
                        </span>
                      )}
                    </div>
                    <ReactTooltip className="tooltip" id="footage">
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.FOOTAGE_LABEL
                      )}
                    </ReactTooltip>
                  </div>
                }
                component={Input}
              />
              <Field
                placeholder={getSnippetBySlug(
                  snippets,
                  SNIPPETS.CREATE_PROPERTY_PAGE.GUESTS_PLACEHOLDER,
                  true
                )}
                validate={[required, wholeNumber, positiveNumber]}
                type="text"
                change={change}
                name="guests"
                label={
                  <div>
                    <div className={styles.label}>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.GUESTS_LABEL
                      )}
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.GUESTS_LABEL
                      ) && (
                        <span
                          className={`material-icons ${styles.info}`}
                          data-tip
                          data-for="guests"
                        >
                          info
                        </span>
                      )}
                    </div>
                    <ReactTooltip className="tooltip" id="guests">
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.GUESTS_LABEL
                      )}
                    </ReactTooltip>
                  </div>
                }
                component={NumberInput}
              />
            </div>
          </div>
          <Field
            placeholder={getSnippetBySlug(
              snippets,
              SNIPPETS.CREATE_PROPERTY_PAGE.NOTES_PLACEHOLDER,
              true
            )}
            name="notes"
            label={
              <div>
                <div className={styles.label}>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.CREATE_PROPERTY_PAGE.NOTES_LABEL
                  )}
                  {getTooltipBySlug(
                    snippets,
                    SNIPPETS.CREATE_PROPERTY_PAGE.NOTES_LABEL
                  ) && (
                    <span
                      className={`material-icons ${styles.info}`}
                      data-tip
                      data-for="notes"
                    >
                      info
                    </span>
                  )}
                </div>
                <ReactTooltip className="tooltip" id="notes">
                  {getTooltipBySlug(
                    snippets,
                    SNIPPETS.CREATE_PROPERTY_PAGE.NOTES_LABEL
                  )}
                </ReactTooltip>
              </div>
            }
            isHTML
            editorClassName={styles.notes}
            component={Textarea}
          />
          <button
            type="button"
            className="button primary-button"
            onClick={() => submitStep(3)}
          >
            Next Step
          </button>
        </Card>
      </div>
      <div ref={stepRefs[3]} className={styles.section}>
        <Card
          ref={stepRefs[3]}
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_PROPERTY_PAGE.LOAN_SUBTITLE
          )}
          active={step === 3}
          disabled={stepStatuses[3] === 'uncompleted'}
          actionButton={
            stepStatuses[3] === 'error' ? (
              <span className="error">has errors</span>
            ) : (
              ''
            )
          }
          onClick={() => onStepClick(3)}
          isContentVisible={step === 3}
          className={styles.capitalization}
        >
          <div className={styles.fields}>
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.LIST_PRICE_PLACEHOLDER,
                true
              )}
              validate={[required, number, positiveNumber]}
              type="text"
              prefix="$"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              normalize={(value) => removeCommasFromNumber(value)}
              format={(value) => {
                if (!value) {
                  return '';
                }
                return formatNumberWithComma(removeCommasFromNumber(value));
              }}
              name="list_price"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.LIST_PRICE_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.LIST_PRICE_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="list-price"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="list-price">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.LIST_PRICE_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.PURCHASE_PRICE_PLACEHOLDER,
                true
              )}
              validate={[required, number, positiveNumber]}
              type="text"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              prefix="$"
              normalize={(value) => removeCommasFromNumber(value)}
              format={(value) => {
                if (!value) {
                  return '';
                }
                return formatNumberWithComma(removeCommasFromNumber(value));
              }}
              name="purchase_price"
              onChange={(e) => {
                if (closing_costs_percent) {
                  change(
                    'closing_costs',
                    (
                      (removeCommasFromNumber(e.target.value) *
                        closing_costs_percent) /
                      100
                    ).toFixed(2)
                  );
                }
              }}
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.PURCHASE_PRICE_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.PURCHASE_PRICE_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="purchase_price"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="purchase_price">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.PURCHASE_PRICE_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={Input}
            />
            <div className={styles.closingCosts}>
              <Field
                placeholder={getSnippetBySlug(
                  snippets,
                  SNIPPETS.CREATE_PROPERTY_PAGE.CLOSING_COSTS_PLACEHOLDER,
                  true
                )}
                validate={[required, number, percent]}
                type="text"
                onBlurFormatter={onBlurFormatter}
                onFocusFormatter={onFocusFormatter}
                onChange={(e) => {
                  change(
                    'closing_costs',
                    (
                      (purchase_price *
                        removeCommasFromNumber(e.target.value)) /
                      100
                    ).toFixed(2)
                  );
                }}
                suffix="%"
                name="closing_costs_percent"
                normalize={(value) => removeCommasFromNumber(value)}
                format={(value) => {
                  if (!value) {
                    return '';
                  }
                  return formatNumberWithComma(removeCommasFromNumber(value));
                }}
                label={
                  <div>
                    <div className={styles.label}>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.CLOSING_COSTS_LABEL
                      )}
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.CLOSING_COSTS_LABEL
                      ) && (
                        <span
                          className={`material-icons ${styles.info}`}
                          data-tip
                          data-for="closing_costs_percent"
                        >
                          info
                        </span>
                      )}
                    </div>
                    <ReactTooltip
                      className="tooltip"
                      id="closing_costs_percent"
                    >
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.CLOSING_COSTS_LABEL
                      )}
                    </ReactTooltip>
                  </div>
                }
                component={Input}
              />
              <Field
                placeholder={getSnippetBySlug(
                  snippets,
                  SNIPPETS.CREATE_PROPERTY_PAGE.CLOSING_COSTS_PLACEHOLDER,
                  true
                )}
                validate={[required, number]}
                type="text"
                disabled={!+purchase_price}
                onChange={(e) => {
                  change(
                    'closing_costs_percent',
                    (
                      (removeCommasFromNumber(e.target.value) /
                        purchase_price) *
                      100
                    ).toFixed(2)
                  );
                }}
                onBlurFormatter={onBlurFormatter}
                onFocusFormatter={onFocusFormatter}
                prefix="$"
                name="closing_costs"
                normalize={(value) => removeCommasFromNumber(value)}
                format={(value) => {
                  if (!value) {
                    return '';
                  }
                  return formatNumberWithComma(removeCommasFromNumber(value));
                }}
                label={
                  <div>
                    <div className={styles.label}>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.CLOSING_COSTS_LABEL_DOLLAR
                      )}
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.CLOSING_COSTS_LABEL_DOLLAR
                      ) && (
                        <span
                          className={`material-icons ${styles.info}`}
                          data-tip
                          data-for="closing_costs"
                        >
                          info
                        </span>
                      )}
                    </div>
                    <ReactTooltip className="tooltip" id="closing_costs">
                      {getTooltipBySlug(
                        snippets,
                        SNIPPETS.CREATE_PROPERTY_PAGE.CLOSING_COSTS_LABEL
                      )}
                    </ReactTooltip>
                  </div>
                }
                component={Input}
              />
            </div>
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.DOWN_PAYMENT_PLACEHOLDER,
                true
              )}
              validate={[required, number, percent]}
              onFocusFormatter={onFocusFormatter}
              onBlurFormatter={onBlurFormatter}
              type="text"
              change={change}
              suffix="%"
              name="down_payment_percent"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.DOWN_PAYMENT_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.DOWN_PAYMENT_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="down_payment_percent"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="down_payment_percent">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.DOWN_PAYMENT_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={NumberInput}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.INTEREST_RATE_PLACEHOLDER,
                true
              )}
              validate={[required, number, percent]}
              type="text"
              suffix="%"
              name="interest_rate"
              format={(value) => value || ''}
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.INTEREST_RATE_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.INTEREST_RATE_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="interest_rate"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="interest_rate">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.INTEREST_RATE_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.LOAN_TERM_PLACEHOLDER,
                true
              )}
              validate={[wholeNumber, positiveNumber]}
              onFocusFormatter={onFocusFormatter}
              type="text"
              step={5}
              change={change}
              suffix="years"
              name="loan_term"
              integer
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.LOAN_TERM_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.LOAN_TERM_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="loan_term"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="loan_term">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.LOAN_TERM_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={NumberInput}
            />
          </div>
          <button
            type="button"
            className="button primary-button"
            onClick={() => submitStep(4)}
          >
            Next Step
          </button>
        </Card>
      </div>
      <div ref={stepRefs[4]} className={styles.section}>
        <Card
          ref={stepRefs[4]}
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_PROPERTY_PAGE.REVENUE_SUBTITLE
          )}
          active={step === 4}
          disabled={stepStatuses[4] === 'uncompleted'}
          actionButton={
            stepStatuses[4] === 'error' ? (
              <span className="error">has errors</span>
            ) : (
              ''
            )
          }
          onClick={() => onStepClick(4)}
          isContentVisible={step === 4}
          className={styles.revenue}
        >
          <div className={styles.dnaButtons}>
            <button
              type="button"
              onClick={importDataFromAirdna}
              disabled={!address}
              className="button outlined-secondary-button"
            >
              {getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.IMPORT_DATA_FROM_AIRDNA
              )}
            </button>
            <button
              type="button"
              onClick={() =>
                setMonthlyAnalysisTableShown(!isMonthlyAnalysisTableShown)
              }
              className="button outlined-secondary-button"
            >
              {isMonthlyAnalysisTableShown
                ? 'Hide Monthly Analysis Table'
                : 'Show Monthly Analysis Table'}
            </button>
          </div>

          <div className={styles.airdna}>
            <img src={airdnaLogo} alt="Logo of airDNA" />
            <p className="copyright">
              {getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.POWERED_BY_AIRDNA
              )}
            </p>
          </div>
          <div className={styles.fields}>
            <div className={styles.projectedRent}>
              <div>
                <Field
                  placeholder={getSnippetBySlug(
                    snippets,
                    SNIPPETS.CREATE_PROPERTY_PAGE
                      .ANNUAL_PROJECTED_RENT_PLACEHOLDER,
                    true
                  )}
                  className={styles.field}
                  validate={[required, number, positiveNumber]}
                  type="text"
                  prefix="$"
                  onBlurFormatter={onBlurFormatter}
                  onFocusFormatter={onFocusFormatter}
                  onChange={(e) => {
                    change(
                      'monthly_projected_rent',
                      (removeCommasFromNumber(e.target.value) / 12).toFixed(2)
                    );
                    onProjectedRentChange(
                      removeCommasFromNumber(e.target.value)
                    );
                  }}
                  name="projected_rent"
                  label={
                    <div>
                      <div className={styles.label}>
                        {getSnippetBySlug(
                          snippets,
                          SNIPPETS.CREATE_PROPERTY_PAGE
                            .ANNUAL_PROJECTED_RENT_LABEL
                        )}
                        {getTooltipBySlug(
                          snippets,
                          SNIPPETS.CREATE_PROPERTY_PAGE
                            .ANNUAL_PROJECTED_RENT_LABEL
                        ) && (
                          <span
                            className={`material-icons ${styles.info}`}
                            data-tip
                            data-for="projected_rent"
                          >
                            info
                          </span>
                        )}
                      </div>
                      <ReactTooltip className="tooltip" id="projected_rent">
                        {getTooltipBySlug(
                          snippets,
                          SNIPPETS.CREATE_PROPERTY_PAGE
                            .ANNUAL_PROJECTED_RENT_LABEL
                        )}
                      </ReactTooltip>
                    </div>
                  }
                  normalize={(value) => removeCommasFromNumber(value)}
                  format={(value) => {
                    if (!value) {
                      return '';
                    }
                    return formatNumberWithComma(removeCommasFromNumber(value));
                  }}
                  component={Input}
                />
                <Field
                  placeholder={getSnippetBySlug(
                    snippets,
                    SNIPPETS.CREATE_PROPERTY_PAGE
                      .MONTHLY_PROJECTED_RENT_PLACEHOLDER,
                    true
                  )}
                  className={styles.field}
                  validate={[required, number, positiveNumber]}
                  type="text"
                  name="monthly_projected_rent"
                  prefix="$"
                  onChange={(e) => {
                    change(
                      'projected_rent',
                      (removeCommasFromNumber(e.target.value) * 12).toFixed(2)
                    );
                    onProjectedRentChange(
                      removeCommasFromNumber(e.target.value) * 12
                    );
                  }}
                  onBlurFormatter={onBlurFormatter}
                  onFocusFormatter={onFocusFormatter}
                  label={
                    <div>
                      <div className={styles.label}>
                        {getSnippetBySlug(
                          snippets,
                          SNIPPETS.CREATE_PROPERTY_PAGE
                            .MONTHLY_PROJECTED_RENT_LABEL
                        )}
                        {getTooltipBySlug(
                          snippets,
                          SNIPPETS.CREATE_PROPERTY_PAGE
                            .MONTHLY_PROJECTED_RENT_LABEL
                        ) && (
                          <span
                            className={`material-icons ${styles.info}`}
                            data-tip
                            data-for="monthly_projected_rent"
                          >
                            info
                          </span>
                        )}
                      </div>
                      <ReactTooltip
                        className="tooltip"
                        id="monthly_projected_rent"
                      >
                        {getTooltipBySlug(
                          snippets,
                          SNIPPETS.CREATE_PROPERTY_PAGE
                            .MONTHLY_PROJECTED_RENT_LABEL
                        )}
                      </ReactTooltip>
                    </div>
                  }
                  normalize={(value) => removeCommasFromNumber(value)}
                  format={(value) => {
                    if (!value) {
                      return '';
                    }
                    return formatNumberWithComma(removeCommasFromNumber(value));
                  }}
                  component={Input}
                />
              </div>
              {isResaveMessageShown && (
                <p className="info">
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.CREATE_PROPERTY_PAGE.RESAVE_PROJECTED_RENT
                  )}
                </p>
              )}
            </div>
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.AVERAGE_DAILY_RATE_PLACEHOLDER,
                true
              )}
              validate={[required, number, positiveNumber]}
              type="text"
              name="average_daily_rate"
              prefix="$"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.AVERAGE_DAILY_RATE_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.AVERAGE_DAILY_RATE_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="average_daily_rate"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="average_daily_rate">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.AVERAGE_DAILY_RATE_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              normalize={(value) => removeCommasFromNumber(value)}
              format={(value) => {
                if (!value) {
                  return '';
                }
                return formatNumberWithComma(removeCommasFromNumber(value));
              }}
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.PROJECTED_OCCUPANCY_PLACEHOLDER,
                true
              )}
              validate={[required, number, percent]}
              type="text"
              name="projected_occupancy"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              suffix="%"
              format={(value) => value || ''}
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.PROJECTED_OCCUPANCY_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.PROJECTED_OCCUPANCY_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="projected_occupancy"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="projected_occupancy">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.PROJECTED_OCCUPANCY_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.FEES_REVENUE_PLACEHOLDER,
                true
              )}
              validate={[required, number]}
              type="text"
              name="cleaning_fees_revenue"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              prefix="$"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.FEES_REVENUE_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.FEES_REVENUE_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="cleaning_fees_revenue"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="cleaning_fees_revenue">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.FEES_REVENUE_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              normalize={(value) => removeCommasFromNumber(value)}
              format={(value) => {
                if (!value) {
                  return '';
                }
                return formatNumberWithComma(removeCommasFromNumber(value));
              }}
              component={Input}
            />
          </div>
          {isMonthlyAnalysisTableShown && (
            <div className="table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th />
                    {overview.map((data, index) => (
                      <th key={index}>
                        {getMonthNameByNumber(data.month)} {data.year}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ADR</td>
                    {overview.map((_, index) => (
                      <td key={index}>
                        <Field
                          name={`overview[${index}].average_daily_rate`}
                          validate={[required, number, positiveNumber]}
                          type="text"
                          prefix="$"
                          onBlurFormatter={onBlurFormatter}
                          onFocusFormatter={onFocusFormatter}
                          normalize={(value) => removeCommasFromNumber(value)}
                          format={(value) => {
                            if (!value) {
                              return '';
                            }
                            return formatNumberWithComma(
                              removeCommasFromNumber(value)
                            );
                          }}
                          component={Input}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td>Occupancy</td>
                    {overview.map((_, index) => (
                      <td key={index}>
                        <Field
                          name={`overview[${index}].projected_occupancy`}
                          validate={[required, number, percent]}
                          type="text"
                          onBlurFormatter={onBlurFormatter}
                          onFocusFormatter={onFocusFormatter}
                          suffix="%"
                          format={(value) => value || ''}
                          component={Input}
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <button
            type="button"
            className="button primary-button"
            onClick={() => submitStep(5)}
          >
            Next Step
          </button>
        </Card>
      </div>
      <div ref={stepRefs[5]} className={styles.section}>
        <Card
          ref={stepRefs[5]}
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_PROPERTY_PAGE.EXPENSES_SUBTITLE
          )}
          active={step === 5}
          disabled={stepStatuses[5] === 'uncompleted'}
          actionButton={
            stepStatuses[5] === 'error' ? (
              <span className="error">has errors</span>
            ) : (
              ''
            )
          }
          onClick={() => onStepClick(5)}
          isContentVisible={step === 5}
          className={styles.expenses}
        >
          <div className={styles.fields}>
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.PROPERTY_TAXES_PLACEHOLDER,
                true
              )}
              validate={[required, number, positiveNumber]}
              type="text"
              prefix="$"
              name="property_taxes"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.PROPERTY_TAXES_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.PROPERTY_TAXES_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="property_taxes"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="property_taxes">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.PROPERTY_TAXES_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              normalize={(value) => removeCommasFromNumber(value)}
              format={(value) => {
                if (!value) {
                  return '';
                }
                return formatNumberWithComma(removeCommasFromNumber(value));
              }}
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.REHUB_PLACEHOLDER,
                true
              )}
              validate={[required, number, positiveNumber]}
              type="text"
              name="rehub_budget"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              prefix="$"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.REHUB_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.REHUB_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="rehub_budget"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="rehub_budget">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.REHUB_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              normalize={(value) => removeCommasFromNumber(value)}
              format={(value) => {
                if (!value) {
                  return '';
                }
                return formatNumberWithComma(removeCommasFromNumber(value));
              }}
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.FURNISHING_PLACEHOLDER,
                true
              )}
              validate={[required, number, positiveNumber]}
              type="text"
              name="furnishing_budget"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              prefix="$"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.FURNISHING_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.FURNISHING_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="furnishing_budget"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="furnishing_budget">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.FURNISHING_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              normalize={(value) => removeCommasFromNumber(value)}
              format={(value) => {
                if (!value) {
                  return '';
                }
                return formatNumberWithComma(removeCommasFromNumber(value));
              }}
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.FEES_EXPENSES_PLACEHOLDER,
                true
              )}
              validate={[required, number, positiveNumber]}
              type="text"
              name="cleaning_fees_expenses"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              prefix="$"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.FEES_EXPENSES_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.FEES_EXPENSES_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="cleaning_fees_expenses"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="cleaning_fees_expenses">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.FEES_EXPENSES_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              normalize={(value) => removeCommasFromNumber(value)}
              format={(value) => {
                if (!value) {
                  return '';
                }
                return formatNumberWithComma(removeCommasFromNumber(value));
              }}
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.DAYS_PER_STAY_PLACEHOLDER,
                true
              )}
              validate={[required, wholeNumber, positiveNumber]}
              type="text"
              className={styles.daysPerStay}
              change={change}
              name="average_days_per_stay"
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.DAYS_PER_STAY_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.DAYS_PER_STAY_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="average_days_per_stay"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="average_days_per_stay">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.DAYS_PER_STAY_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={NumberInput}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE
                  .RENT_GROWTH_ASSUMPTION_PLACEHOLDER,
                true
              )}
              validate={[required, number, percent]}
              type="text"
              name="rent_growth_assumption"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              suffix="%"
              format={(value) => value || ''}
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.RENT_GROWTH_ASSUMPTION_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.RENT_GROWTH_ASSUMPTION_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="rent_growth_assumption"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip className="tooltip" id="rent_growth_assumption">
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE.RENT_GROWTH_ASSUMPTION_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE
                  .APPRECIATION_ASSUMPTION_PLACEHOLDER,
                true
              )}
              validate={[required, number, percent]}
              type="text"
              name="appreciation_assumption"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              suffix="%"
              format={(value) => value || ''}
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE
                        .APPRECIATION_ASSUMPTION_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE
                        .APPRECIATION_ASSUMPTION_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="appreciation_assumption"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip
                    className="tooltip"
                    id="appreciation_assumption"
                  >
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE
                        .APPRECIATION_ASSUMPTION_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE
                  .EXPENSE_GROWTH_ASSUMPTION_PLACEHOLDER,
                true
              )}
              validate={[required, number, percent]}
              type="text"
              name="expense_growth_assumption"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              suffix="%"
              format={(value) => value || ''}
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE
                        .EXPENSE_GROWTH_ASSUMPTION_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE
                        .EXPENSE_GROWTH_ASSUMPTION_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="expense_growth_assumption"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip
                    className="tooltip"
                    id="expense_growth_assumption"
                  >
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE
                        .EXPENSE_GROWTH_ASSUMPTION_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={Input}
            />
            <Field
              placeholder={getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE
                  .TAX_INCREASE_ASSUMPTION_PLACEHOLDER,
                true
              )}
              validate={[required, number, percent]}
              type="text"
              name="tax_increase_assumption"
              onBlurFormatter={onBlurFormatter}
              onFocusFormatter={onFocusFormatter}
              suffix="%"
              format={(value) => value || ''}
              label={
                <div>
                  <div className={styles.label}>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE
                        .TAX_INCREASE_ASSUMPTION_LABEL
                    )}
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE
                        .TAX_INCREASE_ASSUMPTION_LABEL
                    ) && (
                      <span
                        className={`material-icons ${styles.info}`}
                        data-tip
                        data-for="tax_increase_assumption"
                      >
                        info
                      </span>
                    )}
                  </div>
                  <ReactTooltip
                    className="tooltip"
                    id="tax_increase_assumption"
                  >
                    {getTooltipBySlug(
                      snippets,
                      SNIPPETS.CREATE_PROPERTY_PAGE
                        .TAX_INCREASE_ASSUMPTION_LABEL
                    )}
                  </ReactTooltip>
                </div>
              }
              component={Input}
            />
          </div>
          <button
            type="button"
            className="button primary-button"
            onClick={() => submitStep(6)}
          >
            Next Step
          </button>
        </Card>
      </div>
      <div ref={stepRefs[6]} className={styles.section}>
        <Card
          ref={stepRefs[6]}
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_PROPERTY_PAGE.ADD_VARIABLE_EXPENSES_SUBTITLE
          )}
          active={step === 6}
          disabled={stepStatuses[6] === 'uncompleted'}
          actionButton={
            stepStatuses[6] === 'error' ? (
              <span className="error">has errors</span>
            ) : (
              ''
            )
          }
          onClick={() => onStepClick(6)}
          isContentVisible={step === 6}
        >
          {!variable_expenses || variable_expenses <= 0 ? (
            <div className={styles.variableCostsButton}>
              <button
                type="button"
                disabled={!projected_rent}
                onClick={openVariableCostsModal}
                className="button withImg primary-button -thin center"
              >
                <span className="material-icons">add</span>
                <span>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.CREATE_PROPERTY_PAGE.ADD_EXPENSES_BUTTON
                  )}
                </span>
              </button>
              <p
                className={`info center ${styles.expensesButtonSubtitle} ${
                  projected_rent ? styles.hidden : ''
                }`}
              >
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.CREATE_PROPERTY_PAGE.EXPENSES_BUTTON_SUBTITLE
                )}
              </p>
            </div>
          ) : (
            <div>
              <Field
                placeholder="Variable Expenses"
                validate={[required, number, positiveNumber]}
                type="text"
                className={styles.variableExpensesField}
                disabled
                name="variable_expenses"
                onBlurFormatter={onBlurFormatter}
                onFocusFormatter={onFocusFormatter}
                prefix="$"
                label="Variable Expenses ($)"
                normalize={(value) => removeCommasFromNumber(value)}
                format={(value) =>
                  formatNumberWithComma(removeCommasFromNumber(value))
                }
                component={Input}
              />
              <button
                type="button"
                onClick={openVariableCostsModal}
                className="button link"
              >
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.CREATE_PROPERTY_PAGE.EDIT_VARIABLE_EXPENSES_BUTTON
                )}
              </button>
              {isResaveMessageShown && (
                <span
                  className={`material-icons ${styles.infoIcon}`}
                  data-tip
                  data-for="edit-expenses-button"
                >
                  info
                </span>
              )}
              {isResaveMessageShown && (
                <button
                  type="button"
                  onClick={recalculateVariableExpenses}
                  className={`button primary-button ${styles.recalculateButton}`}
                >
                  Recalculate
                </button>
              )}
            </div>
          )}
        </Card>
      </div>
      {!editMode ? (
        <div className={styles.createPropertyButton}>
          <button
            type="submit"
            disabled={
              !listing_site_fees ||
              !repairs_maintenance ||
              !property_management_fee ||
              stepStatuses.some((status) => status !== 'completed')
            }
            className="button withImg primary-button large"
          >
            <span className="material-icons">add</span>
            {getSnippetBySlug(
              snippets,
              SNIPPETS.CREATE_PROPERTY_PAGE.CREATE_PROPERTY_BUTTON
            )}
          </button>
          {(!listing_site_fees ||
            !repairs_maintenance ||
            !property_management_fee) && (
            <span
              className="material-icons"
              data-tip
              data-for="create-property-button"
            >
              info
            </span>
          )}
        </div>
      ) : (
        <button
          type="submit"
          className="button center primary-button"
          disabled={stepStatuses.some((status) => status !== 'completed')}
        >
          {getSnippetBySlug(
            snippets,
            SNIPPETS.CREATE_PROPERTY_PAGE.SAVE_CHANGES_BUTTON
          )}
        </button>
      )}
      <ReactTooltip id="create-property-button" place="top" effect="solid">
        {getSnippetBySlug(
          snippets,
          SNIPPETS.CREATE_PROPERTY_PAGE.CREATE_PROPERTY_BUTTON_INFO
        )}
      </ReactTooltip>
      <ReactTooltip id="edit-expenses-button" place="top" effect="solid">
        {getSnippetBySlug(snippets, SNIPPETS.CREATE_PROPERTY_PAGE.RESAVE_INFO)}
      </ReactTooltip>
    </form>
  );
}

export default connect((state) => ({
  errors: getFormSyncErrors('create-property-form')(state),
}))(
  reduxForm({
    form: 'create-property-form',
  })(
    formValues(
      'state',
      'city',
      'parsing_url',
      'photos',
      'variableCosts',
      'variable_expenses',
      'projected_rent',
      'repairs_maintenance',
      'property_management_fee',
      'listing_site_fees',
      'closing_costs_percent',
      'purchase_price',
      'notes',
      'address',
      'bedrooms',
      'bathrooms',
      'guests',
      'overview',
      'zip'
    )(CreatePropertyForm)
  )
);

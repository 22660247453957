import ENDPOINTS from '../../constants/endpoints';
import { SET_SNIPPET_GROUPS } from '../action-types/data';
import { apiAction } from './api';
import { setGeneralSuccessMessage } from './form';

export const getSnippetGroups = (parameters) => {
  return apiAction({
    url: ENDPOINTS.DATA.SNIPPET_GROUP,
    onSuccess: (dispatch, _, data) => dispatch(setSnippetGroups(data)),
    method: 'GET',
    data: parameters,
  });
};

export const createSnippetGroup = (data, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.SNIPPET_GROUP,
    onSuccess: (dispatch, _, data) => {
      onSuccess && onSuccess(data);
      dispatch(
        setGeneralSuccessMessage('Snippet group has been created successfully')
      );
    },
    method: 'POST',
    data,
  });
};

export const updateSnippetGroup = (data, onSuccess) => {
  const { id, ...parameters } = data;
  return apiAction({
    url: ENDPOINTS.DATA.SINGLE_GROUP(id),
    method: 'PUT',
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(
        setGeneralSuccessMessage('Snippet group has been updated successfully')
      );
    },
    data: parameters,
  });
};

export const deleteSnippetGroup = (id, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.SINGLE_GROUP(id),
    method: 'DELETE',
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(
        setGeneralSuccessMessage('Snippet group has been deleted successfully')
      );
    },
  });
};

export const getSnippetGroup = (id, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.SINGLE_GROUP(id),
    onSuccess: (dispatch, _, data) => {
      onSuccess && onSuccess(data.data);
    },
    method: 'GET',
  });
};

export const setSnippetGroups = (data) => {
  return {
    type: SET_SNIPPET_GROUPS,
    snippetGroups: data.data,
    count: data.meta.total,
  };
};

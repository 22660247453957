import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';

const withProvider = (Component) =>
  function ({ ...properties }) {
    return (
      <Provider store={store}>
        <Component {...properties} />
      </Provider>
    );
  };

export default withProvider;

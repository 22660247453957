import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { required } from '../../../../utils/validators';
import Input from '../../../components/Input/Input';
import styles from './CreateSnippetGroupForm.module.css';
import Card from '../../../components/Card/Card';

function CreateSnippetGroupForm({ handleSubmit, onSubmit, initialValues }) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.section}>
        <Card title="Snippet Group Details">
          <Field
            placeholder="Name"
            validate={[required]}
            type="text"
            name="name"
            label="Name of Snippet Group"
            initialState={initialValues.name}
            component={Input}
          />
          <button className="button primary-button center">
            Save Snippet Group
          </button>
        </Card>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'create-snippet-group-form',
  enableReinitialize: true,
})(CreateSnippetGroupForm);

import React, { useState } from 'react';
import styles from './Dropdown.module.css';

function Dropdown({ title, menu, customMenuClassName }) {
  const [isMenuOpened, setMenuOpened] = useState(false);
  return (
    <div
      tabIndex="0"
      onBlur={() => setMenuOpened(false)}
      className={`${styles.dropdown}`}
    >
      <div
        className={styles.title}
        onClick={() => setMenuOpened(!isMenuOpened)}
      >
        <span className={styles.titleText}>{title}</span>
        <span className="material-icons">arrow_drop_down</span>
      </div>
      {isMenuOpened && (
        <div className={`${styles.menu} ${customMenuClassName || ''}`}>
          <ul>
            {menu.map((item, index) => {
              return (
                <li
                  onClick={() => {
                    item.onClick();
                    setMenuOpened(false);
                  }}
                  key={index}
                >
                  <span className="material-icons">{item.icon}</span>
                  {item.title}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Dropdown;

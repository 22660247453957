import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { getClients } from '../../../../redux/actions/data';
import Pagination from '../../../components/Pagination/Pagination';
import styles from './UsersPage.module.css';
import Avatar from '../../../components/Avatar/Avatar';
import Dialog from '../../../components/Dialog/Dialog';
import { deleteUserById } from '../../../../redux/actions/auth';
import ROUTES from '../../../../constants/routes';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';

const ITEMS_PER_PAGE = 10;

function UsersPage() {
  const currentItems = useSelector((state) => state.data.clients.data);
  const total = useSelector((state) => state.data.clients.total);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState(
    +new URLSearchParams(location.search).get('page') || 0
  );

  const getClientsData = (page) => {
    dispatch(getClients({ page: page + 1, per_page: ITEMS_PER_PAGE }));
  };

  const handlePageClick = (event) => {
    setSelectedPage(event.selected);
    navigate({
      pathname: window.location.pathname,
      search: `?page=${event.selected}`,
    });
  };

  useEffect(() => {
    getClientsData(selectedPage);
  }, [selectedPage]);

  const removeUser = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <Dialog
          title="Remove User"
          text="Are you sure you want to remove user?"
          buttons={[
            {
              type: 'button',
              text: 'Delete',
              onClick: () => {
                dispatch(
                  deleteUserById(id, () =>
                    dispatch(
                      getClients({
                        page: selectedPage + 1,
                        per_page: ITEMS_PER_PAGE,
                      })
                    )
                  )
                );
                onClose();
              },
              className: 'secondary-button',
            },
            {
              type: 'button',
              text: 'Cancel',
              onClick: onClose,
              className: 'secondary-button',
            },
          ]}
        />
      ),
    });
  };

  return (
    <div>
      <div className={styles.header}>
        <h2 className="subtitle">Clients</h2>
        <p>Total Number of Clients: {total}</p>
      </div>
      <Breadcrumbs />
      {total === 0 ? (
        'No users yet'
      ) : (
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>Avatar</th>
                <th>Name</th>
                <th>Email</th>
                <th>Properties</th>
                <th>Registration Date</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Avatar src={item.thumb} />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>
                        <NavLink
                          to={`${ROUTES.ADMIN.USERS}/${item.id}/properties`}
                          state={{ name: item.name }}
                        >
                          <span className="filled-success breadcrumb">
                            {item.properties}
                          </span>
                        </NavLink>
                      </td>
                      <td>{item.registration_date}</td>
                      <td>
                        <div className={styles.buttons}>
                          <button
                            type="button"
                            title="Delete user"
                            className="button action-button"
                            onClick={() => removeUser(item.id)}
                          >
                            <span className="material-icons">delete</span>
                          </button>
                          <button
                            type="button"
                            className="button action-button"
                            title="Edit User Profile"
                            onClick={() =>
                              navigate(
                                `${ROUTES.ADMIN.PROFILE}/${item.id}/edit`
                              )
                            }
                          >
                            <span className="material-icons">edit</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        onPageChange={handlePageClick}
        selectedPage={selectedPage}
        pageCount={Math.ceil(total / ITEMS_PER_PAGE)}
      />
    </div>
  );
}

export default UsersPage;

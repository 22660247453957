import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import swal from '@sweetalert/with-react';
import ROUTES from '../../../../constants/routes';
import {
  deleteVariableExpense,
  getVariableExpenses,
  updateVariableExpense,
} from '../../../../redux/actions/variableExpenses';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';
import Dialog from '../../../components/Dialog/Dialog';
import Pagination from '../../../components/Pagination/Pagination';
import styles from './VariableExpensesPage.module.css';

const ITEMS_PER_PAGE = 10;

function VariableExpensesPage() {
  const currentItems = useSelector(
    (state) => state.variableExpenses.variableExpenses
  );
  const total = useSelector((state) => state.variableExpenses.total);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState(
    +new URLSearchParams(location.search).get('page') || 0
  );

  const getVariableExpensesData = (page) => {
    dispatch(getVariableExpenses({ page: page + 1, per_page: ITEMS_PER_PAGE }));
  };

  const handlePageClick = (event) => {
    setSelectedPage(event.selected);
    navigate({
      pathname: window.location.pathname,
      search: `?page=${event.selected}`,
    });
  };

  useEffect(() => {
    getVariableExpensesData(selectedPage);
  }, [selectedPage]);

  const editVariableExpense = (id) => {
    navigate(`${ROUTES.ADMIN.VARIABLE_EXPENSES}/${id}/edit`);
  };

  const removeVariableExpense = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <Dialog
          title="Delete Variable Expense"
          text="Are you sure you want to delete this variable expense?"
          buttons={[
            {
              type: 'button',
              text: 'Delete',
              onClick: () => {
                dispatch(
                  deleteVariableExpense(id, () =>
                    dispatch(
                      getVariableExpenses({
                        page: selectedPage + 1,
                        per_page: ITEMS_PER_PAGE,
                      })
                    )
                  )
                );
                onClose();
              },
              className: 'secondary-button',
            },
            {
              type: 'button',
              text: 'Cancel',
              onClick: onClose,
              className: 'secondary-button',
            },
          ]}
        />
      ),
    });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const onDisplayedChanged = (id, name, checked) => {
    swal({
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: (
        <p>
          {checked
            ? 'Do you really want to activate this variable expense?'
            : 'Do you really want to deactivate this variable expense?'}
        </p>
      ),
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          className: 'button outlined-secondary-button -thin',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          visible: true,
          className: 'button primary-button -thin',
          closeModal: true,
        },
      },
      icon: 'warning',
    }).then(function (isConfirm) {
      if (isConfirm) {
        dispatch(
          updateVariableExpense({ id, displayed: checked, name }, () => {
            getVariableExpensesData(selectedPage);
          })
        );
      }
    });
  };

  const onDefaultChanged = (id, name, checked) => {
    swal({
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: (
        <p>
          {checked
            ? 'Do you really want to make this variable expense default?'
            : 'Do you really want to make this variable expense not default?'}
        </p>
      ),
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          className: 'button outlined-secondary-button -thin',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          visible: true,
          className: 'button primary-button -thin',
          closeModal: true,
        },
      },
      icon: 'warning',
    }).then(function (isConfirm) {
      if (isConfirm) {
        dispatch(
          updateVariableExpense({ id, default: checked, name }, () => {
            getVariableExpensesData(selectedPage);
          })
        );
      }
    });
  };

  return (
    <div>
      <div className={styles.header}>
        <h2 className="subtitle">Variable Expenses -- {total}</h2>
        <button
          type="button"
          onClick={() => {
            navigate(ROUTES.ADMIN.CREATE_VARIABLE_EXPENSES);
          }}
          className="button withImg primary-button -thin"
        >
          <span className="material-icons">add</span>
          <span>Create New Variable Expense</span>
        </button>
      </div>
      <Breadcrumbs />
      {total === 0 ? (
        'No variable expenses yet'
      ) : (
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th className={styles.isDefault}>
                  <span>Is default?</span>
                  <span
                    className={`material-icons ${styles.info}`}
                    data-tip
                    data-for="is-default"
                  >
                    info
                  </span>
                </th>
                <th>
                  <span>Deactivate expense</span>
                  <span
                    className={`material-icons ${styles.info}`}
                    data-tip
                    data-for="deactivate-expense"
                  >
                    info
                  </span>
                </th>
                <th>Variable Expense</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <input
                          className={styles.displayed}
                          type="checkbox"
                          onChange={(e) =>
                            onDefaultChanged(
                              item.id,
                              item.name,
                              e.target.checked
                            )
                          }
                          checked={item.default}
                        />
                      </td>
                      <td>
                        <input
                          className={styles.displayed}
                          type="checkbox"
                          disabled={!item.can_be_deleted}
                          onChange={(e) =>
                            onDisplayedChanged(
                              item.id,
                              item.name,
                              e.target.checked
                            )
                          }
                          checked={item.displayed}
                        />
                        {!item.can_be_deleted && (
                          <span
                            className={`material-icons ${styles.info}`}
                            data-tip
                            data-for="can-be-deactivated"
                          >
                            info
                          </span>
                        )}
                      </td>
                      <td>{item.name}</td>
                      <td className={styles.buttons}>
                        <div>
                          <button
                            type="button"
                            className="button action-button"
                            onClick={() => editVariableExpense(item.id)}
                          >
                            <span className="material-icons">edit</span>
                          </button>
                          <button
                            type="button"
                            disabled={!item.can_be_deleted}
                            className="button action-button"
                            onClick={() => removeVariableExpense(item.id)}
                          >
                            <span className="material-icons">delete</span>
                          </button>
                          {!item.can_be_deleted && (
                            <span
                              className={`material-icons ${styles.info}`}
                              data-tip
                              data-for="can-be-deleted"
                            >
                              info
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        onPageChange={handlePageClick}
        selectedPage={selectedPage}
        pageCount={Math.ceil(total / ITEMS_PER_PAGE)}
      />
      <ReactTooltip id="can-be-deactivated" place="top" effect="solid">
        This variable expense cannot be deactivated because it's used in some
        properties
      </ReactTooltip>
      <ReactTooltip id="can-be-deleted" place="top" effect="solid">
        This variable expense cannot be deleted because it's used in some
        properties
      </ReactTooltip>
      <ReactTooltip id="is-default" place="top" effect="solid">
        Checked variable expenses will be selected by default
      </ReactTooltip>
      <ReactTooltip id="deactivate-expense" place="top" effect="solid">
        Deactivated variable expenses are not visible to user
      </ReactTooltip>
    </div>
  );
}

export default VariableExpensesPage;

import { updateErrors } from '../helpers';
import { setGeneralErrorMessage } from './form';

export const onFailure = (dispatch, form, error) => {
  if (error.status === 422) {
    updateErrors(dispatch, form, error.data.errors);
  } else if (error.status === 500) {
    dispatch(setGeneralErrorMessage(error.data.message));
  }
};

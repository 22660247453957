import React from 'react';

export const getSnippetBySlug = (snippets, slug, removeTags = false) => {
  const text =
    snippets && snippets.find((snippet) => snippet.slug === slug)?.name;
  if (removeTags) {
    return text ? text.replace(/<\/?[^>]+(>|$)/g, '') : '';
  }
  return <span dangerouslySetInnerHTML={{ __html: text }} />;
};

export const getTooltipBySlug = (snippets, slug) => {
  return (
    snippets &&
    snippets.find((snippet) => snippet.slug === slug).tooltip && (
      <span
        dangerouslySetInnerHTML={{
          __html: snippets.find((snippet) => snippet.slug === slug).tooltip,
        }}
      />
    )
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import styles from './Input.module.css';

function Input({
  placeholder,
  type,
  label,
  input,
  disabled,
  className,
  prefix,
  suffix,
  onBlurFormatter,
  onFocusFormatter,
  meta: { error, invalid, touched, warning },
}) {
  return (
    <div
      className={`${type !== 'hidden' ? styles.fieldContainer : ''} ${
        className || ''
      } `}
    >
      {label && <label htmlFor={input.name}>{label}</label>}
      {type !== 'hidden' && (
        <div className={`${styles.input}`}>
          {prefix && <span>{prefix}</span>}
          <input
            {...input}
            id={input.name}
            placeholder={placeholder}
            onFocus={(e) => {
              input.onFocus(onFocusFormatter ? onFocusFormatter(e) : e);
            }}
            onBlur={(e) => {
              input.onBlur(
                onBlurFormatter ? onBlurFormatter(e.target.value) : e
              );
            }}
            data-cy={input.name}
            type={type}
            disabled={disabled}
          />
          {suffix && <span>{suffix}</span>}
        </div>
      )}
      {invalid && touched && error && (
        <span data-cy="field-error-label" className="field-error-label">
          {error}
        </span>
      )}
      {warning && (
        <span data-cy="field-error-label" className="field-error-label">
          {warning}
        </span>
      )}
    </div>
  );
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default Input;

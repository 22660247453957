import React from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import ROUTES from '../../../constants/routes';
import styles from './Breadcrumb.module.css';

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs();

  return (
    <div className={styles.container}>
      {breadcrumbs.map(({ breadcrumb, location }, index) => {
        if (
          breadcrumb.key === '/admin' ||
          (Number.isInteger(+breadcrumb.props.children) &&
            !breadcrumb.key.startsWith('/admin/profile')) ||
          (breadcrumb.props.children === 'Profile' &&
            breadcrumb.key.startsWith('/admin/profile') &&
            index !== breadcrumbs.length - 1)
        )
          return;
        return (
          <span
            className={`${styles.breadcrumb} ${
              breadcrumb.key === location.pathname ? styles.active : ''
            }`}
          >
            {breadcrumb.key.startsWith('/admin/profile') &&
            Number.isInteger(+breadcrumb.props.children) ? (
              <NavLink to={ROUTES.ADMIN.USERS}>Users</NavLink>
            ) : (
              <NavLink to={breadcrumb.key}>{breadcrumb}</NavLink>
            )}
          </span>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;

import React from 'react';
import { reduxForm, Field, formValues } from 'redux-form';
import Input from '../../../components/Input/Input';
import styles from './SnippetSearchForm.module.css';

function SnippetSearchForm({ onSubmit, handleSubmit, search }) {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Field
        placeholder="Search by slug, group, name..."
        type="search"
        name="search"
        onChange={(e) => {
          if (!e.target.value) {
            onSubmit({ search: undefined });
          }
        }}
        component={Input}
      />
      <button disabled={!search} className="button primary-button">
        Search
      </button>
    </form>
  );
}

export default reduxForm({
  form: 'snippet-search-form',
})(formValues('search')(SnippetSearchForm));

import { SET_SNIPPETS } from '../action-types/data';

const initialState = {
  snippets: null,
  total: null,
};

const snippetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNIPPETS:
      return {
        ...state,
        snippets: action.snippets,
        total: action.count,
      };
    default:
      return state;
  }
};

export default snippetsReducer;

import { API, REQUEST_END, REQUEST_START } from '../action-types/api';

export const requestStart = () => ({
  type: REQUEST_START,
});

export const requestEnd = () => ({
  type: REQUEST_END,
});

export const apiAction = ({
  url,
  method = 'GET', // Default method
  data = null,
  onSuccess = () => {},
  onFailure = () => {},
  form = null,
  isMultipart = false,
}) => {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      onSuccess,
      onFailure,
      form,
      isMultipart,
    },
  };
};

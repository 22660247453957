import logo from 'public/assets/images/logo.png';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import { logOut } from '../../../redux/actions/auth';
import { getSnippets } from '../../../redux/actions/snippets';
import Avatar from '../Avatar/Avatar';
import Dropdown from '../Dropdown/Dropdown';
import styles from './AdminHeader.module.css';

function AdminHeader() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(
      getSnippets({ pagination: 0 }, () => {
        dispatch(logOut());
      })
    );
  };

  const goToProfile = () => {
    navigate(ROUTES.ADMIN.PROFILE);
  };

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.header}>
        <div
          className={styles.left}
          onClick={() => navigate(ROUTES.ADMIN.HOME)}
        >
          {/* <div>
            <img src={logo} alt="Application Logo" />
          </div> */}
        </div>
        <div className={styles.right}>
          <div className={styles.avatar}>
            <Avatar src={user.thumb} />
          </div>
          <Dropdown
            title={user.name}
            menu={[
              { title: 'Profile', onClick: goToProfile, icon: 'person' },
              { title: 'Log Out', onClick: logout, icon: 'logout' },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminHeader;

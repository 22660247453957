import {
  SET_DASHBOARD_CONTENT,
  SET_RECENT_PROPERTIES,
  SET_TOP_FIVE_BY_COC,
  SET_TOP_FIVE_BY_NOI,
} from '../action-types/dashboard';

const initialState = {
  topByNoi: null,
  topByCoc: null,
  recentProperties: null,
  count: 0,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_CONTENT:
      return {
        ...state,
        topByNoi: action.data.topByNoi,
        topByCoc: action.data.topByCoc,
        recentProperties: action.data.recentProperties,
        count: action.data.count,
      };
    case SET_TOP_FIVE_BY_COC:
      return {
        ...state,
        topByCoc: action.data,
      };
    case SET_TOP_FIVE_BY_NOI:
      return {
        ...state,
        topByNoi: action.data,
      };
    case SET_RECENT_PROPERTIES:
      return {
        ...state,
        recentProperties: action.data,
      };
    default:
      return state;
  }
};

export default dashboardReducer;

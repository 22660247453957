import ENDPOINTS from '../../constants/endpoints';
import { SET_SNIPPETS } from '../action-types/data';
import { apiAction } from './api';
import { setGeneralSuccessMessage } from './form';

export const getSnippets = (parameters, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.SNIPPETS,
    onSuccess: (dispatch, _, data) => {
      dispatch(setSnippets(data));
      onSuccess && onSuccess();
    },
    method: 'GET',
    data: parameters,
  });
};

export const getSnippet = (id, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.SNIPPET(id),
    onSuccess: (dispatch, _, data) => {
      onSuccess && onSuccess(data.data);
    },
    method: 'GET',
  });
};

export const createSnippet = (data, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.SNIPPETS,
    onSuccess: (dispatch, _, data) => {
      onSuccess && onSuccess(data);
      dispatch(
        setGeneralSuccessMessage('Snippet has been created successfully')
      );
    },
    method: 'POST',
    data,
  });
};

export const deleteSnippet = (id, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.SNIPPET(id),
    method: 'DELETE',
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(
        setGeneralSuccessMessage('Snippet has been deleted successfully')
      );
    },
  });
};

export const updateSnippet = (data, onSuccess) => {
  const { id, ...parameters } = data;
  return apiAction({
    url: ENDPOINTS.DATA.SNIPPET(id),
    method: 'PUT',
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(
        setGeneralSuccessMessage('Snippet has been updated successfully')
      );
    },
    data: parameters,
  });
};

export const setSnippets = (data) => {
  return {
    type: SET_SNIPPETS,
    snippets: data.data,
    count: data.meta && data.meta.total,
  };
};

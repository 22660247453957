import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { CURRENCY_LABEL } from '../../../../constants/currency';
import ROUTES from '../../../../constants/routes';
import {
  createVariableExpense,
  getVariableExpense,
  updateVariableExpense,
} from '../../../../redux/actions/variableExpenses';
import { formatNumberWithComma } from '../../../../utils/formatters';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';
import CreateVariableExpenseForm from '../../../forms/admin/CreateVariableExpenseForm/CreateVariableExpenseForm';
import styles from './CreateVariableExpensesPage.module.css';

function CreateVariableExpensesPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const parameters = useParams();
  const [variableExpense, setVariableExpense] = useState(null);

  const onSubmit = (values) => {
    if (parameters.id) {
      dispatch(
        updateVariableExpense({ id: parameters.id, ...values }, () => {
          navigate(
            location.key === 'default' ? ROUTES.ADMIN.VARIABLE_EXPENSES : -1
          );
        })
      );
    } else {
      dispatch(
        createVariableExpense(
          { ...values, measurements: values.measurements.value },
          () => {
            navigate(
              location.key === 'default' ? ROUTES.ADMIN.VARIABLE_EXPENSES : -1
            );
          }
        )
      );
    }
  };

  useEffect(() => {
    if (parameters.id) {
      dispatch(
        getVariableExpense(parameters.id, (data) => {
          setVariableExpense(data);
        })
      );
    }
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <button
          type="button"
          className="button withImg outlined-secondary-button -thin"
          onClick={() =>
            navigate(
              location.key === 'default' ? ROUTES.ADMIN.VARIABLE_EXPENSES : -1
            )
          }
        >
          <span className="material-icons">chevron_left</span>
          <span>Back</span>
        </button>
        <h2 className="subtitle">
          {!parameters.id
            ? 'Create new variable expense'
            : 'Edit variable expense'}
        </h2>
      </div>
      <Breadcrumbs />
      <CreateVariableExpenseForm
        initialValues={
          variableExpense
            ? {
                ...variableExpense,
                measurements: {
                  value: variableExpense.measurements,
                  label: CURRENCY_LABEL[variableExpense.measurements],
                },
                default_value: formatNumberWithComma(
                  variableExpense.default_value.toFixed(2)
                ),
              }
            : {}
        }
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default CreateVariableExpensesPage;

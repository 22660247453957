import React, { useEffect, useState, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import placeholderImage from 'public/assets/placeholder-image.png';
import ROUTES from '../../../../constants/routes';
import {
  deleteProperty,
  getClientProperties,
  getProperties,
} from '../../../../redux/actions/properties';
import {
  formatDollarValues,
  formatPercentValues,
} from '../../../../utils/formatters';
import Dialog from '../../../components/Dialog/Dialog';
import Pagination from '../../../components/Pagination/Pagination';
import styles from './ClientPropertiesPage.module.css';
import { getSnippetBySlug } from '../../../../helpers/snippets';
import SNIPPETS from '../../../../constants/snippets';
import PropertySearchForm from '../../../forms/PropertySearchForm/PropertySearchForm';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';

function ClientPropertiesPage() {
  const currentItems = useSelector((state) => state.properties.properties);
  const total = useSelector((state) => state.properties.total);
  const user = useSelector((state) => state.auth.user);
  const snippets = useSelector((state) => state.snippets.snippets);
  const [filters, setFilters] = useState({});
  const parameters = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ITEMS_PER_PAGE, setItemsPerPage] = useState(20);
  const grossUpRule = useSelector(
    (state) => state.auth.user.color_data_gross_yield
  )[0];
  const cashRule = useSelector(
    (state) => state.auth.user.color_data_cash_return
  )[0];
  const colorDataRules = useSelector(
    (state) => state.auth.user.color_data_rules
  )[0];
  const [sortBy, setSortBy] = useState({});
  const isFirstRender = useRef(true);

  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState(0);

  const getPropertiesData = (page) => {
    const filtersData = {};
    for (const [key, value] of Object.entries(filters)) {
      if (Array.isArray(value) && value.length > 0) {
        filtersData[`filters[${key}]`] = value
          .map((item) => item.value)
          .join(',');
      } else {
        filtersData[`filters[${key}]`] = value;
      }
    }
    dispatch(
      getClientProperties(parameters.userId, {
        page: page + 1,
        per_page: ITEMS_PER_PAGE,
        ...sortBy,
        ...filtersData,
      })
    );
  };

  useEffect(() => {
    getPropertiesData(selectedPage);
  }, [selectedPage, filters]);

  useEffect(() => {
    setSelectedPage(0);
    getPropertiesData(0);
  }, [ITEMS_PER_PAGE]);

  useEffect(() => {
    if (!isFirstRender.current) {
      getPropertiesData(selectedPage);
    } else {
      isFirstRender.current = false;
    }
  }, [sortBy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSort = (field) => {
    setSortBy({
      ...sortBy,
      [`sort[${field}]`]:
        sortBy[`sort[${field}]`] === 'asc'
          ? 'desc'
          : sortBy[`sort[${field}]`] === 'desc'
          ? undefined
          : 'asc',
    });
  };

  const handlePageClick = (event) => {
    setSelectedPage(event.selected);
  };

  const removeProperty = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <Dialog
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTIES_PAGE.DELETE_TITLE
          )}
          text={getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTIES_PAGE.DELETE_SUBTITLE
          )}
          buttons={[
            {
              type: 'button',
              text: 'Delete',
              onClick: () => {
                dispatch(
                  deleteProperty({
                    id,
                    onSuccess: () =>
                      dispatch(
                        getProperties({
                          page: selectedPage + 1,
                          per_page: ITEMS_PER_PAGE,
                        })
                      ),
                  })
                );
                onClose();
              },
              className: 'secondary-button',
            },
            {
              type: 'button',
              text: 'Cancel',
              onClick: onClose,
              className: 'secondary-button',
            },
          ]}
        />
      ),
    });
  };

  const editProperty = (id) => {
    navigate(`${ROUTES.USER.PROPERTIES}/${id}/edit`);
  };

  return (
    <div>
      <div className={styles.header}>
        <div>
          <button
            type="button"
            className="button withImg outlined-secondary-button -thin"
            onClick={() => navigate(-1)}
          >
            <span className="material-icons">chevron_left</span>
            <span>Back</span>
          </button>
          <h2 className={`subtitle ${styles.title}`}>{location.state.name}</h2>
        </div>
        <p>Total Number of Properties: {total}</p>
      </div>
      <Breadcrumbs />
      <div className={styles.filters}>
        <div>
          <PropertySearchForm
            onSubmit={(values) => {
              setFilters({ ...filters, ...values });
            }}
          />
        </div>
      </div>

      {total === 0 ? (
        'No properties yet'
      ) : (
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROPERTIES_PAGE.PHOTO_COL
                  )}
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.MARKET_NAME_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('markets.name')}>
                      {(sortBy['sort[markets.name]'] === 'asc' ||
                        !sortBy['sort[markets.name]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[markets.name]'] === 'desc' ||
                        !sortBy['sort[markets.name]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.ADDRESS_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('address')}>
                      {(sortBy['sort[address]'] === 'asc' ||
                        !sortBy['sort[address]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[address]'] === 'desc' ||
                        !sortBy['sort[address]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.REVENUE_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('projected_rent')}>
                      {(sortBy['sort[projected_rent]'] === 'asc' ||
                        !sortBy['sort[projected_rent]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[projected_rent]'] === 'desc' ||
                        !sortBy['sort[projected_rent]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.STARTUP_COSTS_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('investment_total')}>
                      {(sortBy['sort[investment_total]'] === 'asc' ||
                        !sortBy['sort[investment_total]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[investment_total]'] === 'desc' ||
                        !sortBy['sort[investment_total]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.ANNUAL_EXPENSES_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('annual_expenses')}>
                      {(sortBy['sort[annual_expenses]'] === 'asc' ||
                        !sortBy['sort[annual_expenses]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[annual_expenses]'] === 'desc' ||
                        !sortBy['sort[annual_expenses]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.CASH_FLOW_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('cash_flow')}>
                      {(sortBy['sort[cash_flow]'] === 'asc' ||
                        !sortBy['sort[cash_flow]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[cash_flow]'] === 'desc' ||
                        !sortBy['sort[cash_flow]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.CAP_RATE_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('cap_rate')}>
                      {(sortBy['sort[cap_rate]'] === 'asc' ||
                        !sortBy['sort[cap_rate]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[cap_rate]'] === 'desc' ||
                        !sortBy['sort[cap_rate]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.GROSS_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('gross_yield')}>
                      {(sortBy['sort[gross_yield]'] === 'asc' ||
                        !sortBy['sort[gross_yield]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[gross_yield]'] === 'desc' ||
                        !sortBy['sort[gross_yield]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.CASH_ON_CASH_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('cash_on_cash_return')}>
                      {(sortBy['sort[cash_on_cash_return]'] === 'asc' ||
                        !sortBy['sort[cash_on_cash_return]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[cash_on_cash_return]'] === 'desc' ||
                        !sortBy['sort[cash_on_cash_return]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        {item.photos ? (
                          <img src={item.photos.thumb} alt="Property photo" />
                        ) : (
                          <img
                            className={styles.imagePlaceholder}
                            src={placeholderImage}
                            alt="Image placeholder"
                          />
                        )}
                      </td>
                      <td className="capitalized">{item.market_name}</td>
                      <td className="nowrap">
                        <NavLink
                          className="link"
                          to={
                            item.parsing_url || `/admin/properties/${item.id}`
                          }
                          target={item.parsing_url ? '_blank' : '_self'}
                        >
                          {item.address}
                        </NavLink>
                      </td>
                      <td>{formatDollarValues(item.projected_rent)}</td>
                      <td>{formatDollarValues(item.investment_total)}</td>
                      <td>{formatDollarValues(item.annual_expenses)}</td>
                      <td>{formatDollarValues(item.cash_flow)}</td>
                      <td>
                        <span
                          className={`${
                            item.cap_rate < +colorDataRules.red
                              ? 'filled-error'
                              : item.cap_rate < +colorDataRules.yellow
                              ? 'filled-warning'
                              : 'filled-success'
                          } breadcrumb`}
                        >
                          {formatPercentValues(item.cap_rate)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            item.gross_yield < +grossUpRule.red
                              ? 'filled-error'
                              : item.gross_yield < +grossUpRule.yellow
                              ? 'filled-warning'
                              : 'filled-success'
                          } breadcrumb`}
                        >
                          {formatPercentValues(+item.gross_yield)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            item.cash_on_cash_return < +cashRule.red
                              ? 'filled-error'
                              : item.cash_on_cash_return < +cashRule.yellow
                              ? 'filled-warning'
                              : 'filled-success'
                          } breadcrumb`}
                        >
                          {formatPercentValues(item.cash_on_cash_return)}
                        </span>
                      </td>
                      <td>
                        <div className={styles.buttons}>
                          <button
                            type="button"
                            className="button action-button"
                            onClick={() =>
                              navigate(`/admin/properties/${item.id}`)
                            }
                          >
                            <span className="material-icons">visibility</span>
                          </button>
                          {user.id === item.user.id && (
                            <button
                              type="button"
                              className="button action-button"
                              onClick={() => editProperty(item.id)}
                            >
                              <span className="material-icons">edit</span>
                            </button>
                          )}
                          {user.id === item.user.id && (
                            <button
                              type="button"
                              className="button action-button"
                              onClick={() => removeProperty(item.id)}
                            >
                              <span className="material-icons">delete</span>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {total !== 0 && (
        <Pagination
          onPageChange={handlePageClick}
          selectedPage={selectedPage}
          numberPerPageOptions={[20, 30, 50, 100]}
          numberPerPage={ITEMS_PER_PAGE}
          onNumberPerPageChanged={setItemsPerPage}
          pageCount={Math.ceil(total / ITEMS_PER_PAGE)}
        />
      )}
    </div>
  );
}

export default ClientPropertiesPage;

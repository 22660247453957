import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { newPasswordsMustMatch, required } from '../../../utils/validators';
import { getSnippetBySlug } from '../../../helpers/snippets';
import Input from '../../components/Input/Input';
import SNIPPETS from '../../../constants/snippets';

function ChangePasswordForm({ handleSubmit, onSubmit, form }) {
  const snippets = useSelector((state) => state.snippets.snippets);
  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values, form))}>
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.CURRENT_PASS_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="password"
        name="current_password"
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.CURRENT_PASS_LABEL
        )}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.NEW_PASS_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="password"
        name="new_password"
        label={getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.NEW_PASS_LABEL)}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.CONFIRM_PASS_PLACEHOLDER,
          true
        )}
        validate={[required, newPasswordsMustMatch]}
        type="password"
        name="confirm_password"
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.CONFIRM_PASS_LABEL
        )}
        component={Input}
      />
      <button type="submit" className="button primary-button">
        {getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.SAVE_PASSWORD_BUTTON)}
      </button>
    </form>
  );
}

export default reduxForm({
  form: 'change-password-form',
})(ChangePasswordForm);

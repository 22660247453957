import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { useParams } from 'react-router-dom';
import styles from './UserProfilePage.module.css';
import {
  deleteAvatar,
  deleteAvatarById,
  updateAvatar,
  updateAvatarById,
  updateProfile,
  updateProfileById,
} from '../../../redux/actions/profile';
import Avatar from '../../components/Avatar/Avatar';
import Card from '../../components/Card/Card';
import Dialog from '../../components/Dialog/Dialog';
import ChangePasswordForm from '../../forms/ChangePasswordForm/ChangePasswordForm';
import GeneralProfileInfoForm from '../../forms/GeneralProfileInfoForm/GeneralProfileInfoForm';
import { getProfile, removeUser } from '../../../redux/actions/auth';
import SetColorsForm from '../../forms/SetColorsForm/SetColorsForm';
import { getSnippetBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';
import ROLES from '../../../constants/roles';
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumb';
import { getSnippets } from '../../../redux/actions/snippets';
import ReportPersonalDataForm from '../../forms/admin/ReportPersonalDataForm/ReportPersonalDataForm';

function UserProfilePage() {
  const user = useSelector((store) => store.auth.user);
  const dispatch = useDispatch();
  const snippets = useSelector((state) => state.snippets.snippets);
  const [currentTab, setCurrentTab] = useState(0);

  const [userToEdit, setUserToEdit] = useState(null);
  const parameters = useParams();

  useEffect(() => {
    if (user.role[0].name !== ROLES.CLIENT) {
      dispatch(getSnippets({ pagination: 0 }));
    }
    if (parameters.id) {
      dispatch(
        getProfile(parameters.id, (user) => {
          setUserToEdit(user);
        })
      );
    }
  }, []);

  const isProfileView = user.role[0].name === ROLES.CLIENT || !parameters.id;

  const tabs =
    user.role[0].name === ROLES.CLIENT
      ? [
          getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.GENERAL_INFO_TAB),
          getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.PASSWORD_TAB),
          getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.DISCLAIMER_TAB),
        ]
      : !parameters.id
      ? [
          getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.GENERAL_INFO_TAB),
          getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.PASSWORD_TAB),
        ]
      : [
          getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.GENERAL_INFO_TAB),
          getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.PASSWORD_TAB),
          getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.DESIRED_METRICS_TAB),
        ];

  const saveAvatar = (e) => {
    if (parameters.id) {
      dispatch(
        updateAvatarById(
          parameters.id,
          { avatar: e.currentTarget.files[0] },
          () => {
            dispatch(
              getProfile(parameters.id, (user) => {
                setUserToEdit(user);
              })
            );
          }
        )
      );
    } else {
      dispatch(updateAvatar({ avatar: e.currentTarget.files[0] }));
    }
  };

  const removeAvatar = () => {
    if (parameters.id) {
      dispatch(
        deleteAvatarById(parameters.id, () => {
          dispatch(
            getProfile(parameters.id, (user) => {
              setUserToEdit(user);
            })
          );
        })
      );
    } else {
      dispatch(deleteAvatar());
    }
  };

  const removeProfile = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <Dialog
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.PROFILE_PAGE.REMOVE_PROFILE_TITLE
          )}
          text={getSnippetBySlug(
            snippets,
            SNIPPETS.PROFILE_PAGE.REMOVE_PROFILE_SUBTITLE
          )}
          buttons={[
            {
              type: 'button',
              text: 'Remove account',
              onClick: () => dispatch(removeUser()),
              className: 'secondary-button',
            },
            {
              type: 'button',
              text: 'Cancel',
              onClick: onClose,
              className: 'secondary-button',
            },
          ]}
        />
      ),
    });
  };

  const saveProfileInfo = (values, form) => {
    if (values[form] && values[form].colors) {
      const red = String(values[form].colors[1]);
      const yellow = String(values[form].colors[2]);
      const green = String(values[form].colors[2]);
      values[form] = [{ red, yellow, green }];
    }
    const data = {
      ...values,
      phone: values.phone || undefined,
      location: values.location || undefined,
      job_title: values.job_title || undefined,
      site: values.site || undefined,
    };
    const request = parameters.id
      ? updateProfileById(parameters.id, data)
      : updateProfile(data, form);
    dispatch(request);
  };

  return (
    <div>
      <div className={styles.header}>
        <h2 className="subtitle">
          {getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.TITLE)}
        </h2>
        {!parameters.id && (
          <button
            onClick={removeProfile}
            type="button"
            className="button withImg outlined-secondary-button -thin"
          >
            <span className="material-icons">delete</span>
            {getSnippetBySlug(
              snippets,
              SNIPPETS.PROFILE_PAGE.REMOVE_PROFILE_BUTTON
            )}
          </button>
        )}
      </div>
      {user.role[0].name !== ROLES.CLIENT && <Breadcrumbs />}
      <div className={styles.tabs}>
        <ul>
          {tabs.map((tab, index) => (
            <li
              className={index === currentTab && styles.active}
              onClick={() => setCurrentTab(index)}
              key={index}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>
      {currentTab === 0 ? (
        <div>
          <Card>
            <div className={styles.uploadAvatar}>
              <Avatar
                src={
                  parameters.id ? userToEdit && userToEdit.thumb : user.thumb
                }
              />
              <div className={styles.buttons}>
                <input
                  className={styles.inputFile}
                  id="upload-avatar"
                  type="file"
                  onChange={saveAvatar}
                />
                <button
                  type="button"
                  onClick={() =>
                    document.querySelector('#upload-avatar').click()
                  }
                  className="button withImg outlined-primary-button -thin"
                >
                  <span className="material-icons">add</span>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROFILE_PAGE.UPLOAD_PHOTO_BUTTON
                  )}
                </button>
                <button
                  type="button"
                  onClick={removeAvatar}
                  className="button withImg borderless-outlined-secondary-button -thin"
                >
                  <span className="material-icons">delete</span>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROFILE_PAGE.REMOVE_PHOTO_BUTTON
                  )}
                </button>
              </div>
            </div>
          </Card>
          <div className={styles.profileInfo}>
            <Card
              title={getSnippetBySlug(
                snippets,
                SNIPPETS.PROFILE_PAGE.GENERAL_INFORMATION_SUBTITLE
              )}
            >
              <GeneralProfileInfoForm
                onSubmit={saveProfileInfo}
                initialValues={{
                  email: parameters.id ? userToEdit?.email : user.email,
                  name: parameters.id ? userToEdit?.name : user.name,
                }}
              />
            </Card>
          </div>
        </div>
      ) : currentTab === 1 ? (
        <div className={styles.profileInfo}>
          {parameters.id ? (
            <p>You cannot change password</p>
          ) : (
            <Card
              title={getSnippetBySlug(
                snippets,
                SNIPPETS.PROFILE_PAGE.PASSWORD_SUBTITLE
              )}
            >
              <ChangePasswordForm onSubmit={saveProfileInfo} />
            </Card>
          )}
        </div>
      ) : !isProfileView ? (
        <div className={styles.profileInfo}>
          <Card
            title={getSnippetBySlug(
              snippets,
              SNIPPETS.PROFILE_PAGE.ONE_RULE_SUBTITLE
            )}
          >
            <SetColorsForm
              form="color_data_rules"
              onSubmit={saveProfileInfo}
              marks={{ 0: '0%', 50: '50%+' }}
              max={50}
              initialValues={{
                color_data_rules: {
                  colors: userToEdit.color_data_rules
                    ? [
                        0,
                        userToEdit.color_data_rules[0].red,
                        userToEdit.color_data_rules[0].yellow,
                        50,
                      ]
                    : [0, 20, 40, 50],
                },
              }}
            />
          </Card>
          <Card
            title={getSnippetBySlug(
              snippets,
              SNIPPETS.PROFILE_PAGE.CASH_ON_CASH_SUBTITLE
            )}
          >
            <SetColorsForm
              form="color_data_cash_return"
              onSubmit={saveProfileInfo}
              max={100}
              marks={{ 0: '0%', 100: '100%+' }}
              initialValues={{
                color_data_cash_return: {
                  colors: userToEdit.color_data_cash_return
                    ? [
                        0,
                        userToEdit.color_data_cash_return[0].red,
                        userToEdit.color_data_cash_return[0].yellow,
                        100,
                      ]
                    : [0, 25, 75, 100],
                },
              }}
            />
          </Card>
          <Card
            title={getSnippetBySlug(
              snippets,
              SNIPPETS.PROFILE_PAGE.GROSS_YIELD_SUBTITLE
            )}
          >
            <SetColorsForm
              form="color_data_gross_yield"
              onSubmit={saveProfileInfo}
              max={100}
              marks={{ 0: '0%', 100: '100%+' }}
              initialValues={{
                color_data_gross_yield: {
                  colors: user.color_data_gross_yield
                    ? [
                        0,
                        user.color_data_gross_yield[0].red,
                        user.color_data_gross_yield[0].yellow,
                        100,
                      ]
                    : [0, 10, 15, 100],
                },
              }}
            />
          </Card>
        </div>
      ) : (
        <div className={styles.profileInfo}>
          <Card
            title={getSnippetBySlug(
              snippets,
              SNIPPETS.PROFILE_PAGE.PDF_INFORMATION_SUBTITLE
            )}
          >
            <ReportPersonalDataForm
              onSubmit={saveProfileInfo}
              initialValues={
                parameters.id
                  ? {
                      phone: userToEdit.phone,
                      location: userToEdit.location,
                      job_title: userToEdit.job_title,
                      site: userToEdit.site,
                    }
                  : {
                      phone: user.phone,
                      location: user.location,
                      job_title: user.job_title,
                      site: user.site,
                    }
              }
            />
          </Card>
        </div>
      )}
    </div>
  );
}

export default UserProfilePage;

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { number, percent, required } from '../../../utils/validators';
import { onBlurFormatter, onFocusFormatter } from '../../../utils/formatters';
import NumberInput from '../../components/NumberInput/NumberInput';
import Input from '../../components/Input/Input';

function PreferencesForm({ onSubmit, handleSubmit, change }) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        placeholder="Down Payment"
        validate={[required, number, percent]}
        onFocusFormatter={(e) => onFocusFormatter(e, change)}
        onBlurFormatter={(value) => onBlurFormatter(value, true)}
        change={change}
        type="text"
        suffix="%"
        name="default_down_payment"
        label="Down Payment"
        component={NumberInput}
      />
      <Field
        placeholder="Property Management Fee"
        label="Property Management Fee"
        validate={[required, number, percent]}
        type="text"
        suffix="%"
        onFocusFormatter={(e) => onFocusFormatter(e, change)}
        onBlurFormatter={(value) => onBlurFormatter(value, true)}
        name="default_property_management_fee"
        component={Input}
      />
      <Field
        placeholder="Capital Expenditures"
        label="Capital Expenditures"
        validate={[required, number, percent]}
        type="text"
        suffix="%"
        onFocusFormatter={(e) => onFocusFormatter(e, change)}
        onBlurFormatter={(value) => onBlurFormatter(value, true)}
        name="default_capital_expenditures"
        component={Input}
      />
      <button className="button primary-button">Save</button>
    </form>
  );
}

export default reduxForm({
  form: 'preferences-form',
})(PreferencesForm);

import { SET_MARKETS } from '../action-types/markets';

const initialState = {
  markets: null,
  total: null,
};

const marketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MARKETS:
      return {
        ...state,
        markets: action.markets,
        total: action.count,
      };
    default:
      return state;
  }
};

export default marketsReducer;

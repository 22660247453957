import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import placeholderImage from 'public/assets/placeholder-image.png';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Card from '../../components/Card/Card';
import styles from './HomePage.module.css';
import {
  getDashboardData,
  getRecentProperties,
  getTopFiveCoc,
  getTopFiveNoi,
} from '../../../redux/actions/dashboard';
import {
  formatDollarValues,
  formatNumberWithComma,
  formatPercentValues,
} from '../../../utils/formatters';
import ROUTES from '../../../constants/routes';
import { getSnippetBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';
import NoiFilter from '../../forms/NoiFilter/NoiFilter';
import CashOnCashFilter from '../../forms/CashOnCashFilter/CashOnCashFilter';
import FormDialog from '../../components/FormDialog/FormDialog';

function HomePage() {
  const dispatch = useDispatch();
  const snippets = useSelector((state) => state.snippets.snippets);
  const cashRule = useSelector(
    (state) => state.auth.user.color_data_cash_return
  )[0];
  const grossUpRule = useSelector(
    (state) => state.auth.user.color_data_gross_yield
  )[0];
  const colorDataRules = useSelector(
    (state) => state.auth.user.color_data_rules
  )[0];
  const dashboard = useSelector((state) => state.dashboard);
  useEffect(() => {
    dispatch(getDashboardData());
  }, []);
  const [noiSortBy, setNoiSortBy] = useState({});
  const [cocSortBy, setCocSortBy] = useState({});
  const [recentSortBy, setRecentSortBy] = useState({});
  const navigate = useNavigate();
  const [noiFilters, setNoiFilters] = useState({});
  const [cashFilters, setCashFilters] = useState({});

  useEffect(() => {
    const filtersData = {};
    for (const [key, value] of Object.entries(noiFilters)) {
      if (Array.isArray(value) && value.length > 0) {
        filtersData[`filters[${key}]`] = value
          .map((item) => item.value)
          .join(',');
      } else if (value) {
        filtersData[`filters[${key}]`] = value;
      }
    }
    if (
      Object.keys(noiSortBy).length > 0 ||
      Object.keys(filtersData).length > 0
    ) {
      dispatch(getTopFiveNoi({ ...noiSortBy, ...filtersData }));
    }
  }, [noiSortBy, noiFilters]);

  useEffect(() => {
    const filtersData = {};
    for (const [key, value] of Object.entries(cashFilters)) {
      if (Array.isArray(value) && value.length > 0) {
        filtersData[`filters[${key}]`] = value
          .map((item) => item.value)
          .join(',');
      } else if (value) {
        filtersData[`filters[${key}]`] = value;
      }
    }
    if (
      Object.keys(cocSortBy).length > 0 ||
      Object.keys(cashFilters).length > 0
    ) {
      dispatch(getTopFiveCoc({ ...cocSortBy, ...filtersData }));
    }
  }, [cocSortBy, cashFilters]);

  useEffect(() => {
    if (Object.keys(recentSortBy).length > 0) {
      dispatch(getRecentProperties(recentSortBy));
    }
  }, [recentSortBy]);

  const handleRecentSort = (field) => {
    setRecentSortBy({
      ...recentSortBy,
      [`sort[${field}]`]:
        recentSortBy[`sort[${field}]`] === 'asc'
          ? 'desc'
          : recentSortBy[`sort[${field}]`] === 'desc'
          ? undefined
          : 'asc',
    });
  };

  const handleNoiSort = (field) => {
    setNoiSortBy({
      ...noiSortBy,
      [`sort[${field}]`]:
        noiSortBy[`sort[${field}]`] === 'asc'
          ? 'desc'
          : noiSortBy[`sort[${field}]`] === 'desc'
          ? undefined
          : 'asc',
    });
  };

  const handleCocSort = (field) => {
    setCocSortBy({
      ...cocSortBy,
      [`sort[${field}]`]:
        cocSortBy[`sort[${field}]`] === 'asc'
          ? 'desc'
          : cocSortBy[`sort[${field}]`] === 'desc'
          ? undefined
          : 'asc',
    });
  };

  const openNoiFilterDialog = () => {
    confirmAlert({
      overlayClassName: 'overlay',
      closeOnClickOutside: false,
      customUI: ({ onClose }) => (
        <FormDialog
          onClose={onClose}
          title="Set Filters for TOP 5 PROPERTIES - BY NOI"
          form={
            <NoiFilter
              initialValues={noiFilters}
              onSubmit={(values) => {
                setNoiFilters({ ...noiFilters, ...values });
                onClose();
              }}
            />
          }
        />
      ),
    });
  };

  const openCashFilterDialog = () => {
    confirmAlert({
      overlayClassName: 'overlay',
      closeOnClickOutside: false,
      customUI: ({ onClose }) => (
        <FormDialog
          onClose={onClose}
          title="Set Filters for TOP 5 PROPERTIES - BY CASH-ON-CASH RETURN"
          form={
            <CashOnCashFilter
              initialValues={cashFilters}
              onSubmit={(values) => {
                setCashFilters({ ...cashFilters, ...values });
                onClose();
              }}
            />
          }
        />
      ),
    });
  };

  return (
    <div>
      <div className={styles.header}>
        <h2 className="subtitle">
          {getSnippetBySlug(snippets, SNIPPETS.DASHBOARD_PAGE.TITLE)}
        </h2>
        <span className={styles.numberOfProperties}>
          {getSnippetBySlug(snippets, SNIPPETS.DASHBOARD_PAGE.TOTAL_NUMBER)}
          <span className={styles.count}>- {dashboard.count}</span>
        </span>
      </div>
      <div className={styles.block}>
        <Card
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.DASHBOARD_PAGE.CASH_ON_CASH_TABLE_TITLE
          )}
          actionButton={
            <>
              <button
                type="button"
                onClick={() => openCashFilterDialog()}
                className="button primary-button"
              >
                Filter
              </button>
              <button
                type="button"
                onClick={() => navigate(ROUTES.USER.PROPERTIES)}
                className="button primary-button"
              >
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.DASHBOARD_PAGE.ALL_PROPERTIES_BUTTON
                )}
              </button>
            </>
          }
        >
          {dashboard.topByCoc ? (
            dashboard.topByCoc.length === 0 ? (
              'No properties yet'
            ) : (
              <div className="table-wrapper">
                <table className="table">
                  <thead>
                    <tr>
                      <th />
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE
                                .CASH_ON_CASH_TABLE_CITY_COL
                            )}
                          </span>
                          <div onClick={() => handleCocSort('city.name')}>
                            {(cocSortBy['sort[city.name]'] === 'asc' ||
                              !cocSortBy['sort[city.name]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(cocSortBy['sort[city.name]'] === 'desc' ||
                              !cocSortBy['sort[city.name]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE
                                .CASH_ON_CASH_TABLE_ADDRESS_COL
                            )}
                          </span>
                          <div onClick={() => handleCocSort('address')}>
                            {(cocSortBy['sort[address]'] === 'asc' ||
                              !cocSortBy['sort[address]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(cocSortBy['sort[address]'] === 'desc' ||
                              !cocSortBy['sort[address]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE
                                .CASH_ON_CASH_TABLE_COC_RETURN
                            )}
                          </span>
                          <div
                            onClick={() => handleCocSort('cash_on_cash_return')}
                          >
                            {(cocSortBy['sort[cash_on_cash_return]'] ===
                              'asc' ||
                              !cocSortBy['sort[cash_on_cash_return]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(cocSortBy['sort[cash_on_cash_return]'] ===
                              'desc' ||
                              !cocSortBy['sort[cash_on_cash_return]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboard.topByCoc.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.photos ? (
                            <img src={item.photos.thumb} alt="Property photo" />
                          ) : (
                            <img
                              className={styles.imagePlaceholder}
                              src={placeholderImage}
                              alt="Image placeholder"
                            />
                          )}
                        </td>
                        <td>{item.city.name}</td>
                        <td className="nowrap">
                          <a
                            className="link"
                            href={`${ROUTES.USER.PROPERTIES}/${item.id}`}
                          >
                            {item.address}
                          </a>
                        </td>
                        <td>{formatPercentValues(item.cash_on_cash_return)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          ) : null}
        </Card>
        <Card
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.DASHBOARD_PAGE.NOI_TABLE_TITLE
          )}
          actionButton={
            <>
              <button
                type="button"
                onClick={() => openNoiFilterDialog()}
                className="button primary-button"
              >
                Filter
              </button>
              <button
                type="button"
                onClick={() => navigate(ROUTES.USER.PROPERTIES)}
                className="button primary-button"
              >
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.DASHBOARD_PAGE.ALL_PROPERTIES_BUTTON
                )}
              </button>
            </>
          }
        >
          {dashboard.topByNoi ? (
            dashboard.topByNoi.length === 0 ? (
              'No properties yet'
            ) : (
              <div className="table-wrapper">
                <table className="table">
                  <thead>
                    <tr>
                      <th />
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE.NOI_TABLE_CITY_COL
                            )}
                          </span>
                          <div onClick={() => handleNoiSort('city.name')}>
                            {(noiSortBy['sort[city.name]'] === 'asc' ||
                              !noiSortBy['sort[city.name]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(noiSortBy['sort[city.name]'] === 'desc' ||
                              !noiSortBy['sort[city.name]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE.NOI_TABLE_ADDRESS_COL
                            )}
                          </span>
                          <div onClick={() => handleNoiSort('address')}>
                            {(noiSortBy['sort[address]'] === 'asc' ||
                              !noiSortBy['sort[address]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(noiSortBy['sort[address]'] === 'desc' ||
                              !noiSortBy['sort[address]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE.NOI_TABLE_CASH_FLOW_COL
                            )}
                          </span>
                          <div
                            onClick={() =>
                              handleNoiSort('net_operating_income')
                            }
                          >
                            {(noiSortBy['sort[net_operating_income]'] ===
                              'asc' ||
                              !noiSortBy['sort[net_operating_income]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(noiSortBy['sort[net_operating_income]'] ===
                              'desc' ||
                              !noiSortBy['sort[net_operating_income]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboard.topByNoi.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.photos ? (
                            <img src={item.photos.thumb} alt="Property photo" />
                          ) : (
                            <img
                              className={styles.imagePlaceholder}
                              src={placeholderImage}
                              alt="Image placeholder"
                            />
                          )}
                        </td>
                        <td>{item.city.name}</td>
                        <td className="nowrap">
                          <a
                            className="link"
                            href={`${ROUTES.USER.PROPERTIES}/${item.id}`}
                          >
                            {item.address}
                          </a>
                        </td>
                        <td>{formatDollarValues(item.net_operating_income)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          ) : null}
        </Card>
      </div>
      <Card
        title={getSnippetBySlug(
          snippets,
          SNIPPETS.DASHBOARD_PAGE.RECENTLY_TABLE_TITLE
        )}
        fullWidth
        actionButton={
          <button
            type="button"
            onClick={() => navigate(ROUTES.USER.PROPERTIES)}
            className="button primary-button"
          >
            {getSnippetBySlug(
              snippets,
              SNIPPETS.DASHBOARD_PAGE.ALL_PROPERTIES_BUTTON
            )}
          </button>
        }
      >
        {dashboard.recentProperties ? (
          dashboard.recentProperties.length === 0 ? (
            'No properties yet'
          ) : (
            <>
              <div className="table-wrapper">
                <table className="table">
                  <thead>
                    <tr>
                      <th />
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE
                                .RECENTLY_TABLE_MARKET_NAME_COL
                            )}
                          </span>
                          <div onClick={() => handleRecentSort('market.name')}>
                            {(recentSortBy['sort[market.name]'] === 'asc' ||
                              !recentSortBy['sort[market.name]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(recentSortBy['sort[market.name]'] === 'desc' ||
                              !recentSortBy['sort[market.name]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE.RECENTLY_TABLE_ADDRESS_COL
                            )}
                          </span>
                          <div onClick={() => handleRecentSort('address')}>
                            {(recentSortBy['sort[address]'] === 'asc' ||
                              !recentSortBy['sort[address]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(recentSortBy['sort[address]'] === 'desc' ||
                              !recentSortBy['sort[address]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE
                                .RECENTLY_TABLE_PROJECTED_RENT_COL
                            )}
                          </span>
                          <div
                            onClick={() => handleRecentSort('projected_rent')}
                          >
                            {(recentSortBy['sort[projected_rent]'] === 'asc' ||
                              !recentSortBy['sort[projected_rent]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(recentSortBy['sort[projected_rent]'] === 'desc' ||
                              !recentSortBy['sort[projected_rent]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE
                                .RECENTLY_TABLE_INVESTMENT_TOTAL_COL
                            )}
                          </span>
                          <div
                            onClick={() => handleRecentSort('investment_total')}
                          >
                            {(recentSortBy['sort[investment_total]'] ===
                              'asc' ||
                              !recentSortBy['sort[investment_total]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(recentSortBy['sort[investment_total]'] ===
                              'desc' ||
                              !recentSortBy['sort[investment_total]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE
                                .RECENTLY_TABLE_CASH_FLOW_COL
                            )}
                          </span>
                          <div
                            onClick={() =>
                              handleRecentSort('net_operating_income')
                            }
                          >
                            {(recentSortBy['sort[net_operating_income]'] ===
                              'asc' ||
                              !recentSortBy['sort[net_operating_income]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(recentSortBy['sort[net_operating_income]'] ===
                              'desc' ||
                              !recentSortBy['sort[net_operating_income]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE.RECENTLY_TABLE_CAP_RATE
                            )}
                          </span>
                          <div onClick={() => handleRecentSort('cap_rate')}>
                            {(recentSortBy['sort[cap_rate]'] === 'asc' ||
                              !recentSortBy['sort[cap_rate]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(recentSortBy['sort[cap_rate]'] === 'desc' ||
                              !recentSortBy['sort[cap_rate]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE.RECENTLY_TABLE_GROSS
                            )}
                          </span>
                          <div onClick={() => handleRecentSort('gross_yield')}>
                            {(recentSortBy['sort[gross_yield]'] === 'asc' ||
                              !recentSortBy['sort[gross_yield]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(recentSortBy['sort[gross_yield]'] === 'desc' ||
                              !recentSortBy['sort[gross_yield]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="sort">
                          <span>
                            {getSnippetBySlug(
                              snippets,
                              SNIPPETS.DASHBOARD_PAGE
                                .RECENTLY_TABLE_CASH_ON_CASH_COL
                            )}
                          </span>
                          <div
                            onClick={() =>
                              handleRecentSort('cash_on_cash_return')
                            }
                          >
                            {(recentSortBy['sort[cash_on_cash_return]'] ===
                              'asc' ||
                              !recentSortBy['sort[cash_on_cash_return]']) && (
                              <span className="material-icons small">
                                keyboard_arrow_up
                              </span>
                            )}
                            {(recentSortBy['sort[cash_on_cash_return]'] ===
                              'desc' ||
                              !recentSortBy['sort[cash_on_cash_return]']) && (
                              <span className="material-icons">
                                keyboard_arrow_down
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboard.recentProperties.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.photos && item.photos.length > 0 ? (
                            <img
                              src={item.photos[0].thumb}
                              alt="Property photo"
                            />
                          ) : (
                            <img
                              className={styles.imagePlaceholder}
                              src={placeholderImage}
                              alt="Image placeholder"
                            />
                          )}
                        </td>
                        <td>{item.market_name}</td>
                        <td className="nowrap">
                          <a
                            className="link"
                            href={`${ROUTES.USER.PROPERTIES}/${item.id}`}
                          >
                            {item.address}
                          </a>
                        </td>
                        <td>{formatDollarValues(item.projected_rent)}</td>
                        <td>{formatDollarValues(item.investment_total)}</td>
                        <td>{formatDollarValues(item.cash_flow)}</td>
                        <td>
                          <span
                            className={`${
                              item.cap_rate < +colorDataRules.red
                                ? 'filled-error'
                                : item.cap_rate < +colorDataRules.yellow
                                ? 'filled-warning'
                                : 'filled-success'
                            } breadcrumb`}
                          >
                            {formatPercentValues(item.cap_rate)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              item.gross_yield < +grossUpRule.red
                                ? 'filled-error'
                                : item.gross_yield < +grossUpRule.yellow
                                ? 'filled-warning'
                                : 'filled-success'
                            } breadcrumb`}
                          >
                            {formatPercentValues(item.gross_yield)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              item.cash_on_cash_return < +cashRule.red
                                ? 'filled-error'
                                : item.cash_on_cash_return < +cashRule.yellow
                                ? 'filled-warning'
                                : 'filled-success'
                            } breadcrumb`}
                          >
                            {formatPercentValues(item.cash_on_cash_return)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <br />
              <button
                type="button"
                onClick={() => navigate(ROUTES.USER.PROPERTIES)}
                className="button center primary-button"
              >
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.DASHBOARD_PAGE.VIEW_MORE_BUTTON
                )}
              </button>
            </>
          )
        ) : null}
      </Card>
    </div>
  );
}

export default HomePage;

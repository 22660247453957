import ENDPOINTS from '../../constants/endpoints';
import {
  SET_SNIPPET_GROUPS,
  SET_VARIABLE_EXPENSES,
} from '../action-types/data';
import { apiAction } from './api';
import { setGeneralSuccessMessage } from './form';

export const getVariableExpenses = (parameters) => {
  return apiAction({
    url: ENDPOINTS.DATA.VARIABLE_EXPENSES,
    onSuccess: (dispatch, _, data) => dispatch(setVariableExpenses(data)),
    method: 'GET',
    data: parameters,
  });
};

export const createVariableExpense = (data, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.VARIABLE_EXPENSES,
    onSuccess: (dispatch, _, data) => {
      onSuccess && onSuccess(data);
      dispatch(
        setGeneralSuccessMessage(
          'Variable expense has been created successfully'
        )
      );
    },
    method: 'POST',
    data,
  });
};

export const updateVariableExpense = (data, onSuccess) => {
  const { id, ...parameters } = data;
  return apiAction({
    url: ENDPOINTS.DATA.SINGLE_VARIABLE_EXPENSE(id),
    method: 'PUT',
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(
        setGeneralSuccessMessage(
          'Variable expense has been updated successfully'
        )
      );
    },
    data: parameters,
  });
};

export const updateFluidValues = (data) => {
  const { id, ...parameters } = data;
  return apiAction({
    url: ENDPOINTS.DATA.FLUID_VALUES(id),
    method: 'PUT',
    onSuccess: (dispatch) => {
      dispatch(
        setGeneralSuccessMessage(
          'Fluid default values has been updated successfully'
        )
      );
    },
    data: parameters,
  });
};

export const deleteVariableExpense = (id, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.SINGLE_VARIABLE_EXPENSE(id),
    method: 'DELETE',
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(
        setGeneralSuccessMessage(
          'Variable expense has been deleted successfully'
        )
      );
    },
  });
};

export const getVariableExpense = (id, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.DATA.SINGLE_VARIABLE_EXPENSE(id),
    onSuccess: (dispatch, _, data) => {
      onSuccess && onSuccess(data.data);
    },
    method: 'GET',
  });
};

export const setVariableExpenses = (data) => {
  return {
    type: SET_VARIABLE_EXPENSES,
    variableExpenses: data.data,
    count: data.meta.total,
  };
};

import ENDPOINTS from '../../constants/endpoints';
import { SET_PROPERTIES } from '../action-types/properties';
import { apiAction } from './api';
import { setGeneralErrorMessage, setGeneralSuccessMessage } from './form';

export const getProperties = (data) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.GET_PROPERTIES,
    method: 'GET',
    onSuccess: (dispatch, _, data) => {
      dispatch(setProperties(data));
    },
    data,
  });
};

export const getClientProperties = (id, data) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.GET_PROPERTIES_FOR_CLIENT(id),
    method: 'GET',
    onSuccess: (dispatch, _, data) => {
      dispatch(setProperties(data));
    },
    data,
  });
};

export const getAllProperties = (data) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.GET_ALL_PROPERTIES,
    method: 'GET',
    onSuccess: (dispatch, _, data) => {
      dispatch(setProperties(data));
    },
    data,
  });
};

export const removeExpenses = (id, onSuccess) => {
  if (!id) {
    onSuccess();
    return;
  }
  return apiAction({
    url: ENDPOINTS.PROPERTIES.REMOVE_EXPENSES(id),
    method: 'DELETE',
    onSuccess: (dispatch, _, response) => {
      onSuccess && onSuccess(response.data);
    },
  });
};

export const changeStatus = (id, data) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.CHANGE_STATUS(id),
    method: 'PUT',
    onSuccess: (dispatch) => {
      dispatch(
        setGeneralSuccessMessage('Status has been changed successfully')
      );
    },
    data,
  });
};

export const getSum = (data) => {
  const { onSuccess, ...parameters } = data;
  return apiAction({
    url: ENDPOINTS.PROPERTIES.GET_SUM,
    method: 'POST',
    onSuccess: (dispatch, _, response) => {
      data.onSuccess && data.onSuccess(response.data);
    },
    data: parameters,
  });
};

export const getPropertyDetails = (data) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.PROPERTY_DETAILS(data.id),
    method: 'GET',
    onSuccess: (dispatch, _, response) => {
      data.onSuccess && data.onSuccess(response.data);
    },
  });
};

export const getProperty = (data) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.PROPERTY(data.id),
    method: 'GET',
    onSuccess: (dispatch, _, response) => {
      data.onSuccess && data.onSuccess(response.data);
    },
  });
};

export const getRentalizer = (data, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.RENTALIZER(),
    method: 'POST',
    onSuccess: (dispatch, _, response) => {
      if (response && Object.keys(response).length > 0) {
        onSuccess && onSuccess(response);
      } else {
        dispatch(setGeneralErrorMessage('No data from AirDNA found'));
      }
    },
    onFailure: (dispatch) => {
      dispatch(setGeneralErrorMessage('No data from AirDNA found'));
    },
    data,
  });
};

export const getOverview = (data, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.GET_OVERVIEW(),
    method: 'POST',
    data,
    onSuccess: (dispatch, _, response) => {
      onSuccess && onSuccess(response);
    },
  });
};

export const getPublicLink = (id, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.SHARE_PUBLIC_LINK(id),
    method: 'GET',
    onSuccess: (dispatch, _, response) => {
      onSuccess(response.data);
    },
  });
};

export const getPropertyBySlug = ({ slug, onSuccess }) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.PROPERTY_BY_SLUG(slug),
    method: 'GET',
    onSuccess: (dispatch, _, response) => {
      onSuccess(response.data);
    },
  });
};

export const getCosts = (data) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.COSTS(data.id),
    method: 'GET',
    onSuccess: (dispatch, _, response) => {
      data.onSuccess && data.onSuccess(response.data);
    },
  });
};

export const createNewProperty = (data) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.GET_PROPERTIES,
    method: 'POST',
    onSuccess: (dispatch) => {
      data.onSuccess && data.onSuccess();
      onCreatePropertySuccess(dispatch);
    },
    data,
  });
};

export const parseData = (data) => {
  const { onSuccess, ...parameters } = data;
  return apiAction({
    url: [ENDPOINTS.PROPERTIES.PARSE_PHOTO, ENDPOINTS.PROPERTIES.PARSE_DATA],
    method: ['GET', 'GET'],
    onSuccess:
      onSuccess &&
      onSuccess.map((f) => (dispatch, _, response) => f(response.data)),
    onFailure: (dispatch) =>
      dispatch(setGeneralErrorMessage('Invalid format of url')),
    data: [parameters, parameters],
  });
};

export const updateProperty = (data) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.PROPERTY(data.id),
    method: 'PUT',
    onSuccess: (dispatch) => {
      data.onSuccess && data.onSuccess();
      onUpdatePropertySuccess(dispatch);
    },
    data,
  });
};

export const deleteProperty = (data) => {
  return apiAction({
    url: ENDPOINTS.PROPERTIES.PROPERTY(data.id),
    method: 'DELETE',
    onSuccess: (dispatch) => {
      data.onSuccess && data.onSuccess();
      dispatch(
        setGeneralSuccessMessage('Property has been deleted successfully')
      );
    },
  });
};

export const setProperties = (data) => {
  return {
    type: SET_PROPERTIES,
    properties: data.data,
    count: data.meta.total,
  };
};

export const onCreatePropertySuccess = (dispatch) => {
  dispatch(setGeneralSuccessMessage('Property has been created successfully'));
};

export const onUpdatePropertySuccess = (dispatch) => {
  dispatch(setGeneralSuccessMessage('Property has been updated successfully'));
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Field, formValues, reduxForm } from 'redux-form';
import SNIPPETS from '../../../constants/snippets';
import { getSnippetBySlug } from '../../../helpers/snippets';
import { getCities, getStates } from '../../../redux/actions/data';
import { notEmpty, required } from '../../../utils/validators';
import Input from '../../components/Input/Input';
import Multiselect from '../../components/MultiSelect/MultiSelect';

function AddMarketForm({
  handleSubmit,
  onSubmit,
  states: selectedStates,
  cities: selectedCities,
  change,
  editMode,
}) {
  const dispatch = useDispatch();
  const states = useSelector((state) =>
    state.data.states?.map((state) => ({
      label: state.city_name,
      value: state.state_id,
    }))
  );

  const cities = useSelector((state) =>
    state.data.cities?.map((city) => ({
      label: city.name,
      value: city.city_id,
      state: city.state_id,
    }))
  );

  const snippets = useSelector((state) => state.snippets.snippets);

  useEffect(() => {
    dispatch(getStates());
  }, []);

  useEffect(() => {
    if (editMode && selectedStates && !cities) {
      loadCities(selectedStates.map((state) => state.value));
    }
  }, [selectedStates]);

  const loadCities = (stateIds) => {
    dispatch(getCities({ state_ids: stateIds }));
  };

  const onStateChanged = (value) => {
    const stateIds = new Set(value.map((state) => state.value));
    change(
      'cities',
      selectedCities.filter((selected) => stateIds.has(selected.state))
    );
    if (value && value.length > 0) {
      loadCities([...stateIds]);
    }
  };

  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values))}>
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.CREATE_MARKET_FORM.MARKET_NAME_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="text"
        name="name"
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.CREATE_MARKET_FORM.MARKET_NAME_LABEL
        )}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.CREATE_MARKET_FORM.STATES_PLACEHOLDER,
          true
        )}
        validate={[notEmpty]}
        name="states"
        onChange={onStateChanged}
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.CREATE_MARKET_FORM.STATES_LABEL
        )}
        options={states || []}
        component={Multiselect}
      />
      {/* <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.CREATE_MARKET_FORM.CITIES_PLACEHOLDER,
          true
        )}
        validate={[notEmpty]}
        name="cities"
        disabled={!selectedStates || selectedStates.length === 0}
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.CREATE_MARKET_FORM.CITIES_LABEL
        )}
        options={cities || []}
        component={Multiselect}
      /> */}
      <button
        type="submit"
        data-cy="save-market-button"
        className="button primary-button center"
      >
        {editMode
          ? getSnippetBySlug(
              snippets,
              SNIPPETS.CREATE_MARKET_FORM.SAVE_CHANGES_BUTTON
            )
          : getSnippetBySlug(
              snippets,
              SNIPPETS.CREATE_MARKET_FORM.CREATE_MARKET_BUTTON
            )}
      </button>
    </form>
  );
}

export default reduxForm({
  form: 'add-market-form',
})(formValues('states', 'cities')(AddMarketForm));

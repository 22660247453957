import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../../../constants/routes';
import styles from './HomePage.module.css';

function HomePage() {
  return (
    <div>
      <ul className={styles.menu}>
        <li>
          <Link to={ROUTES.ADMIN.USERS}>Users</Link>
        </li>
        <li>
          <Link to={ROUTES.ADMIN.SNIPPETS}>Snippets</Link>
        </li>
        <li>
          <Link to={ROUTES.ADMIN.GROUPS}>Snippet Groups</Link>
        </li>
        <li>
          <Link to={ROUTES.ADMIN.VARIABLE_EXPENSES}>Variable Expenses</Link>
        </li>
      </ul>
    </div>
  );
}

export default HomePage;

import PropTypes from 'prop-types';
import React from 'react';
import styles from './Checkbox.module.css';

function Checkbox({ label, input }) {
  return (
    <div className={styles.fieldContainer}>
      <label htmlFor={input.name}>
        <input
          {...input}
          id={input.name}
          checked={input.value}
          type="checkbox"
        />
        <div>{label()}</div>
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.func.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default Checkbox;

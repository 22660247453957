import {
  CLEAR_MESSAGE,
  SET_GENERAL_ERROR_MESSAGE,
  SET_GENERAL_SUCCESS_MESSAGE,
  SET_SUCCESS_SUBMIT_MESSAGE,
} from '../action-types/form';

export const setSuccessSubmitMessage = (form, message) => ({
  type: SET_SUCCESS_SUBMIT_MESSAGE,
  form,
  message,
});

export const setGeneralErrorMessage = (error) => ({
  type: SET_GENERAL_ERROR_MESSAGE,
  error,
});

export const setGeneralSuccessMessage = (message) => ({
  type: SET_GENERAL_SUCCESS_MESSAGE,
  message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});

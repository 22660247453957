import React from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';

const RangeComponent = createSliderWithTooltip(Slider.Range);

function Range({
  min,
  max,
  formatter,
  count,
  marks,
  color,
  dotsColor,
  step,
  ...properties
}) {
  return count ? (
    <RangeComponent
      count={count}
      range
      step={step}
      pushable={step}
      marks={marks}
      value={properties.input.value}
      trackStyle={color.map((index) => ({ backgroundColor: index }))}
      handleStyle={{ backgroundColor: dotsColor }}
      onChange={properties.input.onChange}
      min={min}
      max={max}
      defaultValue={[min, max]}
      allowCross={false}
      tipFormatter={formatter}
    />
  ) : (
    <RangeComponent
      trackStyle={[{ backgroundColor: color }]}
      handleStyle={[{ backgroundColor: color }, { backgroundColor: color }]}
      railStyle={{ backgroundColor: 'var(--color-gray)' }}
      value={properties.input.value}
      onChange={properties.input.onChange}
      min={min}
      max={max}
      defaultValue={[min, max]}
      allowCross={false}
      tipFormatter={formatter}
    />
  );
}

export default Range;

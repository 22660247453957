import {
  CLEAR_MESSAGE,
  SET_GENERAL_ERROR_MESSAGE,
  SET_GENERAL_SUCCESS_MESSAGE,
  SET_SUCCESS_SUBMIT_MESSAGE,
} from '../action-types/form';

const initialState = {
  form: null,
  successMessage: null,
  generalMessage: null,
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUCCESS_SUBMIT_MESSAGE:
      return {
        ...state,
        form: action.form,
        successMessage: action.message,
      };
    case SET_GENERAL_ERROR_MESSAGE:
      return {
        ...state,
        generalMessage: {
          type: 'error',
          message: action.error,
        },
      };
    case SET_GENERAL_SUCCESS_MESSAGE:
      return {
        ...state,
        generalMessage: {
          type: 'success',
          message: action.message,
        },
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        form: null,
        successMessage: null,
      };
    default:
      return state;
  }
};

export default formReducer;

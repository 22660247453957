import ENDPOINTS from '../../constants/endpoints';
import { UPDATE_AVATAR } from '../action-types/profile';
import { apiAction } from './api';
import { setUser } from './auth';
import { setGeneralErrorMessage, setGeneralSuccessMessage } from './form';
import { onFailure } from './general';

export const updateAvatarById = (id, data, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.USER.AVATAR_BY_ID(id),
    method: 'POST',
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(
        setGeneralSuccessMessage('Avatar has been updated successfully')
      );
    },
    onFailure: (dispatch) =>
      dispatch(
        setGeneralErrorMessage(
          'Uploading avatar failed. Please check format of the file and try again. Allowed formats: png, jpeg, jpg, gif'
        )
      ),
    isMultipart: true,
    data,
  });
};

export const updateAvatar = (data) => {
  return apiAction({
    url: ENDPOINTS.USER.AVATAR,
    onSuccess: onUpdateAvatarSuccess,
    onFailure: (dispatch) =>
      dispatch(
        setGeneralErrorMessage(
          'Uploading avatar failed. Please check format of the file and try again. Allowed formats: png, jpeg, jpg, gif'
        )
      ),
    method: 'POST',
    data,
    isMultipart: true,
  });
};

export const deleteAvatarById = (id, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.USER.AVATAR_BY_ID(id),
    onSuccess: (dispatch, _) => {
      onSuccess && onSuccess();
      dispatch(
        setGeneralSuccessMessage('Avatar has been deleted successfully')
      );
    },
    method: 'DELETE',
  });
};

export const deleteAvatar = () => {
  return apiAction({
    url: ENDPOINTS.USER.AVATAR,
    onSuccess: onDeleteAvatarSuccess,
    method: 'DELETE',
  });
};

export const updateProfile = (data, form) => {
  return apiAction({
    url: ENDPOINTS.USER.UPDATE_PROFILE,
    method: 'PUT',
    onSuccess: onUpdateProfileSuccess,
    onFailure,
    data,
    form,
  });
};

export const updateProfileById = (id, data) => {
  return apiAction({
    url: ENDPOINTS.USER.UPDATE_PROFILE_BY_ID(id),
    method: 'PUT',
    onSuccess: (dispatch, _, data) => {
      dispatch(
        setGeneralSuccessMessage('Profile information updated successfully')
      );
    },
    data,
  });
};

export const setAvatar = (source) => {
  return {
    type: UPDATE_AVATAR,
    src: source,
  };
};

// handlers

const onUpdateAvatarSuccess = (dispatch, _, data) => {
  dispatch(setAvatar(data.data.src));
  dispatch(setGeneralSuccessMessage('Avatar updated successfully'));
};

const onDeleteAvatarSuccess = (dispatch) => {
  dispatch(setAvatar(null));
  dispatch(setGeneralSuccessMessage('Avatar deleted successfully'));
};

export const onUpdateProfileSuccess = (dispatch, _, data) => {
  dispatch(setUser({ user: data.data }));
  dispatch(
    setGeneralSuccessMessage('Profile information updated successfully')
  );
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SNIPPETS from '../../../constants/snippets';
import { getSnippetBySlug } from '../../../helpers/snippets';
import { updateProfile } from '../../../redux/actions/profile';
import Card from '../../components/Card/Card';
import SetColorsForm from '../../forms/SetColorsForm/SetColorsForm';
import styles from './DesiredReturnMetricsPage.module.css';
import PreferencesForm from '../../forms/PreferencesForm/PreferencesForm';

function DesiredReturnMetricsPage() {
  const user = useSelector((store) => store.auth.user);
  const dispatch = useDispatch();
  const snippets = useSelector((state) => state.snippets.snippets);

  const saveProfileInfo = (values, form) => {
    if (values[form] && values[form].colors) {
      const red = String(values[form].colors[1]);
      const yellow = String(values[form].colors[2]);
      const green = String(values[form].colors[2]);
      values[form] = [{ red, yellow, green }];
    }
    values.default_capital_expenditures = Number(
      values.default_capital_expenditures
    );
    values.default_down_payment = Number(values.default_down_payment);
    values.default_property_management_fee = Number(
      values.default_property_management_fee
    );
    dispatch(updateProfile(values, form));
  };

  return (
    <div className={styles.profileInfo}>
      <Card
        title={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.ONE_RULE_SUBTITLE
        )}
      >
        <SetColorsForm
          form="color_data_rules"
          onSubmit={saveProfileInfo}
          marks={{ 0: '0%', 50: '50%+' }}
          max={50}
          initialValues={{
            color_data_rules: {
              colors: user.color_data_rules
                ? [
                    0,
                    user.color_data_rules[0].red,
                    user.color_data_rules[0].yellow,
                    50,
                  ]
                : [0, 20, 40, 50],
            },
          }}
        />
      </Card>
      <Card
        title={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.CASH_ON_CASH_SUBTITLE
        )}
      >
        <SetColorsForm
          form="color_data_cash_return"
          onSubmit={saveProfileInfo}
          max={100}
          marks={{ 0: '0%', 100: '100%+' }}
          initialValues={{
            color_data_cash_return: {
              colors: user.color_data_cash_return
                ? [
                    0,
                    user.color_data_cash_return[0].red,
                    user.color_data_cash_return[0].yellow,
                    100,
                  ]
                : [0, 25, 75, 100],
            },
          }}
        />
      </Card>
      <Card
        title={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.GROSS_YIELD_SUBTITLE
        )}
      >
        <SetColorsForm
          form="color_data_gross_yield"
          onSubmit={saveProfileInfo}
          max={100}
          marks={{ 0: '0%', 100: '100%+' }}
          initialValues={{
            color_data_gross_yield: {
              colors: user.color_data_gross_yield
                ? [
                    0,
                    user.color_data_gross_yield[0].red,
                    user.color_data_gross_yield[0].yellow,
                    100,
                  ]
                : [0, 10, 15, 100],
            },
          }}
        />
      </Card>
      <Card>
        <PreferencesForm
          onSubmit={saveProfileInfo}
          initialValues={{
            default_down_payment: (user.default_down_payment || 0).toFixed(2),
            default_property_management_fee: (
              user.default_property_management_fee || 0
            ).toFixed(2),
            default_capital_expenditures: (
              user.default_capital_expenditures || 0
            ).toFixed(2),
          }}
        />
      </Card>
    </div>
  );
}

export default DesiredReturnMetricsPage;

import React, { useEffect, useState, createRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import CURRENCY_TYPES from '../../../constants/currency';
import RENTAL_TYPES from '../../../constants/rental-type';
import ROUTES from '../../../constants/routes';
import SNIPPETS from '../../../constants/snippets';
import { getSnippetBySlug } from '../../../helpers/snippets';
import { getCities } from '../../../redux/actions/data';
import {
  createNewProperty,
  getProperty,
  updateProperty,
} from '../../../redux/actions/properties';
import { getMonthsBetweenTwoDates } from '../../../utils/date';
import {
  formatNumberWithComma,
  removeCommasFromNumber,
} from '../../../utils/formatters';
import CreatePropertyForm from '../../forms/CreatePropertyForm/CreatePropertyForm';
import styles from './CreatePropertyPage.module.css';

const steps = [
  'Insert link from zillow',
  'Tag a market',
  'Refine property details',
  'Refine loan details',
  'Determine revenue projections',
  'Fixed expenses',
  'Add variable expenses',
];

function Stepper({ steps, activeStep, onClickStep, stepStatuses, stepRefs }) {
  return (
    <div className={styles.stepper}>
      <ul className={styles.steps}>
        {steps.map((step, index) => (
          <li
            className={`${styles.step} ${
              index === activeStep
                ? styles.active
                : stepStatuses[index] === 'uncompleted'
                ? styles.disabled
                : stepStatuses[index] === 'completed'
                ? styles.completed
                : ''
            }`}
          >
            <div
              className={styles.number}
              onClick={
                stepStatuses[index] !== 'uncompleted' &&
                (() => {
                  onClickStep(index);
                  stepRefs[index].current.scrollIntoView({
                    behavior: 'smooth',
                  });
                })
              }
            >
              <span>{index + 1}</span>
            </div>
            {index === activeStep && (
              <div className={styles.description}>
                <p className={styles.stepNumber}>
                  {index === steps.length - 1
                    ? 'Final step'
                    : `Step ${index + 1}`}
                </p>
                <p className={styles.stepName}>{step}</p>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

function CreatePropertyPage() {
  const [step, setStep] = useState(0);
  const [property, setProperty] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const parameters = useParams();
  const snippets = useSelector((state) => state.snippets.snippets);
  const user = useSelector((state) => state.auth.user);
  const [stepStatuses, setStepStatuses] = useState(
    ['completed'].concat(
      Array.from({ length: 6 }).fill(
        parameters.propertyId ? 'completed' : 'uncompleted'
      )
    )
  );
  const stepReferences = Array.from({ length: 7 }).map((element) =>
    createRef(element)
  );

  useEffect(() => {
    if (parameters.propertyId) {
      dispatch(
        getProperty({
          id: parameters.propertyId,
          onSuccess: (data) => {
            const { analyze, ...property } = data;
            setProperty({
              ...property,
              list_price: property.list_price.toFixed(2),
              purchase_price: property.purchase_price.toFixed(2),
              closing_costs_percent: property.closing_costs_percent.toFixed(2),
              closing_costs: property.closing_costs.toFixed(2),
              down_payment_percent: property.down_payment_percent.toFixed(2),
              interest_rate: property.interest_rate.toFixed(2),
              projected_rent: property.projected_rent.toFixed(2),
              average_daily_rate: property.average_daily_rate.toFixed(2),
              projected_occupancy: property.projected_occupancy.toFixed(2),
              property_taxes: property.property_taxes.toFixed(2),
              rehub_budget: property.rehub_budget.toFixed(2),
              cleaning_fees_revenue: property.cleaning_fees_revenue.toFixed(2),
              furnishing_budget: property.furnishing_budget.toFixed(2),
              need_appreciation_assumption:
                property.need_appreciation_assumption &&
                property.need_appreciation_assumption.toFixed(2),
              variable_expenses: property.variable_expenses.toFixed(2),
            });
            dispatch(getCities({ state_ids: [property.state_id] }));
          },
        })
      );
    }
  }, []);

  const formatOverview = (data) => {
    const overview = [];
    if (!data || Object.keys(data).length === 0) {
      const startDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      );
      const endDate = new Date();
      const overviewDates = getMonthsBetweenTwoDates(startDate, endDate);
      for (const date of overviewDates.slice(0, -1)) {
        overview.push({
          ...date,
          projected_occupancy: 0,
          average_daily_rate: 0,
        });
      }
    } else {
      for (const [year, overviewYearData] of Object.entries(data)) {
        for (const data of overviewYearData) {
          overview.push({
            ...data,
            year,
            projected_occupancy: data.projected_occupancy.toFixed(2),
            average_daily_rate: data.average_daily_rate.toFixed(2),
          });
        }
      }
    }
    return overview;
  };

  const createProperty = (values) => {
    const overviewGroupByYear = values.overview?.reduce(
      (overviewData, overviewOfMonth) => {
        const { year, projected_occupancy, average_daily_rate, ...data } =
          overviewOfMonth;
        overviewData[year] = overviewData[year] ?? [];
        overviewData[year].push({
          ...data,
          projected_occupancy: Number(projected_occupancy),
          average_daily_rate: Number(average_daily_rate),
        });
        return overviewData;
      },
      {}
    );

    if (values.isSave) {
      const customExpenses = values.variableCosts.map((cost) => ({
        name: cost.label,
        value: removeCommasFromNumber(cost.month),
        measurements: cost.currency,
        key: cost.value,
        id: cost.id,
      }));

      const data = {
        ...values,
        market_id: values.market_id.value,
        city_id: values.city.value,
        state_id: values.state.value,
        rental_type: values.rental_type.value,
        photo: values.photos && values.photos.big_photo,
        custom_expenses: customExpenses,
        repairs_maintenance: values.repairs_maintenance.month,
        listing_site_fees: values.listing_site_fees.month,
        property_management_fee: values.property_management_fee.month,
        overview: overviewGroupByYear,
        monthly_projected_rent: undefined,
        city: undefined,
        state: undefined,
        photos: undefined,
        market_name: undefined,
        variableCosts: undefined,
        variable_expenses: undefined,
      };
      if (parameters.propertyId) {
        dispatch(
          updateProperty({
            id: property.id,
            ...data,
            onSuccess: () => {
              navigate(
                location.state && location.state.pathname
                  ? location.state.pathname
                  : ROUTES.USER.PROPERTIES,
                {
                  state: {
                    ...location.state,
                  },
                }
              );
            },
          })
        );
      } else {
        dispatch(
          createNewProperty({
            ...data,
            onSuccess: () => {
              navigate(
                location.state && location.state.pathname
                  ? location.state.pathname
                  : ROUTES.USER.PROPERTIES,
                {
                  state: {
                    ...location.state,
                  },
                }
              );
            },
          })
        );
      }
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <button
          type="button"
          className="button withImg outlined-secondary-button -thin"
          onClick={() =>
            navigate(
              location.state && location.state.pathname
                ? location.state.pathname
                : ROUTES.USER.PROPERTIES,
              {
                state: {
                  ...location.state,
                },
              }
            )
          }
        >
          <span className="material-icons">chevron_left</span>
          <span>Back</span>
        </button>
        <h2 className="subtitle">
          {parameters.propertyId
            ? getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.UPDATE_PROPERTY_TITLE
              )
            : getSnippetBySlug(snippets, SNIPPETS.CREATE_PROPERTY_PAGE.TITLE)}
        </h2>
      </div>
      <Stepper
        steps={steps}
        activeStep={step}
        onClickStep={setStep}
        stepStatuses={stepStatuses}
        stepRefs={stepReferences}
      />
      {(property || !parameters.propertyId) && (
        <CreatePropertyForm
          step={step}
          stepRefs={stepReferences}
          stepStatuses={stepStatuses}
          onStepSubmitted={(step, status) =>
            setStepStatuses(
              stepStatuses.map((item, index) =>
                index === step ? status : item
              )
            )
          }
          onStepClick={setStep}
          onSubmit={(values) => {
            createProperty(values);
          }}
          currentPropertyId={parameters.propertyId}
          editMode={!!parameters.propertyId}
          initialValues={
            property
              ? {
                  ...property,
                  overview: formatOverview(property.overview),
                  monthly_projected_rent: (
                    property.projected_rent / 12
                  ).toFixed(2),
                  variableCosts: property.custom_expenses.map((cost) => ({
                    label: cost.name,
                    id: cost.id,
                    value: cost.key,
                    currency: cost.measurements,
                    month: formatNumberWithComma(cost.value.toFixed(2)),
                    year:
                      cost.measurements === CURRENCY_TYPES.DOLLAR
                        ? formatNumberWithComma((cost.value * 12).toFixed(2))
                        : formatNumberWithComma(
                            (
                              (property.projected_rent * cost.value) /
                              100
                            ).toFixed(2)
                          ),
                  })),
                  listing_site_fees: {
                    month: formatNumberWithComma(
                      property.listing_site_fees.toFixed(2)
                    ),
                    year: formatNumberWithComma(
                      (
                        (property.listing_site_fees * property.projected_rent) /
                        12 /
                        100
                      ).toFixed(2)
                    ),
                  },
                  repairs_maintenance: {
                    month: formatNumberWithComma(
                      property.repairs_maintenance.toFixed(2)
                    ),
                    year: formatNumberWithComma(
                      (
                        (property.repairs_maintenance *
                          property.projected_rent) /
                        12 /
                        100
                      ).toFixed(2)
                    ),
                  },
                  property_management_fee: {
                    month: formatNumberWithComma(
                      property.property_management_fee.toFixed(2)
                    ),
                    year: formatNumberWithComma(
                      (
                        (property.property_management_fee *
                          property.projected_rent) /
                        12 /
                        100
                      ).toFixed(2)
                    ),
                  },
                  photos: {
                    small_photo:
                      property.photos && property.photos.length > 0
                        ? property.photos[0].src
                        : null,
                    big_photo:
                      property.photos && property.photos.length > 0
                        ? property.photos[0].src
                        : null,
                  },
                  rental_type: {
                    value: property.rental_type,
                    label: RENTAL_TYPES[property.rental_type],
                  },
                  market_id: {
                    value: property.market_id,
                    label: property.market_name,
                  },
                  city: {
                    value: property.city_id,
                    label: property.city,
                  },
                  state: {
                    value: property.state_id,
                    label: property.state,
                    shortName: property.state_short_name,
                  },
                }
              : {
                  bedrooms: 1,
                  bathrooms: 1,
                  loan_term: 30,
                  down_payment_percent: (
                    user.default_down_payment || 0
                  ).toFixed(2),
                  average_days_per_stay: 5,
                  need_appreciation_assumption: 4,
                  rental_type: {
                    label: 'Short Term Rental',
                    value: 'short_term_rental',
                  },
                  overview: formatOverview(null),
                }
          }
        />
      )}
    </div>
  );
}

export default CreatePropertyPage;

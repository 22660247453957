import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../../../constants/routes';
import { getFluidValues } from '../../../../redux/actions/data';
import {
  getVariableExpense,
  updateFluidValues,
} from '../../../../redux/actions/variableExpenses';
import { formatNumberWithComma } from '../../../../utils/formatters';
import EditFluidValuesForm from '../../../forms/admin/EditFluidValuesForm/EditFluidValuesForm';
import styles from './EditFluidValuesPage.module.css';

function EditFluidValuesPage() {
  const { variableExpenseId } = useParams();
  const [fluidValues, setFluidValues] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (values) => {
    const statesValue = values.states.map((state, index) => ({
      state,
      value: Number(values.values[index]),
    }));
    dispatch(
      updateFluidValues({
        id: variableExpenseId,
        states_value: statesValue,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getVariableExpense(variableExpenseId, (expense) => {
        dispatch(
          getFluidValues(variableExpenseId, (data) => {
            setFluidValues({
              states: data.map((item) => item.state),
              values: data.map((item) =>
                formatNumberWithComma(item.value.toFixed(2))
              ),
              measurements: expense.measurements,
            });
          })
        );
      })
    );
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <button
          type="button"
          className="button withImg outlined-secondary-button -thin"
          onClick={() => navigate(-1)}
        >
          <span className="material-icons">chevron_left</span>
          <span>Back</span>
        </button>
        <h2 className="subtitle">Edit Fluid Default Values</h2>
      </div>
      <EditFluidValuesForm initialValues={fluidValues} onSubmit={onSubmit} />
    </div>
  );
}

export default EditFluidValuesPage;

import React from 'react';
import CircleLoader from 'react-spinners/CircleLoader';

function Loader() {
  return (
    <div style={{ position: 'fixed', left: '50%', top: '50vh' }}>
      <CircleLoader />
    </div>
  );
}

export default Loader;

import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage/ForgotPasswordPage';
import LoginPage from '../../pages/LoginPage/LoginPage';
import PropertyDetailsPage from '../../pages/PropertyDetailsPage/PropertyDetailsPage';
import RegistrationPage from '../../pages/RegistrationPage/RegistrationPage';
import ResetPasswordPage from '../../pages/ResetPasswordPage/ResetPasswordPage';

function PublicPaths() {
  const location = useLocation();
  useEffect(() => {
    if (document.querySelector('#react-confirm-alert')) {
      document.querySelector('#react-confirm-alert').remove();
    }
  }, [location]);
  return (
    <Routes>
      <Route
        path={ROUTES.USER.PUBLIC_PROPERTY}
        element={<PropertyDetailsPage />}
      />
      <Route path={ROUTES.AUTH.REGISTER} element={<RegistrationPage />} />
      <Route path={ROUTES.AUTH.LOGIN} element={<LoginPage />} />
      <Route
        path={ROUTES.AUTH.FORGOT_PASSWORD}
        element={<ForgotPasswordPage />}
      />
      <Route
        path={ROUTES.AUTH.RESET_PASSWORD}
        element={<ResetPasswordPage />}
      />
      <Route path="*" element={<Navigate replace to={ROUTES.AUTH.LOGIN} />} />
    </Routes>
  );
}

export default PublicPaths;

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CURRENCY_TYPES, { CURRENCY_LABEL } from '../../../../constants/currency';
import {
  formatNumberWithComma,
  onBlurFormatter,
  onFocusFormatter,
  removeCommasFromNumber,
} from '../../../../utils/formatters';
import {
  nonNegativeNumber,
  number,
  percent,
  required,
} from '../../../../utils/validators';
import Input from '../../../components/Input/Input';

function EditFluidValuesForm({
  initialValues,
  onSubmit,
  handleSubmit,
  change,
}) {
  if (!initialValues) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr>
              {initialValues.states.map((state) => (
                <th key={state}>{state}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {initialValues.values.map((_, index) => (
                <td key={index}>
                  <Field
                    name={`values[${index}]`}
                    validate={
                      initialValues.measurements === CURRENCY_TYPES.DOLLAR
                        ? [required, number, nonNegativeNumber]
                        : [required, number, percent]
                    }
                    type="text"
                    placeholder="0.00"
                    prefix={
                      initialValues.measurements === CURRENCY_TYPES.DOLLAR
                        ? CURRENCY_LABEL.currency
                        : null
                    }
                    suffix={
                      initialValues.measurements === CURRENCY_TYPES.PERCENT
                        ? CURRENCY_LABEL.percent
                        : null
                    }
                    onFocusFormatter={(e) => onFocusFormatter(e, change)}
                    onBlurFormatter={(value) => onBlurFormatter(value, true)}
                    normalize={(value) => removeCommasFromNumber(value)}
                    format={(value) => {
                      if (!value) {
                        return '';
                      }
                      return formatNumberWithComma(
                        removeCommasFromNumber(value)
                      );
                    }}
                    component={Input}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <button className="button primary-button center">Save changes</button>
    </form>
  );
}

export default reduxForm({
  form: 'edit-fluid-values-form',
})(EditFluidValuesForm);

import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import ClientPropertiesPage from '../../pages/admin/ClientPropertiesPage/ClientPropertiesPage';
import CreateSnippetGroupPage from '../../pages/admin/CreateSnippetGroupPage/CreateSnippetGroupPage';
import CreateSnippetPage from '../../pages/admin/CreateSnippetPage/CreateSnippetPage';
import CreateVariableExpensesPage from '../../pages/admin/CreateVariableExpensesPage/CreateVariableExpensesPage';
import HomePage from '../../pages/admin/HomePage/HomePage';
import SnippetGroupsPage from '../../pages/admin/SnippetGroupsPage/SnippetGroupsPage';
import SnippetsPage from '../../pages/admin/SnippetsPage/SnippetsPage';
import UsersPage from '../../pages/admin/UsersPage/UsersPage';
import VariableExpensesPage from '../../pages/admin/VariableExpensesPage/VariableExpensesPage';
import UserProfilePage from '../../pages/UserProfilePage/UserProfilePage';
import PropertyDetailsPage from '../../pages/PropertyDetailsPage/PropertyDetailsPage';
import AdminHeader from '../AdminHeader/AdminHeader';
import styles from './AdminPrivatePaths.module.css';
import EditFluidValuesPage from '../../pages/admin/EditFluidValuesPage/EditFluidValuesPage';

function AdminPrivatePaths() {
  const location = useLocation();
  useEffect(() => {
    if (document.querySelector('#react-confirm-alert')) {
      document.querySelector('#react-confirm-alert').remove();
    }
  }, [location]);
  return (
    <>
      <AdminHeader />
      <div className={styles.content}>
        <Routes>
          <Route path={ROUTES.ADMIN.HOME} element={<HomePage />} />
          <Route path={ROUTES.ADMIN.SNIPPETS} element={<SnippetsPage />} />
          <Route path={ROUTES.ADMIN.GROUPS} element={<SnippetGroupsPage />} />
          <Route path={ROUTES.ADMIN.PROFILE} element={<UserProfilePage />} />
          <Route
            path={ROUTES.ADMIN.EDIT_PROFILE}
            element={<UserProfilePage />}
          />
          <Route path={ROUTES.ADMIN.USERS} element={<UsersPage />} />
          <Route
            path={ROUTES.ADMIN.CREATE_SNIPPET_GROUP}
            element={<CreateSnippetGroupPage />}
          />
          <Route
            path={ROUTES.ADMIN.CREATE_SNIPPET}
            element={<CreateSnippetPage />}
          />
          <Route
            path={ROUTES.ADMIN.EDIT_SNIPPET}
            element={<CreateSnippetPage />}
          />
          <Route
            path={ROUTES.ADMIN.EDIT_SNIPPET_GROUP}
            element={<CreateSnippetGroupPage />}
          />
          <Route
            path={ROUTES.ADMIN.VARIABLE_EXPENSES}
            element={<VariableExpensesPage />}
          />
          <Route
            path={ROUTES.ADMIN.CREATE_VARIABLE_EXPENSES}
            element={<CreateVariableExpensesPage />}
          />
          <Route
            path={ROUTES.ADMIN.EDIT_VARIABLE_EXPENSE}
            element={<CreateVariableExpensesPage />}
          />
          <Route
            path={ROUTES.ADMIN.USER_PROPERTIES}
            element={<ClientPropertiesPage />}
          />
          <Route
            path={ROUTES.ADMIN.USER_PROPERTY_DETAILS}
            element={<PropertyDetailsPage />}
          />
          <Route
            path={ROUTES.ADMIN.FLUID_DEFAULT_VALUES}
            element={<EditFluidValuesPage />}
          />
          <Route
            path="*"
            element={<Navigate replace to={ROUTES.ADMIN.HOME} />}
          />
        </Routes>
      </div>
    </>
  );
}

export default AdminPrivatePaths;

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import ROUTES from '../../../constants/routes';
import SNIPPETS from '../../../constants/snippets';
import { getSnippetBySlug } from '../../../helpers/snippets';
import { required } from '../../../utils/validators';
import Checkbox from '../../components/Checkbox/Checkbox';
import Input from '../../components/Input/Input';
import styles from './LoginForm.module.css';

function LoginForm({ handleSubmit, onSubmit, form }) {
  const snippets = useSelector((state) => state.snippets.snippets);
  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values, form))}>
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.LOGIN_PAGE.EMAIL_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="email"
        name="email"
        label={getSnippetBySlug(snippets, SNIPPETS.LOGIN_PAGE.EMAIL_LABEL)}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.LOGIN_PAGE.PASSWORD_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="password"
        name="password"
        label={getSnippetBySlug(snippets, SNIPPETS.LOGIN_PAGE.PASSWORD_LABEL)}
        component={Input}
      />
      <div className={styles.keepLogBlock}>
        <Field
          name="keepLog"
          label={() =>
            getSnippetBySlug(snippets, SNIPPETS.LOGIN_PAGE.KEEP_LOG_IN)
          }
          component={Checkbox}
        />
        <Link to={ROUTES.AUTH.FORGOT_PASSWORD} className="link">
          {getSnippetBySlug(snippets, SNIPPETS.LOGIN_PAGE.FORGOT_PASSWORD)}
        </Link>
      </div>

      <button
        type="submit"
        data-cy="login-button"
        className="button primary-button full-width"
      >
        {getSnippetBySlug(snippets, SNIPPETS.LOGIN_PAGE.SIGN_IN_BUTTON)}
      </button>
    </form>
  );
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'login-form',
})(LoginForm);

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ROUTES from '../../../../constants/routes';
import {
  createSnippetGroup,
  getSnippetGroup,
  updateSnippetGroup,
} from '../../../../redux/actions/snippetGroups';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';
import CreateSnippetGroupForm from '../../../forms/admin/CreateSnippetGroupForm/CreateSnippetGroupForm';
import styles from './CreateSnippetGroupPage.module.css';

function CreateSnippetGroupPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const parameters = useParams();
  const [snippetGroup, setSnippetGroup] = useState(null);

  const onSubmit = (values) => {
    if (parameters.id) {
      dispatch(
        updateSnippetGroup({ id: parameters.id, ...values }, () => {
          navigate(location.key === 'default' ? ROUTES.ADMIN.GROUPS : -1);
        })
      );
    } else {
      dispatch(
        createSnippetGroup(values, () => {
          navigate(location.key === 'default' ? ROUTES.ADMIN.GROUPS : -1);
        })
      );
    }
  };

  useEffect(() => {
    if (parameters.id) {
      dispatch(
        getSnippetGroup(parameters.id, (data) => {
          setSnippetGroup(data);
        })
      );
    }
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <button
          type="button"
          className="button withImg outlined-secondary-button -thin"
          onClick={() =>
            navigate(location.key === 'default' ? ROUTES.ADMIN.GROUPS : -1)
          }
        >
          <span className="material-icons">chevron_left</span>
          <span>Back</span>
        </button>
        <h2 className="subtitle">
          {!parameters.id ? 'Create new snippet group' : 'Edit Snippet group'}
        </h2>
      </div>
      <Breadcrumbs />
      <CreateSnippetGroupForm
        initialValues={{ ...snippetGroup }}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default CreateSnippetGroupPage;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import { getMarketDetails } from '../../../redux/actions/markets';
import styles from './MarketDetailsPage.module.css';
import { ALL, BEDROOM_COUNT, ONE } from '../../../constants/filters';
import {
  formatDollarValues,
  formatNumberWithComma,
  formatPercentValues,
} from '../../../utils/formatters';
import { getSnippetBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';

function MarketDetailsPage() {
  const navigate = useNavigate();
  const [market, setMarket] = useState(null);
  const dispatch = useDispatch();
  const parameters = useParams();
  const [capRatesFilter, setCapRatesFilter] = useState(ALL);
  const [grossYieldFilter, setGrossYieldFilter] = useState(ALL);
  const [cashFilter, setCashFilter] = useState(ALL);
  const snippets = useSelector((state) => state.snippets.snippets);

  useEffect(() => {
    dispatch(
      getMarketDetails(
        parameters.marketId,
        {
          cap_rates_bedroom_count:
            capRatesFilter !== ALL ? capRatesFilter : undefined,
          gross_yield_bedroom_count:
            grossYieldFilter !== ALL ? grossYieldFilter : undefined,
          coc_bedroom_count: cashFilter !== ALL ? cashFilter : undefined,
        },
        (data) => {
          setMarket(data);
        }
      )
    );
  }, [capRatesFilter, grossYieldFilter, cashFilter]);

  return (
    market && (
      <div>
        <div className={styles.header}>
          <div>
            <button
              type="button"
              className="button withImg outlined-secondary-button -thin"
              onClick={() => {
                navigate(-1);
              }}
            >
              <span className="material-icons">chevron_left</span>
              <span>Back</span>
            </button>
            <h2 className="subtitle">
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.PAGE_TITLE
              )}
            </h2>
          </div>
        </div>
        <h2 className="subtitle">
          {getSnippetBySlug(
            snippets,
            SNIPPETS.MARKET_DETAILS_PAGE.MARKET_NAME_SUBTITLE,
            true
          )}
          : {market.name}
        </h2>
        <h2 className="subtitle">
          {getSnippetBySlug(
            snippets,
            SNIPPETS.MARKET_DETAILS_PAGE.PROPERTIES_ANALYZED_SUBTITLE,
            true
          )}
          : {market.properties_analyzed}
        </h2>
        <div className={styles.graphs}>
          <div>
            <h3 className={styles.graphTitle}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.REVENUE_SUBTITLE
              )}
            </h3>
            <ResponsiveContainer aspect={2}>
              <AreaChart
                width={500}
                height={300}
                data={market.annual_projected_revenue.map((item) => ({
                  name: `${item.bedroom} BR`,
                  [getSnippetBySlug(
                    snippets,
                    SNIPPETS.MARKET_DETAILS_PAGE.REVENUE_SUBTITLE,
                    true
                  )]: Math.round(item.value),
                }))}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(value) => formatDollarValues(value)} />
                <Area
                  type="monotone"
                  dataKey={getSnippetBySlug(
                    snippets,
                    SNIPPETS.MARKET_DETAILS_PAGE.REVENUE_SUBTITLE,
                    true
                  )}
                  stroke="#89AD34"
                  fill="#89AD34"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3 className={styles.graphTitle}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.DAILY_RATES_SUBTITLE
              )}
            </h3>
            <ResponsiveContainer aspect={2}>
              <AreaChart
                width={500}
                height={300}
                data={market.average_daily_rates.map((item) => ({
                  name: `${item.bedroom} BR`,
                  [getSnippetBySlug(
                    snippets,
                    SNIPPETS.MARKET_DETAILS_PAGE.DAILY_RATES_SUBTITLE,
                    true
                  )]: Math.round(item.value),
                }))}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(value) => formatDollarValues(value)} />
                <Area
                  type="monotone"
                  dataKey={getSnippetBySlug(
                    snippets,
                    SNIPPETS.MARKET_DETAILS_PAGE.DAILY_RATES_SUBTITLE,
                    true
                  )}
                  stroke="#89AD34"
                  fill="#89AD34"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3 className={styles.graphTitle}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.OCCUPANCY_SUBTITLE
              )}
            </h3>
            <ResponsiveContainer aspect={2}>
              <AreaChart
                width={500}
                height={300}
                data={market.average_occupancy.map((item) => ({
                  name: `${item.bedroom} BR`,
                  [getSnippetBySlug(
                    snippets,
                    SNIPPETS.MARKET_DETAILS_PAGE.OCCUPANCY_SUBTITLE,
                    true
                  )]: Math.round(item.value),
                }))}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(value) => formatDollarValues(value)} />
                <Area
                  type="monotone"
                  dataKey={getSnippetBySlug(
                    snippets,
                    SNIPPETS.MARKET_DETAILS_PAGE.OCCUPANCY_SUBTITLE,
                    true
                  )}
                  stroke="#89AD34"
                  fill="#89AD34"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className={styles.statistics}>
          <div>
            <h3 className={styles.graphTitle}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.CAP_RATES_SUBTITLE
              )}
            </h3>
            <div className={styles.filters}>
              {BEDROOM_COUNT.map((item, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => setCapRatesFilter(item)}
                  className={`button breadcrumb -thin ${
                    item === capRatesFilter
                      ? 'outlined-primary-button'
                      : 'primary-button'
                  }`}
                >
                  {item}
                </button>
              ))}
            </div>
            <SemiCircleProgressBar
              percentage={
                market.cap_rates_by_bedroom.current_selection_avg_graph
              }
              showPercentValue
              stroke="#89AD34"
              strokeWidth={30}
            />
            <p className={styles.apex}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.AVERAGE_CAP_RATE_LABEL,
                true
              )}{' '}
              ({market.cap_rates_by_bedroom.filter_bedroom} bedroom
              {![ONE].includes(market.cap_rates_by_bedroom.filter_bedroom)
                ? 's'
                : ''}
              {' - '}
              {market.cap_rates_by_bedroom.count_properities_analyzed}{' '}
              properties):{' '}
              {formatPercentValues(
                market.cap_rates_by_bedroom.current_selection_avg
              )}
            </p>
            <p className={styles.apex}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.MARKET_AVERAGE_CAP_RATE_LABEL,
                true
              )}
              : {formatPercentValues(market.cap_rates_by_bedroom.avg)}
            </p>
            <p className={styles.apex}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.APEX_CAP_RATE_LABEL,
                true
              )}{' '}
              {market.cap_rates_by_bedroom.apex} bedroom
              {market.cap_rates_by_bedroom.apex !== 1 ? 's' : ''}
            </p>
          </div>
          <div>
            <h3 className={styles.graphTitle}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.GROSS_YIELD_SUBTITLE
              )}
            </h3>
            <div className={styles.filters}>
              {BEDROOM_COUNT.map((item, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => setGrossYieldFilter(item)}
                  className={`button breadcrumb -thin ${
                    item === grossYieldFilter
                      ? 'outlined-primary-button'
                      : 'primary-button'
                  }`}
                >
                  {item}
                </button>
              ))}
            </div>
            <SemiCircleProgressBar
              percentage={
                market.gross_yield_by_bedroom.current_selection_avg_graph
              }
              showPercentValue
              stroke="#89AD34"
              strokeWidth={30}
            />
            <p className={styles.apex}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.AVERAGE_GROSS_YIELD_LABEL,
                true
              )}{' '}
              ({market.gross_yield_by_bedroom.filter_bedroom} bedroom
              {![ONE].includes(market.gross_yield_by_bedroom.filter_bedroom)
                ? 's'
                : ''}
              {' - '}
              {market.gross_yield_by_bedroom.count_properities_analyzed}{' '}
              properties):{' '}
              {formatPercentValues(
                market.gross_yield_by_bedroom.current_selection_avg
              )}
            </p>
            <p className={styles.apex}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.MARKET_AVERAGE_GROSS_YIELD_LABEL,
                true
              )}
              : {formatPercentValues(market.gross_yield_by_bedroom.avg)}
            </p>
            <p className={styles.apex}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.APEX_GROSS_YIELD_LABEL,
                true
              )}{' '}
              {market.gross_yield_by_bedroom.apex} bedroom
              {market.gross_yield_by_bedroom.apex !== 1 ? 's' : ''}
            </p>
          </div>
          <div>
            <h3 className={styles.graphTitle}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.CASH_ON_CASH_SUBTITLE
              )}
            </h3>
            <div className={styles.filters}>
              {BEDROOM_COUNT.map((item, index) => (
                <button
                  type="button"
                  key={index}
                  onClick={() => setCashFilter(item)}
                  className={`button breadcrumb -thin ${
                    item === cashFilter
                      ? 'outlined-primary-button'
                      : 'primary-button'
                  }`}
                >
                  {item}
                </button>
              ))}
            </div>
            <SemiCircleProgressBar
              percentage={
                market.cash_on_cash_return_by_bedroom
                  .current_selection_avg_graph
              }
              showPercentValue
              stroke="#89AD34"
              strokeWidth={30}
            />
            <p className={styles.apex}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.AVERAGE_CASH_LABEL,
                true
              )}{' '}
              ({market.cash_on_cash_return_by_bedroom.filter_bedroom} bedroom
              {![ONE].includes(
                market.cash_on_cash_return_by_bedroom.filter_bedroom
              )
                ? 's'
                : ''}
              {' - '}
              {
                market.cash_on_cash_return_by_bedroom.count_properities_analyzed
              }{' '}
              properties):{' '}
              {formatPercentValues(
                market.cash_on_cash_return_by_bedroom.current_selection_avg
              )}
            </p>
            <p className={styles.apex}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.MARKET_AVERAGE_GROSS_YIELD_LABEL,
                true
              )}
              : {formatPercentValues(market.cash_on_cash_return_by_bedroom.avg)}
            </p>
            <p className={styles.apex}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.MARKET_DETAILS_PAGE.APEX_CASH_LABEL,
                true
              )}{' '}
              {market.cash_on_cash_return_by_bedroom.apex} bedroom
              {market.cash_on_cash_return_by_bedroom.apex !== 1 ? 's' : ''}
            </p>
          </div>
        </div>
      </div>
    )
  );
}

export default MarketDetailsPage;

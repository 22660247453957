import React from 'react';
/* eslint-disable import/no-unresolved */
import logo from 'public/assets/images/logo.png';
/* eslint-enable import/no-unresolved */
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ForgotPasswordPage.module.css';
import { forgotPassword } from '../../../redux/actions/auth';
import ROUTES from '../../../constants/routes';
import ForgotPasswordForm from '../../forms/ForgotPasswordForm/ForgotPasswordForm';
import { getSnippetBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';

function ForgotPasswordPage() {
  const dispatch = useDispatch();
  const snippets = useSelector((state) => state.snippets.snippets);

  function onSubmit(values, form) {
    dispatch(forgotPassword(values, form));
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftSide} />
      <div className={styles.rightSide}>
        <div>
          <img
            src={logo}
            alt="Application Logo"
            style={{ visibility: 'hidden' }}
          />
        </div>
        <div>
          <h3 className="subtitle">
            {getSnippetBySlug(snippets, SNIPPETS.FORGOT_PASSWORD_PAGE.TITLE)}
          </h3>
          <ForgotPasswordForm onSubmit={onSubmit} />
        </div>
        <div className={styles.haveAccount}>
          {getSnippetBySlug(
            snippets,
            SNIPPETS.FORGOT_PASSWORD_PAGE.ALREADY_HAVE_ACCOUNT
          )}
          <Link to={ROUTES.AUTH.LOGIN} className="link">
            {getSnippetBySlug(
              snippets,
              SNIPPETS.FORGOT_PASSWORD_PAGE.SIGN_IN_LINK
            )}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { numberOrEmpty } from '../../../utils/validators';
import DatePicker from '../../components/DatePicker/DatePicker';
import Input from '../../components/Input/Input';
import styles from './NoiFilter.module.css';

const validate = (values) => {
  const errors = {};
  if (
    (values.net_operating_income_from && !values.net_operating_income_to) ||
    (!values.net_operating_income_from && values.net_operating_income_to)
  ) {
    errors.net_operating_income_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (
    +values.net_operating_income_from > +values.net_operating_income_to
  ) {
    errors.net_operating_income_error =
      'From value must be less or equal than to value';
  }

  if (
    (values.created_at_from && !values.created_at_to) ||
    (!values.created_at_from && values.created_at_to)
  ) {
    errors.created_at_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (+values.created_at_from > +values.created_at_to) {
    errors.created_at_error = 'From value must be less or equal than to value';
  }
  return errors;
};

function NoiFilter({ onSubmit, handleSubmit, change }) {
  const onLastDaysSelected = (days) => {
    const d = new Date();
    d.setDate(d.getDate() - days + 1);
    change('created_at_from', d);
    change('created_at_to', new Date());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div>
        <h4>Net Operating Income</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="From"
            name="net_operating_income_from"
            component={Input}
          />
          <span>To</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="To"
            name="net_operating_income_to"
            component={Input}
          />
        </div>
        <Field
          type="hidden"
          name="net_operating_income_error"
          component={Input}
        />
      </div>
      <div>
        <h4>Сreation Date</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            name="created_at_from"
            component={DatePicker}
            placeholder="Select Date"
          />
          <span>To</span>
          <Field
            name="created_at_to"
            component={DatePicker}
            placeholder="Select Date"
          />
        </div>
        <div className={styles.dateButtons}>
          <button
            type="button"
            className="button -thin outlined-primary-button"
            onClick={() => onLastDaysSelected(7)}
          >
            Last 7 days
          </button>
          <button
            type="button"
            className="button -thin outlined-primary-button"
            onClick={() => onLastDaysSelected(30)}
          >
            Last 30 days
          </button>
          <button
            type="button"
            className="button -thin outlined-primary-button"
            onClick={() => onLastDaysSelected(90)}
          >
            Last 90 days
          </button>
        </div>
        <Field type="hidden" name="created_at_error" component={Input} />
      </div>
      <button className="button primary-button center">Apply Filters</button>
    </form>
  );
}

export default reduxForm({
  form: 'noi-filter',
  validate,
})(NoiFilter);

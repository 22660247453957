const CURRENCY_TYPES = {
  DOLLAR: 'currency',
  PERCENT: 'percent',
};

export const CURRENCY_LABEL = {
  currency: '$',
  percent: '%',
};

export default CURRENCY_TYPES;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ROUTES from '../../../../constants/routes';
import { SNIPPET_TYPES } from '../../../../constants/snippets';
import {
  createSnippet,
  getSnippet,
  updateSnippet,
} from '../../../../redux/actions/snippets';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';
import CreateSnippetForm from '../../../forms/admin/CreateSnippetForm/CreateSnippetForm';
import styles from './CreateSnippetPage.module.css';

function CreateSnippetPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const parameters = useParams();
  const [snippet, setSnippet] = useState(null);

  const onSubmit = (values) => {
    if (parameters.id) {
      dispatch(
        updateSnippet(
          {
            id: parameters.id,
            ...values,
            group_snippet_id: values.group_snippet_id.value,
            type: values.type.value,
            tooltip: values.type.value === 'label' ? values.tooltip : null,
          },
          () => {
            navigate(
              location.state && location.state.pathname
                ? location.state.pathname
                : ROUTES.ADMIN.SNIPPETS,
              {
                state: {
                  ...location.state,
                },
              }
            );
          }
        )
      );
    } else {
      dispatch(
        createSnippet(
          {
            ...values,
            group_snippet_id: values.group_snippet_id.value,
            type: values.type.value,
            tooltip: values.type.value === 'label' ? values.tooltip : null,
          },
          () => {
            navigate(
              location.state && location.state.pathname
                ? location.state.pathname
                : ROUTES.ADMIN.SNIPPETS,
              {
                state: {
                  ...location.state,
                },
              }
            );
          }
        )
      );
    }
  };

  useEffect(() => {
    if (parameters.id) {
      dispatch(
        getSnippet(parameters.id, (data) => {
          setSnippet(data);
        })
      );
    }
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <button
          type="button"
          className="button withImg outlined-secondary-button -thin"
          onClick={() =>
            navigate(
              location.state && location.state.pathname
                ? location.state.pathname
                : ROUTES.ADMIN.SNIPPETS,
              {
                state: {
                  ...location.state,
                },
              }
            )
          }
        >
          <span className="material-icons">chevron_left</span>
          <span>Back</span>
        </button>
        <h2 className="subtitle">
          {!parameters.id ? 'Create new snippet' : 'Edit Snippet'}
        </h2>
      </div>
      <Breadcrumbs />
      <CreateSnippetForm
        initialValues={
          snippet
            ? {
                ...snippet,
                group_snippet_id: {
                  label: snippet.group.name,
                  value: snippet.group.id,
                },
                type: {
                  label: SNIPPET_TYPES[snippet.type],
                  value: snippet.type,
                },
              }
            : {}
        }
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default CreateSnippetPage;

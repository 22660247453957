import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import SNIPPETS from '../../../constants/snippets';
import { getSnippetBySlug } from '../../../helpers/snippets';
import { required } from '../../../utils/validators';
import InfoAlert from '../../components/InfoAlert/InfoAlert';
import Input from '../../components/Input/Input';
import styles from './ForgotPasswordForm.module.css';

function ForgotPasswordForm({ handleSubmit, onSubmit, form }) {
  const formSubmitData = useSelector((state) => state.formSubmit);
  const snippets = useSelector((state) => state.snippets.snippets);

  return formSubmitData.form === form && formSubmitData.successMessage ? (
    <div className={styles.infoMessage}>
      <InfoAlert type="success" message={formSubmitData.successMessage} />
    </div>
  ) : (
    <form onSubmit={handleSubmit((values) => onSubmit(values, form))}>
      <InfoAlert
        message={getSnippetBySlug(
          snippets,
          SNIPPETS.FORGOT_PASSWORD_PAGE.INFO_TEXT
        )}
      />

      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.FORGOT_PASSWORD_PAGE.EMAIL_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="email"
        name="email"
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.FORGOT_PASSWORD_PAGE.EMAIL_LABEL
        )}
        component={Input}
      />
      <button type="submit" className="button primary-button full-width">
        {getSnippetBySlug(snippets, SNIPPETS.FORGOT_PASSWORD_PAGE.RESET_BUTTON)}
      </button>
    </form>
  );
}

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'forgot-password-form',
})(ForgotPasswordForm);

import React from 'react';
/* eslint-disable import/no-unresolved */
import logo from 'public/assets/images/logo.png';
/* eslint-enable import/no-unresolved */
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './ResetPasswordPage.module.css';
import ROUTES from '../../../constants/routes';
import ResetPasswordForm from '../../forms/ResetPasswordForm/ResetPasswordForm';
import { resetPassword } from '../../../redux/actions/auth';

function ResetPasswordPage() {
  const dispatch = useDispatch();
  const location = useLocation();

  function onSubmit(values, form) {
    const token = new URLSearchParams(location.search).get('token');
    dispatch(resetPassword({ ...values, token }, form));
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftSide} />
      <div className={styles.rightSide}>
        <div>
          <img
            src={logo}
            alt="Application Logo"
            style={{ visibility: 'hidden' }}
          />
        </div>
        <div>
          <h3 className="subtitle">Reset Password?</h3>
          <ResetPasswordForm onSubmit={onSubmit} />
        </div>
        <div className={styles.haveAccount}>
          <span>Already have an account?</span>
          <Link to={ROUTES.AUTH.LOGIN} className="link">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;

import ENDPOINTS from '../../constants/endpoints';
import { SET_MARKETS } from '../action-types/markets';
import { apiAction } from './api';
import { setGeneralSuccessMessage } from './form';

export const getMarkets = (data) => {
  const { onSuccess, ...parameters } = data;
  return apiAction({
    url: ENDPOINTS.MARKETS.GET_MARKETS,
    method: 'GET',
    onSuccess: (dispatch, _, data) => {
      onSuccess && onSuccess();
      dispatch(setMarkets(data));
    },
    data: parameters,
  });
};

export const getMarketDetails = (id, data, onSuccess) => {
  return apiAction({
    url: ENDPOINTS.MARKETS.MARKET_DETAILS(id),
    method: 'GET',
    onSuccess: (dispatch, _, data) => {
      onSuccess && onSuccess(data.data);
    },
    data,
  });
};

export const createMarket = (data) => {
  return apiAction({
    url: ENDPOINTS.MARKETS.GET_MARKETS,
    method: 'POST',
    onSuccess: (dispatch, _, response) => {
      data.onSuccess && data.onSuccess(response.data);
      dispatch(
        setGeneralSuccessMessage('Market has been created successfully')
      );
    },
    data: data.payload,
  });
};

export const updateMarket = (data) => {
  return apiAction({
    url: ENDPOINTS.MARKETS.MARKET(data.id),
    method: 'PUT',
    onSuccess: (dispatch) => {
      data.onSuccess && data.onSuccess();
      dispatch(
        setGeneralSuccessMessage('Market has been updated successfully')
      );
    },
    data: data.payload,
  });
};

export const deleteMarket = (data) => {
  return apiAction({
    url: ENDPOINTS.MARKETS.MARKET(data.id),
    method: 'DELETE',
    onSuccess: (dispatch) => {
      dispatch(getMarkets({ page: data.page, per_page: data.per_page }));
      dispatch(
        setGeneralSuccessMessage('Market has been deleted successfully')
      );
    },
  });
};

export const setMarkets = (data) => {
  return {
    type: SET_MARKETS,
    markets: data.data,
    count: data.meta.total,
  };
};

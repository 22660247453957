import React, { useEffect, useState, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import placeholderImage from 'public/assets/placeholder-image.png';
import Toggle from 'react-toggle';
import ROUTES from '../../../constants/routes';
import {
  changeStatus,
  deleteProperty,
  getProperties,
} from '../../../redux/actions/properties';
import {
  formatDollarValues,
  formatPercentValues,
} from '../../../utils/formatters';
import Dialog from '../../components/Dialog/Dialog';
import Pagination from '../../components/Pagination/Pagination';
import styles from './PropertiesPage.module.css';
import { getSnippetBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';
import PropertySearchForm from '../../forms/PropertySearchForm/PropertySearchForm';
import PropertyFilterForm from '../../forms/PropertyFilterForm/PropertyFilterForm';
import FormDialog from '../../components/FormDialog/FormDialog';
import { usePrevious } from '../../../hooks/usePrevious';

function PropertiesPage() {
  const currentItems = useSelector((state) => state.properties.properties);
  const total = useSelector((state) => state.properties.total);
  const user = useSelector((state) => state.auth.user);
  const snippets = useSelector((state) => state.snippets.snippets);
  const location = useLocation();
  const [filters, setFilters] = useState(
    location.state && location.state.filters
      ? location.state.filters
      : { status: 0 }
  );
  const [selectedPage, setSelectedPage] = useState(
    +new URLSearchParams(location.search).get('page') || 0
  );
  const previous = usePrevious({ filters, selectedPage });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ITEMS_PER_PAGE, setItemsPerPage] = useState(
    location.state && location.state.perPage ? location.state.perPage : 20
  );
  const cashRule = useSelector(
    (state) => state.auth.user.color_data_cash_return
  )[0];
  const grossUpRule = useSelector(
    (state) => state.auth.user.color_data_gross_yield
  )[0];
  const colorDataRules = useSelector(
    (state) => state.auth.user.color_data_rules
  )[0];
  const [sortBy, setSortBy] = useState({});
  const isFirstRender = useRef(true);
  const getPropertiesData = (page) => {
    const filtersData = {};
    const availableFilters = { ...filters };
    if (filters.created_at_from) {
      availableFilters.created_at_from = filters.created_at_from
        .toISOString()
        .slice(0, 10);
    }
    if (filters.created_at_to) {
      availableFilters.created_at_to = filters.created_at_to
        .toISOString()
        .slice(0, 10);
    }
    availableFilters.status =
      filters.status === 0
        ? 'active'
        : filters.status === 1
        ? 'inactive'
        : undefined;
    for (const [key, value] of Object.entries(availableFilters)) {
      if (Array.isArray(value) && value.length > 0) {
        filtersData[`filters[${key}]`] = value
          .map((item) => item.value)
          .join(',');
      } else if (value) {
        filtersData[`filters[${key}]`] = value;
      }
    }
    dispatch(
      getProperties({
        page: page + 1,
        per_page: ITEMS_PER_PAGE,
        ...sortBy,
        ...filtersData,
      })
    );
  };

  useEffect(() => {
    // if filters changes reset page to 0
    if (
      previous &&
      selectedPage === previous.selectedPage &&
      filters !== previous.filters &&
      selectedPage !== 0
    ) {
      setSelectedPage(0);
      navigate(location.pathname);
    } else {
      getPropertiesData(selectedPage);
    }
  }, [selectedPage, filters]);

  useEffect(() => {
    setSelectedPage(+new URLSearchParams(location.search).get('page') || 0);
    getPropertiesData(+new URLSearchParams(location.search).get('page') || 0);
  }, [ITEMS_PER_PAGE]);

  useEffect(() => {
    if (!isFirstRender.current) {
      getPropertiesData(selectedPage);
    } else {
      isFirstRender.current = false;
    }
  }, [sortBy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSort = (field, startFromDesc = false) => {
    setSortBy({
      ...sortBy,
      [`sort[${field}]`]: startFromDesc
        ? sortBy[`sort[${field}]`] === 'asc'
          ? undefined
          : sortBy[`sort[${field}]`] === 'desc'
          ? 'asc'
          : 'desc'
        : sortBy[`sort[${field}]`] === 'asc'
        ? 'desc'
        : sortBy[`sort[${field}]`] === 'desc'
        ? undefined
        : 'asc',
    });
  };

  const handlePageClick = (event) => {
    setSelectedPage(event.selected);
    navigate({
      pathname: window.location.pathname,
      search: `?page=${event.selected}`,
    });
  };

  const removeProperty = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <Dialog
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTIES_PAGE.DELETE_TITLE
          )}
          text={getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTIES_PAGE.DELETE_SUBTITLE
          )}
          buttons={[
            {
              type: 'button',
              text: 'Delete',
              onClick: () => {
                dispatch(
                  deleteProperty({
                    id,
                    onSuccess: () =>
                      dispatch(
                        getProperties({
                          page: selectedPage + 1,
                          per_page: ITEMS_PER_PAGE,
                        })
                      ),
                  })
                );
                onClose();
              },
              className: 'secondary-button',
            },
            {
              type: 'button',
              text: 'Cancel',
              onClick: onClose,
              className: 'secondary-button',
            },
          ]}
        />
      ),
    });
  };

  const openFilterDialog = () => {
    confirmAlert({
      overlayClassName: 'overlay',
      closeOnClickOutside: false,
      customUI: ({ onClose }) => (
        <FormDialog
          onClose={onClose}
          title={getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTY_FILTER_FORM.TITLE
          )}
          form={
            <PropertyFilterForm
              initialValues={filters}
              onSubmit={(values) => {
                setFilters({ ...filters, ...values });
                onClose();
              }}
            />
          }
        />
      ),
    });
  };

  const resetFilters = () => {
    setFilters(
      Object.fromEntries(
        Object.entries(filters).filter(
          ([key]) => key === 'search' || key === 'status'
        )
      )
    );
  };

  const editProperty = (id) => {
    navigate(`${ROUTES.USER.PROPERTIES}/${id}/edit`, {
      state: {
        pathname: location.pathname + location.search,
        filters,
        perPage: ITEMS_PER_PAGE,
      },
    });
  };

  const numberOfFilters = Object.values(
    Object.fromEntries(
      Object.entries(filters).filter(
        ([key]) => !key.endsWith('to') && key !== 'search' && key !== 'status'
      )
    )
  ).filter((item) => (Array.isArray(item) ? item.length > 0 : !!item)).length;

  const handleStatusChange = (id, checked) => {
    dispatch(changeStatus(id, { status: checked ? 'active' : 'inactive' }));
  };

  const onActiveFilterClicked = (status) => {
    setFilters({
      ...filters,
      status,
    });
  };

  return (
    <div>
      <div className={styles.header}>
        <h2 className={`subtitle ${styles.title}`}>
          {getSnippetBySlug(snippets, SNIPPETS.PROPERTIES_PAGE.TITLE)}
          <span className={styles.total}>- {total}</span>
        </h2>
        <button
          type="button"
          onClick={() => {
            navigate(ROUTES.USER.CREATE_PROPERTY, {
              state: {
                pathname: location.pathname + location.search,
                filters,
                perPage: ITEMS_PER_PAGE,
              },
            });
          }}
          className="button withImg primary-button -thin"
        >
          <span className="material-icons">add</span>
          <span>
            {getSnippetBySlug(
              snippets,
              SNIPPETS.PROPERTIES_PAGE.CREATE_NEW_PROPERTY_BUTTON
            )}
          </span>
        </button>
      </div>
      <div className={styles.statusButtons}>
        {['Active', 'Inactive', 'All'].map((button, index) => (
          <button
            type="button"
            key={index}
            className={`button -thin ${
              index === filters.status
                ? 'secondary-button'
                : 'outlined-secondary-button'
            }`}
            onClick={() => onActiveFilterClicked(index)}
          >
            {button}
          </button>
        ))}
      </div>
      <div className={styles.filters}>
        <div>
          <PropertySearchForm
            initialValues={{
              search: filters.search,
            }}
            onSubmit={(values) => {
              setFilters({ ...filters, ...values });
            }}
          />
        </div>
        <button
          type="button"
          onClick={() => {
            openFilterDialog();
          }}
          className={`button withImg primary-button ${styles.filterButton}`}
        >
          <span className="material-icons">filter_alt</span>
          <span>
            {getSnippetBySlug(snippets, SNIPPETS.PROPERTIES_PAGE.FILTER_BUTTON)}
          </span>
          {numberOfFilters > 0 && (
            <span className={`breadcrumb filled-warning ${styles.number}`}>
              {numberOfFilters}
            </span>
          )}
        </button>
        <button
          type="button"
          style={{ visibility: numberOfFilters ? 'visible' : 'hidden' }}
          onClick={resetFilters}
          className="button primary-button"
        >
          {getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTIES_PAGE.RESET_FILTER_BUTTON
          )}
        </button>
      </div>

      {total === 0 ? (
        'No properties yet'
      ) : (
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th className={styles.photo}>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROPERTIES_PAGE.PHOTO_COL
                  )}
                </th>
                <th className={styles.photoWithInfo}>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROPERTIES_PAGE.PROPERTY_DETAILS_COL
                  )}
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.MARKET_NAME_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('markets.name')}>
                      {(sortBy['sort[markets.name]'] === 'asc' ||
                        !sortBy['sort[markets.name]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[markets.name]'] === 'desc' ||
                        !sortBy['sort[markets.name]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th className={styles.address}>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.ADDRESS_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('address')}>
                      {(sortBy['sort[address]'] === 'asc' ||
                        !sortBy['sort[address]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[address]'] === 'desc' ||
                        !sortBy['sort[address]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.STARTUP_COSTS_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('investment_total')}>
                      {(sortBy['sort[investment_total]'] === 'asc' ||
                        !sortBy['sort[investment_total]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[investment_total]'] === 'desc' ||
                        !sortBy['sort[investment_total]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.REVENUE_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('projected_rent')}>
                      {(sortBy['sort[projected_rent]'] === 'asc' ||
                        !sortBy['sort[projected_rent]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[projected_rent]'] === 'desc' ||
                        !sortBy['sort[projected_rent]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.ANNUAL_EXPENSES_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('annual_expenses')}>
                      {(sortBy['sort[annual_expenses]'] === 'asc' ||
                        !sortBy['sort[annual_expenses]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[annual_expenses]'] === 'desc' ||
                        !sortBy['sort[annual_expenses]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.CASH_FLOW_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('cash_flow')}>
                      {(sortBy['sort[cash_flow]'] === 'asc' ||
                        !sortBy['sort[cash_flow]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[cash_flow]'] === 'desc' ||
                        !sortBy['sort[cash_flow]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.CAP_RATE_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('cap_rate')}>
                      {(sortBy['sort[cap_rate]'] === 'asc' ||
                        !sortBy['sort[cap_rate]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[cap_rate]'] === 'desc' ||
                        !sortBy['sort[cap_rate]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.GROSS_COL
                      )}
                    </span>
                    <div onClick={() => handleSort('gross_yield')}>
                      {(sortBy['sort[gross_yield]'] === 'asc' ||
                        !sortBy['sort[gross_yield]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[gross_yield]'] === 'desc' ||
                        !sortBy['sort[gross_yield]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="sort">
                    <span>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTIES_PAGE.CASH_ON_CASH_COL
                      )}
                    </span>
                    <div
                      onClick={() => handleSort('cash_on_cash_return', true)}
                    >
                      {(sortBy['sort[cash_on_cash_return]'] === 'asc' ||
                        !sortBy['sort[cash_on_cash_return]']) && (
                        <span className="material-icons small">
                          keyboard_arrow_up
                        </span>
                      )}
                      {(sortBy['sort[cash_on_cash_return]'] === 'desc' ||
                        !sortBy['sort[cash_on_cash_return]']) && (
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <th className={styles.status}>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className={styles.photo}>
                        {item.photos ? (
                          <img src={item.photos.thumb} alt="Property photo" />
                        ) : (
                          <img
                            className={styles.imagePlaceholder}
                            src={placeholderImage}
                            alt="Image placeholder"
                          />
                        )}
                      </td>
                      <td className={styles.photoWithInfo}>
                        {item.photos ? (
                          <img src={item.photos.thumb} alt="Property photo" />
                        ) : (
                          <img
                            className={styles.imagePlaceholder}
                            src={placeholderImage}
                            alt="Image placeholder"
                          />
                        )}
                        <a
                          className="link"
                          href={
                            item.parsing_url ||
                            `${ROUTES.USER.PROPERTIES}/${item.id}`
                          }
                          target={item.parsing_url ? '_blank' : '_self'}
                          rel="noreferrer"
                        >
                          {item.address}
                        </a>
                        <Toggle
                          defaultChecked={item.status === 'active'}
                          onChange={(e) =>
                            handleStatusChange(item.id, e.target.checked)
                          }
                        />
                      </td>
                      <td className="capitalized">{item.market_name}</td>
                      <td className={`nowrap ${styles.address}`}>
                        <a
                          className="link"
                          href={
                            item.parsing_url ||
                            `${ROUTES.USER.PROPERTIES}/${item.id}`
                          }
                          target={item.parsing_url ? '_blank' : '_self'}
                          rel="noreferrer"
                        >
                          {item.address}
                        </a>
                      </td>
                      <td>{formatDollarValues(item.investment_total)}</td>
                      <td>{formatDollarValues(item.projected_rent)}</td>
                      <td>{formatDollarValues(item.annual_expenses, true)}</td>
                      <td>{formatDollarValues(item.cash_flow)}</td>
                      <td>
                        <span
                          className={`${
                            item.cap_rate < +colorDataRules.red
                              ? 'filled-error'
                              : item.cap_rate < +colorDataRules.yellow
                              ? 'filled-warning'
                              : 'filled-success'
                          } breadcrumb`}
                        >
                          {formatPercentValues(item.cap_rate)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            item.gross_yield < +grossUpRule.red
                              ? 'filled-error'
                              : item.gross_yield < +grossUpRule.yellow
                              ? 'filled-warning'
                              : 'filled-success'
                          } breadcrumb`}
                        >
                          {formatPercentValues(+item.gross_yield)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            item.cash_on_cash_return < +cashRule.red
                              ? 'filled-error'
                              : item.cash_on_cash_return < +cashRule.yellow
                              ? 'filled-warning'
                              : 'filled-success'
                          } breadcrumb`}
                        >
                          {formatPercentValues(item.cash_on_cash_return)}
                        </span>
                      </td>
                      <td className={styles.status}>
                        <Toggle
                          defaultChecked={item.status === 'active'}
                          onChange={(e) =>
                            handleStatusChange(item.id, e.target.checked)
                          }
                        />
                      </td>
                      <td>
                        <div className={styles.buttons}>
                          <button
                            type="button"
                            className="button action-button"
                            onClick={() =>
                              navigate(`${ROUTES.USER.PROPERTIES}/${item.id}`, {
                                state: {
                                  pathname: location.pathname + location.search,
                                  filters,
                                  perPage: ITEMS_PER_PAGE,
                                },
                              })
                            }
                          >
                            <span className="material-icons">visibility</span>
                          </button>
                          {user.id === item.user.id && (
                            <button
                              type="button"
                              className="button action-button"
                              onClick={() => editProperty(item.id)}
                            >
                              <span className="material-icons">edit</span>
                            </button>
                          )}
                          {user.id === item.user.id && (
                            <button
                              type="button"
                              className="button action-button"
                              onClick={() => removeProperty(item.id)}
                            >
                              <span className="material-icons">delete</span>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {total !== 0 && (
        <Pagination
          onPageChange={handlePageClick}
          selectedPage={selectedPage}
          numberPerPageOptions={[20, 30, 50, 100]}
          numberPerPage={ITEMS_PER_PAGE}
          onNumberPerPageChanged={setItemsPerPage}
          pageCount={Math.ceil(total / ITEMS_PER_PAGE)}
        />
      )}
    </div>
  );
}

export default PropertiesPage;

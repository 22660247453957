import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import SNIPPETS from '../../../../constants/snippets';
import { getSnippetBySlug } from '../../../../helpers/snippets';
import Input from '../../../components/Input/Input';

function ReportPersonalDataForm({ handleSubmit, onSubmit, form }) {
  const snippets = useSelector((state) => state.snippets.snippets);

  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values, form))}>
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.PHONE_PLACEHOLDER,
          true
        )}
        type="text"
        name="phone"
        label={getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.PHONE_LABEL)}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.LOCATION_PLACEHOLDER,
          true
        )}
        type="text"
        name="location"
        label={getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.LOCATION_LABEL)}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.JOB_TITLE_PLACEHOLDER,
          true
        )}
        type="text"
        name="job_title"
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.JOB_TITLE_LABEL
        )}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.WEBSITE_PLACEHOLDER,
          true
        )}
        type="text"
        name="site"
        label={getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.WEBSITE_LABEL)}
        component={Input}
      />
      <button type="submit" className="button primary-button">
        {getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.PDF_INFORMATION_SAVE_BUTTON
        )}
      </button>
    </form>
  );
}

export default reduxForm({
  form: 'report-personal-data-form',
  enableReinitialize: true,
})(ReportPersonalDataForm);

const ENDPOINTS = {
  AUTH: {
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    LOGIN: '/login',
  },
  USER: {
    GET_INFO: '/users/show',
    AVATAR: '/users/avatar',
    AVATAR_BY_ID: (id) => `/clients/${id}/avatar`,
    DELETE_USER: '/users/delete',
    UPDATE_PROFILE: '/users/put',
    CLIENTS: '/clients/list',
    DELETE_BY_ID: (id) => `clients/${id}/delete`,
    GET_PROFILE: (id) => `clients/${id}/show`,
    UPDATE_PROFILE_BY_ID: (id) => `clients/${id}/put`,
  },
  MARKETS: {
    GET_MARKETS: '/markets',
    MARKET: (id) => `/markets/${id}`,
    MARKET_DETAILS: (id) => `/markets/analytics/${id}`,
  },
  PROPERTIES: {
    GET_PROPERTIES: '/markets/properties',
    GET_PROPERTIES_FOR_CLIENT: (id) => `/clients/${id}/properties`,
    GET_ALL_PROPERTIES: '/markets/all-properties',
    PROPERTIES_BY_MARKET: (marketId) => `/properties/${marketId}`,
    COSTS: (propertyId) => `properties/variable-costs/${propertyId}`,
    PROPERTY: (id) => `/markets/properties/${id}`,
    SHARE_PUBLIC_LINK: (id) => `/properties/get-slug/${id}`,
    PARSE_PHOTO: '/get-photo/parse',
    PARSE_DATA: '/get-data/parse',
    GET_SUM: 'properties/variable-costs/sum',
    PROPERTY_BY_SLUG: (slug) => `/share/property/${slug}`,
    RENTALIZER: () => 'properties/get_rentalizer',
    PROPERTY_DETAILS: (propertyId) =>
      `/markets/properties/${propertyId}/detail`,
    REMOVE_EXPENSES: (propertyId) => `properties/${propertyId}/expenses`,
    CHANGE_STATUS: (propertyId) =>
      `/markets/properties/${propertyId}/changeStatus`,
    GET_OVERVIEW: () => '/properties/get_overview',
  },
  DASHBOARD: {
    TOP_FIVE_COC: '/dashboard/top-five-coc',
    TOP_FIVE_NOI: '/dashboard/top-five-noi',
    RECENT_ADDED_PROPERTIES: '/dashboard/recent-added-properties',
    PROPERTIES_COUNT: '/dashboard/count-properties',
  },
  DATA: {
    STATES: '/dictionaries/states',
    CITIES: '/dictionaries/cities',
    VARIABLE_COSTS: '/dictionaries/expense-variable',
    SNIPPETS: '/snippets',
    SNIPPET_GROUP: '/snippets/group',
    VARIABLE_EXPENSES: '/dictionaries/expenses-dictionary',
    FLUID_VALUES: (expenseId) => `/expenses-by-states/${expenseId}`,
    SINGLE_GROUP: (id) => `/snippets/group/${id}`,
    SNIPPET: (id) => `/snippets/${id}`,
    SINGLE_VARIABLE_EXPENSE: (id) => `dictionaries/expenses-dictionary/${id}`,
  },
};
export default ENDPOINTS;

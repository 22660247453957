import React from 'react';
import styles from './Dialog.module.css';

function Dialog({ title, text, buttons }) {
  return (
    <div data-cy="dialog" className={styles.dialog}>
      <h2>{title}</h2>
      <p>{text}</p>
      <div className={styles.buttons}>
        {buttons.map((button, index) => (
          <button
            className={`button ${button.className}`}
            key={index}
            type={button.type}
            onClick={button.onClick}
          >
            {button.text}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Dialog;

import React from 'react';
/* eslint-disable import/no-unresolved */
import logo from 'public/assets/images/logo.png';
/* eslint-enable import/no-unresolved */
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './LoginPage.module.css';
import LoginForm from '../../forms/LoginForm/LoginForm';
import { login } from '../../../redux/actions/auth';
import ROUTES from '../../../constants/routes';
import SNIPPETS from '../../../constants/snippets';
import { getSnippetBySlug } from '../../../helpers/snippets';

function LoginPage() {
  const dispatch = useDispatch();
  const snippets = useSelector((state) => state.snippets.snippets);

  function onSubmit(values, form) {
    const { keepLog, ...data } = values;
    dispatch(login(data, form));
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftSide} />
      <div className={styles.rightSide}>
        <div>
          <img
            src={logo}
            alt="Application Logo"
            style={{ visibility: 'hidden' }}
          />
        </div>
        <div>
          <h3 className="subtitle">
            {getSnippetBySlug(snippets, SNIPPETS.LOGIN_PAGE.TITLE)}
          </h3>
          <LoginForm onSubmit={onSubmit} />
        </div>
        <div className={styles.haveAccount}>
          {getSnippetBySlug(snippets, SNIPPETS.LOGIN_PAGE.NOT_ACCOUNT)}
          <Link to={ROUTES.AUTH.REGISTER} className="link">
            {getSnippetBySlug(snippets, SNIPPETS.LOGIN_PAGE.SIGN_UP_LINK)}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

import React from 'react';
import ReactPaginate from 'react-paginate';
import WindowedSelect from 'react-windowed-select';
import styles from './Pagination.module.css';

function Pagination({
  onPageChange,
  pageCount,
  selectedPage,
  numberPerPageOptions,
  numberPerPage,
  onNumberPerPageChanged,
}) {
  return (
    <div className={styles.container}>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={onPageChange}
        pageRangeDisplayed={5}
        className={styles.paginator}
        pageClassName={styles.paginatorButton}
        previousClassName={styles.previousButton}
        nextClassName={styles.nextButton}
        disabledClassName={styles.disabledButton}
        activeClassName={styles.activeButton}
        pageCount={pageCount}
        previousLabel="<"
        forcePage={selectedPage}
        renderOnZeroPageCount={null}
      />
      {numberPerPageOptions && (
        <div className={styles.select}>
          <WindowedSelect
            options={numberPerPageOptions.map((option) => ({
              label: option,
              value: option,
            }))}
            value={{ label: numberPerPage, value: numberPerPage }}
            closeMenuOnSelect
            menuPortalTarget={document.body}
            menuPosition="fixed"
            isMulti={false}
            onChange={(value) => {
              onNumberPerPageChanged(value.value);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Pagination;

import React from 'react';
import { Provider } from 'react-redux';
import styles from './FormDialog.module.css';
import store from '../../../redux/store';

function FormDialog({ title, form, onClose }) {
  return (
    <div data-cy="form-dialog" className={styles.dialog}>
      <span
        data-cy="close-button"
        onClick={onClose}
        className={`material-icons ${styles.closeIcon}`}
      >
        close
      </span>
      <h2 className="subtitle center">{title}</h2>
      <Provider store={store}>{form}</Provider>
    </div>
  );
}

export default FormDialog;

import React from 'react';
import WindowedSelect from 'react-windowed-select';
import { SelectFetch } from 'react-select-fetch';
import axios from 'axios';
import styles from './MultiSelect.module.css';

function Multiselect({
  placeholder,
  options,
  label,
  input,
  disabled,
  async,
  isSingle,
  url,
  onChange,
  key,
  mappingFunc,
  searchField,
  meta: { error, invalid, touched },
}) {
  const customStyles = {
    multiValue: (styles) => ({
      ...styles,
      borderRadius: '8px',
      marginBottom: '3px',
      background: 'var(--color-secondary)',
    }),

    multiValueLabel: (styles) => ({
      ...styles,
      marginBottom: '2px',
      color: 'var(--color-white)',
    }),

    multiValueRemove: (styles) => ({
      ...styles,
      borderRadius: '8px',
      marginLeft: '4px',
      color: 'var(--color-white)',
      transition: 'all linear .1s',
      '&:hover': {
        cursor: 'pointer',
        background: 'var(--color-error)',
        color: 'var(--color-white)',
      },
    }),
    control: (styles) => ({
      ...styles,
      background: 'var(--color-gray)',
    }),
  };

  return (
    <div className={styles.fieldContainer} data-cy={`select-${input.name}`}>
      <label htmlFor={input.name}>{label}</label>
      {async ? (
        <SelectFetch
          {...(key && { key: JSON.stringify(key) })}
          placeholder={placeholder}
          styles={customStyles}
          className={styles.select}
          mapResponse={(response) => {
            return {
              options: response.data.map((item) => ({
                value: item.id,
                label: item.name,
              })),
              hasMore: response.meta.current_page !== response.meta.last_page,
            };
          }}
          value={input.value}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'var(--color-primary-light)',
            },
          })}
          closeMenuOnSelect={isSingle}
          isMulti={!isSingle}
          onChange={(value) => {
            onChange && onChange(value);
            input.onChange(value);
          }}
          url={`https://realestate-api.cases-exore.pro/api${url}/`}
          get={async (url, parameters) => {
            axios.defaults.baseURL =
              'https://realestate-api.cases-exore.pro/api';
            axios.defaults.headers.common['Content-Type'] = 'application/json';
            const response = await axios.get(url, {
              params: {
                page: parameters.page,
                per_page: 10,
                'filters[name]': parameters.search || undefined,
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            });
            if (mappingFunc) {
              response.data.data = response.data.data.map(mappingFunc);
            }
            return response.data;
          }}
          isDisabled={disabled}
        />
      ) : (
        <WindowedSelect
          placeholder={placeholder}
          options={options}
          styles={customStyles}
          className={styles.select}
          value={input.value}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'var(--color-primary-light)',
            },
          })}
          closeMenuOnSelect={isSingle}
          isMulti={!isSingle}
          onChange={(value) => {
            onChange && onChange(value);
            input.onChange(value);
          }}
          isDisabled={disabled}
        />
      )}
      {invalid && touched && (
        <span data-cy="field-error-label" className="field-error-label">
          {error}
        </span>
      )}
    </div>
  );
}

export default Multiselect;

import React from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, Field, formValues } from 'redux-form';
import SNIPPETS from '../../../constants/snippets';
import { getSnippetBySlug } from '../../../helpers/snippets';
import Input from '../../components/Input/Input';
import styles from './MarketSearchForm.module.css';

function MarketSearchForm({ onSubmit, handleSubmit, name }) {
  const snippets = useSelector((state) => state.snippets.snippets);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.MARKETS_PAGE.SEARCH_PLACEHOLDER,
          true
        )}
        type="search"
        name="name"
        onChange={(e) => {
          if (!e.target.value) {
            onSubmit({ name: undefined });
          }
        }}
        component={Input}
      />
      <button disabled={!name} className="button primary-button">
        {getSnippetBySlug(snippets, SNIPPETS.MARKETS_PAGE.SEARCH_BUTTON, true)}
      </button>
    </form>
  );
}

export default reduxForm({
  form: 'market-search-form',
})(formValues('name')(MarketSearchForm));

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from '../redux/actions/auth';

const withAuth = (Component) =>
  function ({ ...properties }) {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getUser());
    }, []);

    return <Component {...properties} />;
  };

export default withAuth;

const ROUTES = {
  AUTH: {
    REGISTER: '/register',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
  },
  USER: {
    HOME: '/home',
    MARKETS: '/markets',
    PROPERTIES: '/properties',
    CREATE_PROPERTY: '/properties/create',
    EDIT_PROPERTY: '/properties/:propertyId/edit',
    PROFILE: '/profile',
    PROPERTY_DETAILS: '/properties/:propertyId',
    PUBLIC_PROPERTY: '/property/:slug',
    DESIRED_MERTICS: '/desired-metrics',
    MARKET_DETAILS: '/markets/:marketId',
  },
  ADMIN: {
    SNIPPETS: '/admin/snippets',
    GROUPS: '/admin/snippet-group',
    CREATE_SNIPPET: '/admin/snippets/create',
    CREATE_SNIPPET_GROUP: '/admin/snippet-group/create',
    HOME: '/admin/home',
    PROFILE: '/admin/profile',
    EDIT_PROFILE: '/admin/profile/:id/edit',
    EDIT_SNIPPET: '/admin/snippets/:id/edit',
    EDIT_SNIPPET_GROUP: '/admin/snippet-group/:id/edit',
    USERS: '/admin/users',
    VARIABLE_EXPENSES: '/admin/variable-expenses',
    CREATE_VARIABLE_EXPENSES: '/admin/variable-expenses/create',
    EDIT_VARIABLE_EXPENSE: '/admin/variable-expenses/:id/edit',
    USER_PROPERTIES: '/admin/users/:userId/properties',
    USER_PROPERTY_DETAILS: '/admin/properties/:propertyId',
    FLUID_DEFAULT_VALUES_BASE_PATH: '/admin/fluid-values',
    FLUID_DEFAULT_VALUES: '/admin/fluid-values/:variableExpenseId',
  },
};

export default ROUTES;

import { SET_SNIPPET_GROUPS } from '../action-types/data';

const initialState = {
  groups: null,
  total: null,
};

const snippetGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNIPPET_GROUPS:
      return {
        ...state,
        groups: action.snippetGroups,
        total: action.count,
      };
    default:
      return state;
  }
};

export default snippetGroupsReducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../../utils/validators';
import Input from '../../components/Input/Input';
import { getSnippetBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';

function GeneralProfileInfoForm({ handleSubmit, onSubmit, form }) {
  const snippets = useSelector((state) => state.snippets.snippets);
  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values, form))}>
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.NAME_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="text"
        name="name"
        label={getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.NAME_LABEL)}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.PROFILE_PAGE.EMAIL_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="email"
        name="email"
        label={getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.EMAIL_LABEL)}
        component={Input}
      />
      <button type="submit" className="button primary-button">
        {getSnippetBySlug(snippets, SNIPPETS.PROFILE_PAGE.GENERAL_INFO_BUTTON)}
      </button>
    </form>
  );
}

export default reduxForm({
  form: 'general-profile-info-form',
  enableReinitialize: true,
})(GeneralProfileInfoForm);

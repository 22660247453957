import React, { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import withAuth from '../../../hocs/withAuth';
import withProvider from '../../../hocs/withProvider';
import Loader from '../Loader/Loader';

import ROLES from '../../../constants/roles';
import { getSnippets } from '../../../redux/actions/snippets';
import AdminPrivatePaths from '../AdminPrivatePaths/AdminPrivatePaths';
import PrivatePaths from '../PrivatePaths/PrivatePaths';
import PublicPaths from '../PublicPaths/PublicPaths';

function App() {
  const isLoading = useSelector((state) => state.api.isLoading);
  const user = useSelector((state) => state.auth.user);
  const generalMessage = useSelector(
    (state) => state.formSubmit.generalMessage
  );
  const dispatch = useDispatch();

  const snippets = useSelector((state) => state.snippets.snippets);

  useEffect(() => {
    dispatch(getSnippets({ pagination: 0 }));
  }, []);

  useEffect(() => {
    if (generalMessage) {
      if (generalMessage.type === 'error') {
        toast.error(generalMessage.message);
      } else if (generalMessage.type === 'success') {
        toast.success(generalMessage.message);
      }
    }
  }, [generalMessage]);

  // user !== undefined means "if user has been prompted"
  return (
    <LoadingOverlay spinner={<Loader />} active={isLoading}>
      <ToastContainer />
      {user !== undefined && snippets && (
        <Router>
          {!user ? (
            <PublicPaths />
          ) : user.role[0].name === ROLES.CLIENT ? (
            <PrivatePaths />
          ) : (
            <AdminPrivatePaths />
          )}
        </Router>
      )}
    </LoadingOverlay>
  );
}

export default withProvider(withAuth(App));

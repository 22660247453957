import { SET_VARIABLE_EXPENSES } from '../action-types/data';

const initialState = {
  variableExpenses: null,
  total: null,
};

const variableExpensesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VARIABLE_EXPENSES:
      return {
        ...state,
        variableExpenses: action.variableExpenses,
        total: action.count,
      };
    default:
      return state;
  }
};

export default variableExpensesReducer;

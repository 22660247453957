import {
  ADD_VARIABLE_COST,
  REMOVE_VARIABLE_COST,
  SET_CITIES,
  SET_CLIENTS,
  SET_STATES,
  SET_VARIABLE_COSTS,
} from '../action-types/data';

const initialState = {
  states: null,
  cities: null,
  variableCosts: null,
  clients: [],
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATES:
      return {
        ...state,
        states: action.data,
      };
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.data,
      };
    case SET_VARIABLE_COSTS:
      return {
        ...state,
        variableCosts: [...action.data.map((cost) => ({
          ...cost,
          available: true,
        }))],
      };
    case REMOVE_VARIABLE_COST:
      return {
        ...state,
        variableCosts: state.variableCosts.map((cost) => {
          if (cost.key !== action.value) {
            return cost;
          }
          return { ...cost, available: false };
        }),
      };
    case ADD_VARIABLE_COST:
      return {
        ...state,
        variableCosts: state.variableCosts.map((cost) => {
          if (cost.key !== action.value) {
            return cost;
          }
          return { ...cost, available: true };
        }),
      };
    case SET_CITIES:
      return {
        ...state,
        cities: action.data.reduce(
          (accumulator, current) => accumulator.concat(current.cities),
          []
        ),
      };
    default:
      return state;
  }
};

export default dataReducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import ENDPOINTS from '../../../constants/endpoints';
import SNIPPETS from '../../../constants/snippets';
import { getSnippetBySlug } from '../../../helpers/snippets';
import { numberOrEmpty } from '../../../utils/validators';
import DatePicker from '../../components/DatePicker/DatePicker';
import Input from '../../components/Input/Input';
import Multiselect from '../../components/MultiSelect/MultiSelect';
import styles from './PropertyFilterForm.module.css';

const validate = (values) => {
  const errors = {};
  if (
    (values.gross_yield_from && !values.gross_yield_to) ||
    (!values.gross_yield_from && values.gross_yield_to)
  ) {
    errors.gross_yield_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (+values.gross_yield_from > +values.gross_yield_to) {
    errors.gross_yield_error = 'From value must be less or equal than to value';
  }
  if (
    (values.cap_rate_from && !values.cap_rate_to) ||
    (!values.cap_rate_from && values.cap_rate_to)
  ) {
    errors.cap_rate_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (+values.cap_rate_from > +values.cap_rate_to) {
    errors.cap_rate_error = 'From value must be less or equal than to value';
  }
  if (
    (values.cash_on_cash_return_from && !values.cash_on_cash_return_to) ||
    (!values.cash_on_cash_return_from && values.cash_on_cash_return_to)
  ) {
    errors.cash_on_cash_return_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (
    +values.cash_on_cash_return_from > +values.cash_on_cash_return_to
  ) {
    errors.cash_on_cash_return_error =
      'From value must be less or equal than to value';
  }

  if (
    (values.created_at_from && !values.created_at_to) ||
    (!values.created_at_from && values.created_at_to)
  ) {
    errors.created_at_error =
      'Please fill both from and to values or leave this filter empty';
  } else if (values.created_at_from > values.created_at_to) {
    errors.created_at_error = 'From value must be less or equal than to value';
  }
  return errors;
};

function PropertyFilterForm({ onSubmit, handleSubmit, change }) {
  const snippets = useSelector((state) => state.snippets.snippets);

  const onLastDaysSelected = (days) => {
    const d = new Date();
    d.setDate(d.getDate() - days + 1);
    change('created_at_from', d);
    change('created_at_to', new Date());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      {/* <Field
          placeholder={getSnippetBySlug(snippets, SNIPPETS.PROPERTY_FILTER_FORM.RENTAL_TYPE_PLACEHOLDER, true)}
          label={getSnippetBySlug(snippets, SNIPPETS.PROPERTY_FILTER_FORM.RENTAL_TYPE_LABEL)}
          name="rental_type"
          options={Object.entries(RENTAL_TYPES).map((type) => ({
            label: type[1],
            value: type[0],
          }))}
          component={Multiselect}
        /> */}
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.PROPERTY_FILTER_FORM.MARKET_PLACEHOLDER,
          true
        )}
        name="market_id"
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.PROPERTY_FILTER_FORM.MARKET_LABEL
        )}
        async
        url={`${ENDPOINTS.MARKETS.GET_MARKETS}`}
        component={Multiselect}
      />
      <div>
        <h4>Gross Yield</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="From"
            name="gross_yield_from"
            component={Input}
          />
          <span>To</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="To"
            name="gross_yield_to"
            component={Input}
          />
        </div>
        <Field type="hidden" name="gross_yield_error" component={Input} />
      </div>
      <div>
        <h4>Cap Rate</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="From"
            name="cap_rate_from"
            component={Input}
          />
          <span>To</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="To"
            name="cap_rate_to"
            component={Input}
          />
        </div>
        <Field type="hidden" name="cap_rate_error" component={Input} />
      </div>
      <div>
        <h4>Cash-on-Cash Return</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="From"
            name="cash_on_cash_return_from"
            component={Input}
          />
          <span>To</span>
          <Field
            validate={[numberOrEmpty]}
            placeholder="To"
            name="cash_on_cash_return_to"
            component={Input}
          />
        </div>
        <Field
          type="hidden"
          name="cash_on_cash_return_error"
          component={Input}
        />
      </div>
      <div>
        <h4>Сreation Date</h4>
        <div className={styles.range}>
          <span>From</span>
          <Field
            name="created_at_from"
            component={DatePicker}
            placeholder="Select Date"
          />
          <span>To</span>
          <Field
            name="created_at_to"
            component={DatePicker}
            placeholder="Select Date"
          />
        </div>
        <div className={styles.dateButtons}>
          <button
            type="button"
            className="button -thin outlined-primary-button"
            onClick={() => onLastDaysSelected(7)}
          >
            Last 7 days
          </button>
          <button
            type="button"
            className="button -thin outlined-primary-button"
            onClick={() => onLastDaysSelected(30)}
          >
            Last 30 days
          </button>
          <button
            type="button"
            className="button -thin outlined-primary-button"
            onClick={() => onLastDaysSelected(90)}
          >
            Last 90 days
          </button>
        </div>
        <Field type="hidden" name="created_at_error" component={Input} />
      </div>
      <button className="button primary-button center">Apply Filters</button>
    </form>
  );
}

export default reduxForm({
  form: 'property-filter-form',
  validate,
})(PropertyFilterForm);

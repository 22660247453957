import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import styles from './TextArea.module.css';

function Textarea({
  placeholder,
  type,
  label,
  input,
  disabled,
  className,
  initialState,
  editorClassName,
  isHTML,
  meta: { error, invalid, touched },
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (initialState) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(initialState))
        )
      );
    }
  }, [initialState]);

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  return (
    <div className={`${styles.fieldContainer} ${className || ''}`}>
      {label && <label htmlFor={input.name}>{label}</label>}
      {isHTML ? (
        <textarea
          {...input}
          id={input.name}
          disabled={disabled}
          placeholder={placeholder}
          className={`${styles.textarea} ${editorClassName || ''}`}
        />
      ) : (
        <Editor
          editorState={editorState}
          {...input}
          editorClassName={`${styles.editor} ${editorClassName || ''}`}
          wrapperClassName={styles.wrapper}
          id={input.name}
          placeholder={placeholder}
          onChange={() => {}}
          onEditorStateChange={(value) => {
            handleEditorChange(value);
            let html = draftToHtml(convertToRaw(value.getCurrentContent()));
            if (/^(<(?!\/)[^>]+>)+(<\/[^>]+>)+$/.test(html.trim())) {
              html = '';
            }
            input.onChange(html);
          }}
          type={type}
          disabled={disabled}
        />
      )}
      {invalid && touched && (
        <span data-cy="field-error-label" className="field-error-label">
          {error}
        </span>
      )}
    </div>
  );
}

export default Textarea;

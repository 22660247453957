import PropTypes from 'prop-types';
import React from 'react';
import styles from './NumberInput.module.css';

function NumberInput({
  integer = false,
  placeholder,
  type,
  label,
  input,
  change,
  suffix,
  step,
  onBlurFormatter,
  onFocusFormatter,
  meta: { error, invalid, touched },
}) {
  return (
    <div className={styles.fieldContainer}>
      <label htmlFor={input.name}>{label}</label>
      <div className={styles.inputContainer}>
        <button
          type="button"
          className={styles.minusButton}
          onClick={() => change(input.name, +input.value - (step || 1))}
        >
          -
        </button>
        <div className={styles.input}>
          <input
            {...input}
            id={input.name}
            className={suffix && input.value ? styles.withSuffix : ''}
            placeholder={placeholder}
            onFocus={(e) => {
              input.onFocus(onFocusFormatter ? onFocusFormatter(e) : e);
            }}
            onBlur={(e) => {
              input.onBlur(
                onBlurFormatter ? onBlurFormatter(e.target.value) : e
              );
            }}
            onChange={(event) => {
              if (
                /^[+-]?(\d+\.?\d*|\.\d+)$/.test(event.target.value) ||
                !event.target.value
              ) {
                if (integer) {
                  change(input.name, +event?.target?.value);
                } else {
                  input.onChange(event);
                }
              }
            }}
            type={type}
          />
          {suffix && <span>{suffix}</span>}
        </div>
        <button
          type="button"
          className={styles.plusButton}
          onClick={() => change(input.name, +input.value + (step || 1))}
        >
          +
        </button>
      </div>
      {invalid && touched && <span className="field-error-label">{error}</span>}
    </div>
  );
}

NumberInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default NumberInput;

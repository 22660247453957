import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import { confirmAlert } from 'react-confirm-alert';
import ROUTES from '../../../../constants/routes';
import { deleteSnippet, getSnippets } from '../../../../redux/actions/snippets';
import Pagination from '../../../components/Pagination/Pagination';
import styles from './SnippetsPage.module.css';
import Dialog from '../../../components/Dialog/Dialog';
import SnippetFilterForm from '../../../forms/admin/SnippetFilterForm/SnippetFilterForm';
import FormDialog from '../../../components/FormDialog/FormDialog';
import SnippetSearchForm from '../../../forms/admin/SnippetSearchForm/SnippetSearchForm';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';
import { usePrevious } from '../../../../hooks/usePrevious';

const ITEMS_PER_PAGE = 40;

function SnippetsPage() {
  const currentItems = useSelector((state) => state.snippets.snippets);
  const location = useLocation();
  const [filters, setFilters] = useState(
    location.state && location.state.filters ? location.state.filters : {}
  );
  const total = useSelector((state) => state.snippets.total);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState(
    +new URLSearchParams(location.search).get('page') || 0
  );
  const previous = usePrevious({ filters, selectedPage });

  const getSnippetsData = (page, filters) => {
    dispatch(
      getSnippets({ page: page + 1, per_page: ITEMS_PER_PAGE, ...filters })
    );
  };

  const handlePageClick = (event) => {
    setSelectedPage(event.selected);
    navigate({
      pathname: window.location.pathname,
      search: `?page=${event.selected}`,
    });
  };

  const showSnippet = (snippet) => {
    swal({
      content: <div dangerouslySetInnerHTML={{ __html: snippet }} />,
    });
  };

  useEffect(() => {
    if (
      previous &&
      selectedPage === previous.selectedPage &&
      filters !== previous.filters &&
      selectedPage !== 0
    ) {
      setSelectedPage(0);
      navigate(location.pathname);
    } else {
      const filtersData = {};
      if (filters) {
        for (const [key, value] of Object.entries(filters)) {
          if (Array.isArray(value) && value.length > 0) {
            filtersData[`filters[${key}]`] = value
              .map((item) => item.value)
              .join(',');
          } else {
            filtersData[`filters[${key}]`] = value;
          }
        }
      }
      getSnippetsData(selectedPage, filtersData);
    }
  }, [selectedPage, filters]);

  const editSnippet = (id) => {
    navigate(`${ROUTES.ADMIN.SNIPPETS}/${id}/edit`, {
      state: {
        pathname: location.pathname + location.search,
        filters,
      },
    });
  };

  const removeSnippet = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <Dialog
          title="Delete Snippet"
          text="Are you sure you want to delete this snippet?"
          buttons={[
            {
              type: 'button',
              text: 'Delete',
              onClick: () => {
                const filtersData = {};
                if (filters) {
                  for (const [key, value] of Object.entries(filters)) {
                    if (Array.isArray(value) && value.length > 0) {
                      filtersData[`filters[${key}]`] = value
                        .map((item) => item.value)
                        .join(',');
                    } else {
                      filtersData[`filters[${key}]`] = value;
                    }
                  }
                }
                dispatch(
                  deleteSnippet(id, () =>
                    dispatch(
                      getSnippets({
                        page: selectedPage + 1,
                        per_page: ITEMS_PER_PAGE,
                        ...filtersData,
                      })
                    )
                  )
                );
                onClose();
              },
              className: 'secondary-button',
            },
            {
              type: 'button',
              text: 'Cancel',
              onClick: onClose,
              className: 'secondary-button',
            },
          ]}
        />
      ),
    });
  };

  const openFilterDialog = () => {
    confirmAlert({
      overlayClassName: 'overlay',
      closeOnClickOutside: false,
      customUI: ({ onClose }) => (
        <FormDialog
          onClose={onClose}
          title="Add Filters"
          form={
            <SnippetFilterForm
              initialValues={filters}
              onSubmit={(values) => {
                setFilters({ ...filters, ...values });
                onClose();
              }}
            />
          }
        />
      ),
    });
  };

  return (
    <div>
      <div className={styles.header}>
        <h2 className="subtitle">Snippets -- {total}</h2>
        <div>
          <button
            type="button"
            onClick={() => {
              openFilterDialog();
            }}
            className="button withImg primary-button -thin"
          >
            <span className="material-icons">filter_alt</span>
            <span>Filter</span>
          </button>
          <button
            type="button"
            onClick={() => {
              navigate(ROUTES.ADMIN.CREATE_SNIPPET);
            }}
            className="button withImg primary-button -thin"
          >
            <span className="material-icons">add</span>
            <span>Create New Snippet</span>
          </button>
        </div>
      </div>
      <Breadcrumbs />
      <SnippetSearchForm
        initialValues={{ search: filters.search || '' }}
        onSubmit={(values) => {
          setFilters({ ...filters, ...values });
        }}
      />
      {total === 0 ? (
        'No snippets yet'
      ) : (
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>Slug</th>
                <th>Text</th>
                <th>Description</th>
                <th>Group</th>
                <th>Is Active?</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.slug}</td>
                      <td>
                        <button
                          className="button link"
                          type="button"
                          onClick={() => showSnippet(item.name)}
                        >
                          Show Snippet
                        </button>
                      </td>
                      <td>{item.description || '--'}</td>
                      <td>{item.group.name}</td>
                      <td>{item.is_active ? 'YES' : 'NO'}</td>
                      <td>
                        <div>
                          <button
                            type="button"
                            className="button action-button"
                            onClick={() => editSnippet(item.id)}
                          >
                            <span className="material-icons">edit</span>
                          </button>
                          <button
                            type="button"
                            className="button action-button"
                            onClick={() => removeSnippet(item.id)}
                          >
                            <span className="material-icons">delete</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        onPageChange={handlePageClick}
        selectedPage={selectedPage}
        pageCount={Math.ceil(total / ITEMS_PER_PAGE)}
      />
    </div>
  );
}

export default SnippetsPage;

import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { confirmAlert } from 'react-confirm-alert';
import avatarPlaceholder from 'public/assets/images/avatar-placeholder.jpg';
import airdnaLogo from 'public/assets/images/AirDNA-logo.png';
import html2pdf from 'html2pdf.js';
import {
  Bar,
  BarChart,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  ReferenceLine,
  YAxis,
} from 'recharts';
import {
  deleteProperty,
  getPropertyBySlug,
  getPropertyDetails,
  getPublicLink,
} from '../../../redux/actions/properties';
import styles from './PropertyDetailsPage.module.css';
import ROUTES from '../../../constants/routes';
import Dialog from '../../components/Dialog/Dialog';
import {
  formatDollarValues,
  formatPercentValues,
  formatNumericValueWithRounding,
} from '../../../utils/formatters';
import { getSnippetBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';
import { getMonthNameByNumber } from '../../../utils/date';
import CURRENCY_TYPES from '../../../constants/currency';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function YearTooltip({ active, payload }) {
  if (active && payload && payload.length > 0) {

    const fieldName = (name) => {
      let field = '';

      switch(name) {
        case 'mounthly_profit_loss':
          field = 'Profit/Loss';
          break;
        case 'total_expenses':
          field = 'Expenses';
          break;
        case 'total_revenue':
          field = 'Revenue';
          break;
        default: field = name;
      };

      return field;
    }

    return (
      <div className={styles.tooltip}>
        <p><b>{payload?.[0]?.payload?.name || '-'}</b></p>
        <p style={{ color: payload?.[0]?.color || '#000' }}>
          <span>{fieldName(payload?.[0]?.name)}: </span>
          <span>{ USDollar.format(payload?.[0]?.value || 0)}</span>
        </p>
        <p style={{ color: payload?.[1]?.color || '#000' }}>
          <span>{fieldName(payload?.[1]?.name)}: </span>
          <span>{ USDollar.format(payload?.[1]?.value || 0)}</span>
        </p>
        <p style={{ color: payload?.[2]?.color || '#000' }}>
          <span>{fieldName(payload?.[2]?.name)}: </span>
          <span>{ USDollar.format(payload?.[2]?.value || 0)}</span>
        </p>
      </div>
    );
  }

  return null;
}

function LegendTooltip({ active, payload }) {
  if (payload && payload.length > 0) {

    const fieldName = (name) => {
      let field = '';

      switch(name) {
        case 'mounthly_profit_loss':
          field = 'Profit/Loss';
          break;
        case 'total_expenses':
          field = 'Expenses';
          break;
        case 'total_revenue':
          field = 'Revenue';
          break;
        default: field = name;
      };

      return field;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <p style={{ color: payload?.[0]?.color || '#000', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: 15, height: 15, backgroundColor: payload?.[0]?.color || '#000', marginRight: 5 }}></div>
          <span>{fieldName(payload?.[0]?.dataKey)}</span>
        </p>
        <p style={{ color: payload?.[1]?.color || '#000', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: 15, height: 15, backgroundColor: payload?.[1]?.color || '#000', marginRight: 5 }}></div>
          <span>{fieldName(payload?.[1]?.dataKey)}</span>
        </p>
        <p style={{ color: payload?.[2]?.color || '#000', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: 15, height: 15, backgroundColor: payload?.[2]?.color || '#000', marginRight: 5 }}></div>
          <span>{fieldName(payload?.[2]?.dataKey)}</span>
        </p>
      </div>
    );
  }

  return null;
}


function OccupancyGraphTooltip({ active, payload }) {
  if (active && payload && payload.length > 0) {
    return (
      <div className={styles.tooltip}>
        <p className={styles.date}>{payload[0].payload.date}</p>
        <p className={styles.value}>{formatPercentValues(payload[0].value)}</p>
      </div>
    );
  }

  return null;
}

const xPriceFormatter = (value, chartData) => {
  let max = 0;
  let min = 0;

  chartData.forEach(element => {
    Object.keys(element).forEach(el => {
      if (typeof element[el] === 'number') {
        if (element[el] > max) {
          max = element[el];
        }
        if (element[el] < min) {
          min = element[el];
        }
      }
    });
  });

  if (value > 0 && value * 3.5 < max) {
    return ''
  }

  if (value < 0 && value * 3.5 > min) {
    return ''
  }

  return `${USDollar.format(value)}`;
}

const MounthFormatter2 = (value) => {
  const mounth = value.split(' ')[0];

  return mounth
}

const MounthFormatter = (value) => {
  const mounth = value.split(' ')[0];
  const year = value.split(' ')[1].substr(-2);

  switch(mounth) {
    case 'May' : 
      return `May`;
    case 'June' : 
      return `Jun`;
    case 'July' : 
      return `Jul`;
    case 'August' : 
      return `Aug`;
    case 'September' : 
      return `Sep`;
    case 'October' : 
      return `Oct`;
    case 'November' : 
      return `Nov`;
    case 'December' : 
      return `Dec`;
    case 'January' : 
      return `Jan`;
    case 'February' : 
      return `Feb`;
    case 'March' : 
      return `Mar`;
    case 'April' : 
      return `Apr`;
    default: 
      return value;
  }
}

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul>
      {
        payload.map((entry, index) => (
          <li key={`item-${index}`}>{entry.value}123</li>
        ))
      }
    </ul>
  );
}

const renderColorfulLegendText = (value, entry) => {
  const { color } = entry;

  let field = '';

  switch(value) {
    case 'mounthly_profit_loss':
      field = 'Profit/Loss';
      break;
    case 'total_expenses':
      field = 'Expenses';
      break;
    case 'total_revenue':
      field = 'Revenue';
      break;
    default: field = value;
  }

  if (typeof value === 'string') {
    return <span style={{ color }}>{field}</span>;
  }

  return  <span style={{ color }}>{value}</span>;
};

function ADRGraphTooltip({ active, payload }) {
  if (active && payload && payload.length > 0) {
    return (
      <div className={styles.tooltip}>
        <p className={styles.date}>{payload[0].payload.date}</p>
        <p className={styles.value}>{formatDollarValues(payload[0].value)}</p>
      </div>
    );
  }

  return null;
}

function PropertyDetails({ property, pdf }) {
  const user = useSelector((state) => state.auth.user);
  const snippets = useSelector((state) => state.snippets.snippets);

  const overviewData = useMemo(() => {
    if (!property.overview) {
      return null;
    }
    const overviewData = [];
    for (const [year, overviewYearData] of Object.entries(property.overview)) {
      for (const data of overviewYearData) {
        overviewData.push({
          averageDailyRate: data.average_daily_rate,
          projectedOccupancy: data.projected_occupancy,
          date: `${getMonthNameByNumber(data.month)} ${year}`,
        });
      }
    }
    return overviewData;
  }, [property.overview]);

  const cashRule =
    user && user.color_data_cash_return && user.color_data_cash_return[0];
  const grossUpRule =
    user && user.color_data_gross_yield && user.color_data_gross_yield[0];

  const getDate = (date) => {
    const parts = date.split('-');
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  };

  return (
    <div className={`${styles.main} ${pdf ? styles.pdfVersion : ''}`}>
      {pdf && (
        <h2 className="subtitle">
          {getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTY_DETAILS_PAGE.PROPERTY_ANALYSIS_TITLE
          )}
        </h2>
      )}
      <div className={styles.mainInfo}>
        {property && property.photos && property.photos.length > 0 ? (
          !pdf ? (
            <ImageGallery
              thumbnailPosition="left"
              showPlayButton={false}
              renderLeftNav={(onClick, disabled) => (
                <button
                  type="button"
                  className={styles.leftButton}
                  onClick={onClick}
                  disabled={disabled}
                >
                  <span className="material-icons">chevron_left</span>
                </button>
              )}
              renderRightNav={(onClick, disabled) => (
                <button
                  type="button"
                  className={styles.rightButton}
                  onClick={onClick}
                  disabled={disabled}
                >
                  <span className="material-icons">chevron_right</span>
                </button>
              )}
              items={[
                ...property.photos.map((photo) => ({
                  original: photo.src,
                  thumbnail: photo.thumb,
                  originalClass: styles.original,
                  thumbnailClass: styles.thumbnail,
                })),
              ]}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <img className={styles.pdfImg} src={property.photos[0].src} />
              <div
                className={styles.generatedBy}
                style={{ marginTop: 0, marginLeft: 20 }}
              >
                <p>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROPERTY_DETAILS_PAGE.REPORT_GENERATED_BY_LABEL
                  )}
                </p>
                <div className={styles.info}>
                  <table className={styles.table}>
                    <tr>
                      <td style={{ paddingRight: 0 }}>
                        {property.report_generated_by.name}
                      </td>
                      <td>
                        {getSnippetBySlug(
                          snippets,
                          SNIPPETS.PROPERTY_DETAILS_PAGE.PHONE_LABEL
                        )}
                      </td>
                      <td>{property.report_generated_by.phone}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingRight: 0 }}>
                        {property.report_generated_by.job_title}
                      </td>
                      <td>
                        {getSnippetBySlug(
                          snippets,
                          SNIPPETS.PROPERTY_DETAILS_PAGE.EMAIL_LABEL
                        )}
                      </td>
                      <td>{property.report_generated_by.email}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingRight: 0 }}>
                        {property.report_generated_by.location}
                      </td>
                      <td>
                        {getSnippetBySlug(
                          snippets,
                          SNIPPETS.PROPERTY_DETAILS_PAGE.WEBSITE_LABEL
                        )}
                      </td>
                      <td>{property.report_generated_by.site}</td>
                    </tr>
                  </table>
                  <img
                    src={
                      property.report_generated_by.avatar || avatarPlaceholder
                    }
                    alt="Profile photo"
                  />
                </div>
              </div>
            </div>
          )
        ) : (
          !pdf && <p className={styles.noImage}>No image</p>
        )}
        <div className={styles.table}>
          <h3>
            {getSnippetBySlug(
              snippets,
              SNIPPETS.PROPERTY_DETAILS_PAGE.DETAILS_SUBTITLE
            )}
          </h3>
          <table>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.MARKET_NAME_LABEL
                )}
              </td>
              <td>{property.market_name}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.BEDROOMS_LABEL
                )}
              </td>
              <td>{property.bedrooms}</td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.ADDRESS_LABEL
                )}
              </td>
              <td>{property.address}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.BATHROOMS_LABEL
                )}
              </td>
              <td>{property.bathrooms}</td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.CITY_LABEL
                )}
              </td>
              <td>{property.city}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.SQUARE_FOOTAGE_LABEL
                )}
              </td>
              <td>
                {property.square_feet
                  ? formatNumericValueWithRounding(property.square_feet)
                  : '--'}
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.STATE_LABEL
                )}
              </td>
              <td>{property.state}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.LIST_PRICE_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.list_price)}</td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.ZIP_LABEL
                )}
              </td>
              <td>{property.zip}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.PURCHASE_PRICE_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.purchase_price)}</td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.CREATION_DATE_LABEL
                )}
              </td>
              <td>{getDate(property.property_created)}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.PRICE_PER_FEET_LABEL
                )}
              </td>
              <td>
                {property.square_feet
                  ? formatDollarValues(property.price_per_sq_feet)
                  : '--'}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className={styles.additionalInfo}>
        {property.parsing_url && (
          <div className={styles.propertyLink}>
            <p className={styles.zillow}>
              <span className={styles.subtitle}>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.LINK_LABEL,
                  true
                )}
              </span>
              <a
                className="link"
                href={property.parsing_url}
                target="_blank"
                rel="noreferrer"
              >
                {property.parsing_url}
              </a>
            </p>
            <p className="copyright">
              Zillow, Inc., 2006-2016. Use is subject to 
              <a
                href="https://www.zillow.com/z/corp/terms/"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{' '}
              <a
                href="https://www.zillow.com/z/zestimate/"
                target="_blank"
                rel="noreferrer"
              >
                What's a Zestimate?
              </a>
            </p>
          </div>
        )}
        {property.property_notes && (
          <p>
            <span className={styles.subtitle}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.PROPERTY_DETAILS_PAGE.NOTES_LABEL,
                true
              )}
            </span>
            {property.property_notes}
          </p>
        )}
      </div>
      <div className={styles.investmentAnalysis}>
        <h3>
          {getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTY_DETAILS_PAGE.INVESTMENT_ANANYSIS_SUBTITLE
          )}
        </h3>
        <div className={styles.metrics}>
          <span className={styles.subtitle}>
            {getSnippetBySlug(
              snippets,
              SNIPPETS.PROPERTY_DETAILS_PAGE.PROJECTED_RETURN_METRICS_LABEL
            )}
          </span>
          <div className={styles.metric}>
            <span className={styles.title}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.PROPERTY_DETAILS_PAGE.CAP_RATE_LABEL
              )}
            </span>
            <span
              className={`${
                property.cap_rate < 5
                  ? 'filled-error'
                  : property.cap_rate < 7
                  ? 'filled-warning'
                  : 'filled-success'
              } breadcrumb`}
            >
              {!property.cap_rate && property.cap_rate !== 0
                ? '--'
                : formatPercentValues(property.cap_rate)}
            </span>
          </div>
          <div className={styles.metric}>
            <span className={styles.title}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.PROPERTY_DETAILS_PAGE.GROSS_YIELD_LABEL
              )}
            </span>
            <span
              className={`${
                !grossUpRule
                  ? ''
                  : property.gross_yield < +grossUpRule.red
                  ? 'filled-error'
                  : property.gross_yield < +grossUpRule.yellow
                  ? 'filled-warning'
                  : 'filled-success'
              } breadcrumb`}
            >
              {!property.gross_yield && property.gross_yield !== 0
                ? '--'
                : formatPercentValues(property.gross_yield)}
            </span>
          </div>
          <div className={styles.metric}>
            <span className={styles.title}>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.PROPERTY_DETAILS_PAGE.CASH_RETURN_LABEL
              )}
            </span>
            <span
              className={`${
                !cashRule
                  ? ''
                  : property.cash_on_cash_return < +cashRule.red
                  ? 'filled-error'
                  : property.cash_on_cash_return < +cashRule.yellow
                  ? 'filled-warning'
                  : 'filled-success'
              } breadcrumb`}
            >
              {!property.cash_on_cash_return &&
              property.cash_on_cash_return !== 0
                ? '--'
                : formatPercentValues(property.cash_on_cash_return)}
            </span>
          </div>
        </div>
        <div className={styles.acquisitionDetails}>
          <table className={styles.table}>
            <tr>
              <td colSpan={4}>
                <h4>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROPERTY_DETAILS_PAGE.ACQUISITION_DETAILS_SUBTITLE
                  )}
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.PURCHASE_PRICE_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.purchase_price)}</td>
              <td className="bold" style={{ width: 250 }}>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.FURNISHING_BUDGET_LABEL
                )}
              </td>
              <td style={{ width: 100 }}>{formatDollarValues(property.furnishing_budget)}</td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.DOWN_PAYMENT_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.down_payment)}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.TOTAL_STARTUP_COSTS_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.total_startup_costs)}</td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.CLOSING_COSTS_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.closing_costs)}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.INTEREST_RATE_LABEL
                )}
              </td>
              <td>{formatPercentValues(property.interest_rate)}</td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.REHAB_BUDGET_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.rehub_budget)}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.MORTGAGE_PAYMENT_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.project_mortgage_payment)}</td>
            </tr>
            <tr>
              <td colSpan={4}>
                <h4>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROPERTY_DETAILS_PAGE.PROJECTIONS_SUBTITLE
                  )}
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.GROSS_INCOME_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.gross_income)}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.NET_OPERATING_INCOME_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.net_operating_income)}</td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.ANNUAL_OPERATING_EXPENSES_LABEL
                )}
              </td>
              <td>
                {formatDollarValues(property.annual_operating_expenses, true)}
              </td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.INTEREST_EXPENSE_LABEL
                )}
              </td>
              <td>
                {formatDollarValues(property.interest_expense_on_debt, true)}
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.PROPERTY_MANAGEMENT_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.property_management_fee)}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.PRINCIPAL_REPAYMENT_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.principal_repayment, true)}</td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.CAPITAL_EXPENDITURES_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.capital_expenditures, true)}</td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.ANNUAL_CASH_FLOW_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.annual_cash_flow)}</td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.REAL_ESTATE_TAXES_LABEL
                )}
              </td>
              <td>{formatDollarValues(property.real_estate_taxes, true)}</td>
            </tr>
          </table>
        </div>
        {pdf ? (
          <div>
            {property.expenses.custom_expenses &&
              property.expenses.custom_expenses.length > 0 && (
                <div className={styles.variableExpenses}>
                  <h4>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.PROPERTY_DETAILS_PAGE.VARIABLE_EXPENSES_TITLE
                    )}
                  </h4>
                  <table style={{ width: '100%' }}>
                    <tr>
                      <th>
                        {getSnippetBySlug(
                          snippets,
                          SNIPPETS.PROPERTY_DETAILS_PAGE
                            .VARIABLE_EXPENSES_TABLE_TITLE
                        )}
                      </th>
                      <th>
                        {getSnippetBySlug(
                          snippets,
                          SNIPPETS.PROPERTY_DETAILS_PAGE
                            .VARIABLE_EXPENSES_TABLE_MONTHLY
                        )}
                      </th>
                      <th>
                        {getSnippetBySlug(
                          snippets,
                          SNIPPETS.PROPERTY_DETAILS_PAGE
                            .VARIABLE_EXPENSES_TABLE_ANNUAL
                        )}
                      </th>
                    </tr>
                    {property.expenses.custom_expenses
                      .filter(
                        (expense) =>
                          expense.measurements === CURRENCY_TYPES.DOLLAR
                      )
                      .map((expense, index) => (
                        <tr key={index}>
                          <td>{expense.name}</td>
                          <td style={{ width: '147px' }}>
                            {formatDollarValues(expense.value)}
                          </td>
                          <td style={{ width: '147px' }}>
                            {formatDollarValues(Math.round(expense.value) * 12)}
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
              )}
          </div>
        ) : (
          <div className='wrap'>
            <div className={styles.variableExpensesWrapper} >
              {property.expenses.custom_expenses &&
                property.expenses.custom_expenses.length > 1 && (
                  <div className={styles.variableExpenses}>
                    <h4>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTY_DETAILS_PAGE.VARIABLE_EXPENSES_TITLE
                      )}
                    </h4>
                    <table style={{ width: '100%' }}>
                      <tr>
                        <th>
                          {getSnippetBySlug(
                            snippets,
                            SNIPPETS.PROPERTY_DETAILS_PAGE
                              .VARIABLE_EXPENSES_TABLE_TITLE
                          )}
                        </th>
                        <th>
                          {getSnippetBySlug(
                            snippets,
                            SNIPPETS.PROPERTY_DETAILS_PAGE
                              .VARIABLE_EXPENSES_TABLE_MONTHLY
                          )}
                        </th>
                        <th>
                          {getSnippetBySlug(
                            snippets,
                            SNIPPETS.PROPERTY_DETAILS_PAGE
                              .VARIABLE_EXPENSES_TABLE_ANNUAL
                          )}
                        </th>
                      </tr>
                      {property.expenses.custom_expenses
                        .slice(
                          0,
                          Math.floor(property.expenses.custom_expenses.length / 2)
                        )
                        .filter(
                          (expense) =>
                            expense.measurements === CURRENCY_TYPES.DOLLAR
                        )
                        .map((expense, index) => (
                          <tr key={index}>
                            <td>{expense.name}</td>
                            <td style={{ width: '147px' }}>
                              {formatDollarValues(expense.value)}
                            </td>
                            <td style={{ width: '147px' }}>
                              {formatDollarValues(Math.round(expense.value) * 12)}
                            </td>
                          </tr>
                        ))}
                    </table>
                  </div>
                )}
              {property.expenses.custom_expenses &&
                property.expenses.custom_expenses.length > 0 && (
                  <div className={styles.variableExpenses}>
                    <h4>
                      {getSnippetBySlug(
                        snippets,
                        SNIPPETS.PROPERTY_DETAILS_PAGE.VARIABLE_EXPENSES_TITLE
                      )}
                    </h4>
                    <table style={{ width: '100%' }}>
                      <tr>
                        <th>
                          {getSnippetBySlug(
                            snippets,
                            SNIPPETS.PROPERTY_DETAILS_PAGE
                              .VARIABLE_EXPENSES_TABLE_TITLE
                          )}
                        </th>
                        <th>
                          {getSnippetBySlug(
                            snippets,
                            SNIPPETS.PROPERTY_DETAILS_PAGE
                              .VARIABLE_EXPENSES_TABLE_MONTHLY
                          )}
                        </th>
                        <th>
                          {getSnippetBySlug(
                            snippets,
                            SNIPPETS.PROPERTY_DETAILS_PAGE
                              .VARIABLE_EXPENSES_TABLE_ANNUAL
                          )}
                        </th>
                      </tr>
                      {property.expenses.custom_expenses
                        .slice(
                          Math.floor(property.expenses.custom_expenses.length / 2)
                        )
                        .filter(
                          (expense) =>
                            expense.measurements === CURRENCY_TYPES.DOLLAR
                        )
                        .map((expense, index) => (
                          <tr key={index}>
                            <td>{expense.name}</td>
                            <td style={{ width: '147px' }}>
                              {formatDollarValues(expense.value)}
                            </td>
                            <td style={{ width: '147px' }}>
                              {formatDollarValues(Math.round(expense.value) * 12)}
                            </td>
                          </tr>
                        ))}
                    </table>
                  </div>
                )}
            </div>
          </div>
        )}
        {true && (
          <div className={styles.graphs}>
            <h4>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.PROPERTY_DETAILS_PAGE
                  .MONTHLY_PROFIT_PROJECTIONS_SUBTITLE
              )}
            </h4>
            <div className={styles.airdna} style={{ marginTop: 15 }}>
            <img src={airdnaLogo} alt="Logo of airDNA" />
            <p className="copyright">
              {getSnippetBySlug(
                snippets,
                SNIPPETS.CREATE_PROPERTY_PAGE.POWERED_BY_AIRDNA
              )}
            </p>
          </div>
            <div>
              <h5>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE
                    .MONTHLY_PROFIT_PROJECTION_SUBTITLE
                )}
              </h5>
              {/* <div className={`${styles.graph} ${styles.graph1}`}>
                <span>Coming Soon</span>
              </div> */}
              {property?.profit_loss_chart_data ? (
                <div
                  className={styles.graph}
                  style={{ maxWidth: `${pdf ? '700px' : '100%'}` }}
                >
                  <ResponsiveContainer aspect={1.4}>
                    <BarChart
                      data={property.profit_loss_chart_data}
                      margin={{
                        bottom: 88,
                      }}
                      height={400}
                    >
                      <XAxis dataKey="name" tickFormatter={(value) => MounthFormatter(value)}/>
                      <YAxis tickFormatter={(value) => `${USDollar.format(value)}`} />
                      <ReferenceLine y={0} stroke="#000" />
                      <Tooltip content={<YearTooltip />}/>
                      <Legend content={<LegendTooltip />}/>
                      <Bar dataKey="total_revenue" fill="#89AD34" label={{ position: 'center', fontSize: 12, fill: '#fff', angle: 270, formatter: (value) => xPriceFormatter(value, property.profit_loss_chart_data) }}/>
                      <Bar dataKey="total_expenses" fill="#B00020" label={{ position: 'center', fontSize: 12, fill: '#fff', angle: 270, formatter: (value) => xPriceFormatter(value, property.profit_loss_chart_data) }}/>
                      <Bar dataKey="mounthly_profit_loss" fill="#23465A" label={{ position: 'center', fontSize: 12, fill: '#fff', angle: 270, formatter: (value) => xPriceFormatter(value, property.profit_loss_chart_data) }}/>
                      <CartesianGrid strokeDasharray="3 3" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>) : null}
            </div>
            <div className="wrap" />
            {overviewData && (
              <div className={styles.graphGroup}>
                <div>
                  <h5>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.PROPERTY_DETAILS_PAGE.OCCUPANCY_BY_MONTH_SUBTITLE
                    )}
                  </h5>
                  <div className={styles.graph} style={{ maxWidth: `${pdf ? '300px' : '100%'}` }}>
                    <ResponsiveContainer aspect={1.4}>
                      <BarChart
                        id="canvasChart1"
                        data={overviewData}
                        margin={{
                          bottom: 88,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          tickFormatter={(value) => MounthFormatter2(value)}
                          dataKey="date"
                          textAnchor="end"
                          scaleToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-70"
                        />
                        <YAxis
                          tickFormatter={(value) =>
                            formatPercentValues(value, true)
                          }
                        />
                        <Tooltip content={<OccupancyGraphTooltip />} />
                        <Bar dataKey="projectedOccupancy" fill="#89AD34" label={{ position: 'center', fontSize: 14, fill: '#fff', angle: 270, formatter: (value) => `${value}%` }}/>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div>
                  <h5>
                    {getSnippetBySlug(
                      snippets,
                      SNIPPETS.PROPERTY_DETAILS_PAGE.AVERAGE_DAILY_RATE_SUBTITLE
                    )}
                  </h5>
                  <div className={styles.graph} style={{ maxWidth: `${pdf ? '300px' : '100%'}` }}>
                    <ResponsiveContainer aspect={1.4}>
                      <BarChart
                        data={overviewData}
                        margin={{
                          bottom: 88,
                        }}
                        height={400}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          tickFormatter={(value) => MounthFormatter2(value)}
                          dataKey="date"
                          textAnchor="end"
                          scaleToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-70"
                        />
                        <YAxis
                          tickFormatter={(value) => formatDollarValues(value)}
                        />
                        <Tooltip content={<ADRGraphTooltip />} />
                        <Bar dataKey="averageDailyRate" fill="#89AD34" label={{ position: 'center', fontSize: 14, fill: '#fff', angle: 270, formatter: (value) => `${USDollar.format(value)}` }}/>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className={styles.proforma}>
          <table className={styles.table}>
            <tr>
              <td colSpan={4}>
                <h4>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROPERTY_DETAILS_PAGE.PROFORMA_SUBTITLE
                  )}
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.RENT_GROWTH_ASSUMPTION_LABEL
                )}
              </td>
              <td>
                {formatPercentValues(
                  property.proforma_data.rent_growth_assumption,
                  true
                )}
              </td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.EXPENSE_GROWTH_ASSUMPTION_LABEL
                )}
              </td>
              <td>
                {formatPercentValues(
                  property.proforma_data.expense_growth_assumption,
                  true
                )}
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.APPRECIATION_ASSUMPTION_LABEL
                )}
              </td>
              <td>
                {formatPercentValues(
                  property.proforma_data.appreciation_assumption,
                  true
                )}
              </td>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.TAX_INCREASE_ASSUMPTION_LABEL
                )}
              </td>
              <td>
                {formatPercentValues(
                  property.proforma_data.tax_increase_assumption,
                  true
                )}
              </td>
            </tr>
          </table>
          <table className={styles.proformaTable}>
            <tr>
              <th />
              <th>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.YEAR_1_SUBTITLE
                )}
              </th>
              <th>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.YEAR_2_SUBTITLE
                )}
              </th>
              <th>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.YEAR_3_SUBTITLE
                )}
              </th>
              <th>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.YEAR_4_SUBTITLE
                )}
              </th>
              <th>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.YEAR_5_SUBTITLE
                )}
              </th>
              <th>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.YEAR_10_SUBTITLE
                )}
              </th>
              <th>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.YEAR_20_SUBTITLE
                )}
              </th>
              <th>
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.YEAR_30_SUBTITLE
                )}
              </th>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.REVENUE_SUBTITLE
                )}
              </td>
              <td colSpan={8} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.RENTAL_INCOME_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[0].revenue
                    .rental_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[1].revenue
                    .rental_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[2].revenue
                    .rental_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[3].revenue
                    .rental_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[4].revenue
                    .rental_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[5].revenue
                    .rental_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[6].revenue
                    .rental_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[7].revenue
                    .rental_income
                )}
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.CLEANING_INCOME_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[0].revenue
                    .cleaning_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[1].revenue
                    .cleaning_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[2].revenue
                    .cleaning_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[3].revenue
                    .cleaning_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[4].revenue
                    .cleaning_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[5].revenue
                    .cleaning_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[6].revenue
                    .cleaning_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[7].revenue
                    .cleaning_income
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={9} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.GROSS_INCOME_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[0].gross_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[1].gross_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[2].gross_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[3].gross_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[4].gross_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[5].gross_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[6].gross_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[7].gross_income
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={9} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.EXPENSES_SUBTITLE
                )}
              </td>
              <td colSpan={8} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.OPERATING_EXPENSES_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[0].expenses
                    .operating_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[1].expenses
                    .operating_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[2].expenses
                    .operating_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[3].expenses
                    .operating_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[4].expenses
                    .operating_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[5].expenses
                    .operating_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[6].expenses
                    .operating_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[7].expenses
                    .operating_expenses
                )}
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.CLEANING_FEE_EXPENSES_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[0].expenses
                    .cleaning_fee_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[1].expenses
                    .cleaning_fee_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[2].expenses
                    .cleaning_fee_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[3].expenses
                    .cleaning_fee_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[4].expenses
                    .cleaning_fee_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[5].expenses
                    .cleaning_fee_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[6].expenses
                    .cleaning_fee_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[7].expenses
                    .cleaning_fee_expenses
                )}
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.CAPITAL_EXPENDITURES_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[0].expenses
                    .capital_expenditures
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[1].expenses
                    .capital_expenditures
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[2].expenses
                    .capital_expenditures
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[3].expenses
                    .capital_expenditures
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[4].expenses
                    .capital_expenditures
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[5].expenses
                    .capital_expenditures
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[6].expenses
                    .capital_expenditures
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[7].expenses
                    .capital_expenditures
                )}
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.PROP_FEES_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[0].expenses
                    .prop_mgmt_fees
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[1].expenses
                    .prop_mgmt_fees
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[2].expenses
                    .prop_mgmt_fees
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[3].expenses
                    .prop_mgmt_fees
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[4].expenses
                    .prop_mgmt_fees
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[5].expenses
                    .prop_mgmt_fees
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[6].expenses
                    .prop_mgmt_fees
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[7].expenses
                    .prop_mgmt_fees
                )}
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.REAL_ESTATE_TAXES_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[0].expenses
                    .real_estate_taxes
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[1].expenses
                    .real_estate_taxes
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[2].expenses
                    .real_estate_taxes
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[3].expenses
                    .real_estate_taxes
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[4].expenses
                    .real_estate_taxes
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[5].expenses
                    .real_estate_taxes
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[6].expenses
                    .real_estate_taxes
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[7].expenses
                    .real_estate_taxes
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={9} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.TOTAL_EXPENSES_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[0].total_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[1].total_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[2].total_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[3].total_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[4].total_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[5].total_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[6].total_expenses
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[7].total_expenses
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={9} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.NET_OPERATING_INCOME_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[0]
                    .net_opertating_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[1]
                    .net_opertating_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[2]
                    .net_opertating_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[3]
                    .net_opertating_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[4]
                    .net_opertating_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[5]
                    .net_opertating_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[6]
                    .net_opertating_income
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[7]
                    .net_opertating_income
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={9} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.DEBT_SERVICE_SUBTITLE
                )}
              </td>
              <td colSpan={8} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.INTEREST_EXPENSE_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[0].debt_sercivce
                    .interest_expense
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[1].debt_sercivce
                    .interest_expense
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[2].debt_sercivce
                    .interest_expense
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[3].debt_sercivce
                    .interest_expense
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[4].debt_sercivce
                    .interest_expense
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[5].debt_sercivce
                    .interest_expense
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[6].debt_sercivce
                    .interest_expense
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[7].debt_sercivce
                    .interest_expense
                )}
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.PRINCIPAL_REPAIMENT_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[0].debt_sercivce
                    .principal_repayment
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[1].debt_sercivce
                    .principal_repayment
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[2].debt_sercivce
                    .principal_repayment
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[3].debt_sercivce
                    .principal_repayment
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[4].debt_sercivce
                    .principal_repayment
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[5].debt_sercivce
                    .principal_repayment
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[6].debt_sercivce
                    .principal_repayment
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[7].debt_sercivce
                    .principal_repayment
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={9} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.TOTAL_DEBT_PAYMENTS_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[0].total_debt_payments
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[1].total_debt_payments
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[2].total_debt_payments
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[3].total_debt_payments
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[4].total_debt_payments
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[5].total_debt_payments
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[6].total_debt_payments
                )}
              </td>
              <td>
                {formatDollarValues(
                  -property.proforma_data.proformma_table[7].total_debt_payments
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={9} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.REMAINING_DEBT_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[0].remaining_debt
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[1].remaining_debt
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[2].remaining_debt
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[3].remaining_debt
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[4].remaining_debt
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[5].remaining_debt
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[6].remaining_debt
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[7].remaining_debt
                )}
              </td>
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.EQUITY_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[0].equity
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[1].equity
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[2].equity
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[3].equity
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[4].equity
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[5].equity
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[6].equity
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[7].equity
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={9} />
            </tr>
            <tr>
              <td className="bold">
                {getSnippetBySlug(
                  snippets,
                  SNIPPETS.PROPERTY_DETAILS_PAGE.CASH_FLOW_ROW
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[0].cash_flow
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[1].cash_flow
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[2].cash_flow
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[3].cash_flow
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[4].cash_flow
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[5].cash_flow
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[6].cash_flow
                )}
              </td>
              <td>
                {formatDollarValues(
                  property.proforma_data.proformma_table[7].cash_flow
                )}
              </td>
            </tr>
          </table>
        </div>
      </div>
      {!pdf ? (
        <div className={styles.generatedBy}>
          <p>
            {getSnippetBySlug(
              snippets,
              SNIPPETS.PROPERTY_DETAILS_PAGE.REPORT_GENERATED_BY_LABEL
            )}
          </p>
          <div className={styles.info}>
            <table className={styles.table}>
              <tr>
                <td>{property.report_generated_by.name}</td>
                <td>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROPERTY_DETAILS_PAGE.PHONE_LABEL
                  )}
                </td>
                <td>{property.report_generated_by.phone}</td>
              </tr>
              <tr>
                <td>{property.report_generated_by.job_title}</td>
                <td>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROPERTY_DETAILS_PAGE.EMAIL_LABEL
                  )}
                </td>
                <td>{property.report_generated_by.email}</td>
              </tr>
              <tr>
                <td>{property.report_generated_by.location}</td>
                <td>
                  {getSnippetBySlug(
                    snippets,
                    SNIPPETS.PROPERTY_DETAILS_PAGE.WEBSITE_LABEL
                  )}
                </td>
                <td>{property.report_generated_by.site}</td>
              </tr>
            </table>
            <img
              src={property.report_generated_by.avatar || avatarPlaceholder}
              alt="Profile photo"
            />
          </div>
        </div>
      ) : null}
      <div className="wrap" />
      <div className={styles.disclaimer}>
        <h4>
          {getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTY_DETAILS_PAGE.DISCLAIMER_TITLE
          )}
        </h4>
        <div className={styles.text}>
          {getSnippetBySlug(
            snippets,
            SNIPPETS.PROPERTY_DETAILS_PAGE.DISCLAIMER_TEXT
          )}
        </div>
      </div>
    </div>
  );
}

function PropertyDetailsPage() {
  const navigate = useNavigate();
  const parameters = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const snippets = useSelector((state) => state.snippets.snippets);
  const [isCopied, setCopied] = useState(false);
  const [pdf, setPdf] = useState(false);

  const [property, setProperty] = useState(null);

  const createPDF = async () => {
    const element = document.querySelector('#pdf').innerHTML;
    return html2pdf()
      .set({
        jsPDF: {
          format: 'a4',
        },
        margin: 8,
        filename: `Property Analysis - ${property.address}`,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 1,
          useCORS: true,
          dpi: 72,
          onclone: (element) => {
            const svgElements = [...element.querySelectorAll('svg')];
            for (const s of svgElements) {
              console.log(svgElements);
              const bBox = s.getBBox();
              s.setAttribute('width', s.getBoundingClientRect().width);
              s.setAttribute('height', s.getBoundingClientRect().height);
              s.style.width = null;
              s.style.height = null;
            }
          },
        },
        pagebreak: {
          before: '.wrap',
          avoid: [
            'p',
            'tr',
            'img',
            'h3',
            'h4',
            'h5',
            'a',
            'span',
            'section',
            `div.${styles.variableExpenses}`,
          ],
        },
      })
      .from(element)
      .save();
  };

  useEffect(() => {
    dispatch(
      parameters.slug
        ? getPropertyBySlug({
            slug: parameters.slug,
            onSuccess: (data) => setProperty(data),
          })
        : getPropertyDetails({
            id: parameters.propertyId,
            onSuccess: (data) => setProperty(data),
          })
    );
  }, []);

  const removeProperty = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <Dialog
          title="Delete Property"
          text="Are you sure you want to delete this property?"
          buttons={[
            {
              type: 'button',
              text: 'Delete',
              onClick: () => {
                dispatch(
                  deleteProperty({
                    id,
                    onSuccess: () => {
                      onClose();
                      navigate(ROUTES.USER.PROPERTIES);
                    },
                  })
                );
              },
              className: 'secondary-button',
            },
            {
              type: 'button',
              text: 'Cancel',
              onClick: onClose,
              className: 'secondary-button',
            },
          ]}
        />
      ),
    });
  };

  const editProperty = (id) => {
    navigate(`${ROUTES.USER.PROPERTIES}/${id}/edit`, {
      state: {
        ...location.state,
        pathname: location.pathname,
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const copyLink = () => {
    setCopied(false);
    dispatch(
      getPublicLink(parameters.propertyId, (data) => {
        navigator.clipboard.writeText(
          `${window.location.origin}/property/${data.slug}`
        );
        setCopied(true);
      })
    );
  };

  const generatePdf = () => {
    setPdf(() => true);
    setTimeout(() => {
      createPDF().then(() => {
        setPdf(false);
      });
    }, 20);
  };

  return (
    <div className={`${parameters.slug ? styles.content : ''}`}>
      <div className={styles.header}>
        <div>
          {!parameters.slug && (
            <button
              type="button"
              className="button withImg outlined-secondary-button -thin"
              onClick={() => {
                navigate(
                  location.state && location.state.pathname
                    ? location.state.pathname === location.pathname
                      ? ROUTES.USER.PROPERTIES
                      : location.state.pathname
                    : ROUTES.USER.PROPERTIES,
                  {
                    state: {
                      ...location.state,
                    },
                  }
                );
              }}
            >
              <span className="material-icons">chevron_left</span>
              <span>Back</span>
            </button>
          )}
          <h2 className="subtitle">
            {getSnippetBySlug(snippets, SNIPPETS.PROPERTY_DETAILS_PAGE.TITLE)}
          </h2>
        </div>
        {property && user && user.id === property.user.id && !parameters.slug && (
          <div>
            <span
              className={`breadcrumb filled-success ${styles.copied}`}
              style={{ visibility: isCopied ? 'visible' : 'hidden' }}
            >
              Copied
            </span>
            <button
              type="button"
              onClick={copyLink}
              className="button withImg outlined-secondary-button -thin"
            >
              <span className="material-icons">share</span>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.PROPERTY_DETAILS_PAGE.SHARE_PROPERTY_BUTTON
              )}
            </button>
            <button
              type="button"
              className="button withImg outlined-secondary-button -thin"
              onClick={generatePdf}
            >
              <span className="material-icons">download</span>
              Download PDF
            </button>
            <button
              type="button"
              className="button withImg primary-button -thin"
              onClick={() => editProperty(property.id)}
            >
              <span className="material-icons">edit</span>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.PROPERTY_DETAILS_PAGE.EDIT_BUTTON
              )}
            </button>
            <button
              type="button"
              className="button withImg outlined-secondary-button -thin"
              onClick={() => removeProperty(property.id)}
            >
              <span className="material-icons">delete</span>
              {getSnippetBySlug(
                snippets,
                SNIPPETS.PROPERTY_DETAILS_PAGE.DELETE_BUTTON
              )}
            </button>
          </div>
        )}
      </div>
      {property && (
        <div>
          <PropertyDetails property={property} />
          <div id="pdf" style={{ height: 0, overflow: 'hidden' }}> 
              <PropertyDetails property={property} pdf />
          </div>
          {/* {pdf && (
            <div id="pdf" style={{ display: 'none' }}>
              <PropertyDetails property={property} pdf />
            </div>
          )} */}
        </div>
      )}
    </div>
  );
}

export default PropertyDetailsPage;

const SNIPPETS = {
  MARKET_DETAILS_PAGE: {
    PAGE_TITLE: 'pmarket_detailsp_hM3sfWQ3G',
    MARKET_NAME_SUBTITLE: 'pmarket_namep_SwdUnPsBA',
    PROPERTIES_ANALYZED_SUBTITLE: 'pproperties_analyzedp_1J2qyacQ5',
    REVENUE_SUBTITLE: 'pannual_projected_revenuep_4gQj1uTGY',
    DAILY_RATES_SUBTITLE: 'paverage_daily_ratesp_XrUTIOkCO',
    OCCUPANCY_SUBTITLE: 'paverage_occupancyp_ZAj8BBrCK',
    CAP_RATES_SUBTITLE: 'pcap_rates_by_bedroom_countp_jry60JPsY',
    GROSS_YIELD_SUBTITLE: 'pgross_yield_by_bedroom_countp_OxYaPMdJj',
    CASH_ON_CASH_SUBTITLE: 'pcash_on_cash_return_by_bedroom_countp_aQ4vLzh1P',
    AVERAGE_CAP_RATE_LABEL: 'paverage_cap_ratep_IjE7ieCPK',
    MARKET_AVERAGE_CAP_RATE_LABEL: 'pmarket_average_cap_ratep_PZhQ4ngU1',
    APEX_CAP_RATE_LABEL: 'papex_cap_ratep_xdtHokAgV',
    AVERAGE_GROSS_YIELD_LABEL: 'paverage_gross_yieldp_VeGFTbigV',
    MARKET_AVERAGE_GROSS_YIELD_LABEL: 'pmarket_average_gross_yieldp_B5Fog4oCX',
    APEX_GROSS_YIELD_LABEL: 'papex_gross_yieldp_zZAWWs1Jo',
    AVERAGE_CASH_LABEL: 'paverage_cash_on_cashp_YVH2tEOxe',
    MARKET_AVERAGE_CASH_LABEL: 'pmarket_average_cash_on_cashp_NSqdwlnZv',
    APEX_CASH_LABEL: 'papex_cash_on_cashp_Rgw5RqDop',
  },
  PROPERTY_FILTER_FORM: {
    TITLE: 'padd_filtersp_a6YgjOiTO',
    MARKET_PLACEHOLDER: 'pmarketp_tt78bGFAA',
    RENTAL_TYPE_PLACEHOLDER: 'prental_typep_xF4QQjwXo',
    RENTAL_TYPE_LABEL: 'prental_typep_1qyuu2zAN',
    MARKET_LABEL: 'pmarketp_A2DpKnx08',
  },
  MARKET_FILTER_FORM: {
    TITLE: 'padd_filtersp_mFQj8iZr4',
  },
  FORGOT_PASSWORD_PAGE: {
    TITLE: 'pforgot_passwordp_CclQ7z3ri',
    INFO_TEXT:
      'penter_your_email_and_instructions_will_be_sent_to_youp_0rphrk3wH',
    EMAIL_LABEL: 'pemailp_LHOj4JLmi',
    EMAIL_PLACEHOLDER: 'penter_emailp_qpv6hAhYF',
    RESET_BUTTON: 'preset_passwordp_o3Z8XpdAD',
    ALREADY_HAVE_ACCOUNT: 'palready_have_an_accountp_FpXRyFypZ',
    SIGN_IN_LINK: 'psign_inp_KyrKScyqR',
  },
  REGISTRATION_PAGE: {
    TITLE: 'pregistrationp_kpWcpjNCe',
    NAME_LABEL: 'pfull_namep_hiknSB74q',
    NAME_PLACEHOLDER: 'penter_usernamep_tpfBjxhpk',
    EMAIL_LABEL: 'pemailp_4TMKmdna5',
    EMAIL_PLACEHOLDER: 'penter_emailp_ya8sNANpD',
    PASSWORD_LABEL: 'ppasswordp_YrgyvOvLN',
    PASSWORD_PLACEHOLDER: 'penter_passwordp_llgfHBLko',
    CONFIRM_LABEL: 'pconfirm_passwordp_49MbvzGu6',
    CONFIRM_PLACEHOLDER: 'pconfirm_passwordp_u7iR4CAEb',
    SIGN_UP_BUTTON: 'psign_upp_IB9wgIXWr',
    ALREADY_HAVE_ACCOUNT: 'palready_have_an_accountp_xO1WCCNgq',
    SIGN_IN_LINK: 'psign_inp_l532qS45Q',
  },
  PROPERTY_DETAILS_PAGE: {
    CREATION_DATE_LABEL: 'pproperty_creation_datep_cO5A5rqV5',
    PROPERTY_ANALYSIS_TITLE: 'pproperty_analysisp_scwGGzCeZ',
    TITLE: 'pproperty_detailsp_dyWe12b46',
    SHARE_PROPERTY_BUTTON: 'pshare_propertyp_F0ATC2y54',
    EDIT_BUTTON: 'peditp_3SBeUrdhc',
    DELETE_BUTTON: 'pdeletep_UkTmMBvmI',
    DETAILS_SUBTITLE: 'pdetailsp_zhvMhV5Jc',
    MARKET_NAME_LABEL: 'pmarket_namep_25zfac1KA',
    BEDROOMS_LABEL: 'pbedroomsp_2Of4u0oON',
    DISCLAIMER_TITLE: 'pdisclaimerp_agUX4x18p',
    DISCLAIMER_TEXT: 'plorem_ipsum_is_simply_dummy_textp_RihTGgpFJ',
    ADDRESS_LABEL: 'paddressp_PvrUvOJRN',
    BATHROOMS_LABEL: 'pbathroomsp_s87ZmTyiX',
    CITY_LABEL: 'pcityp_qlIFR77kC',
    SQUARE_FOOTAGE_LABEL: 'psquare_feetp_7e2SBnT2O',
    STATE_LABEL: 'pstatep_olNKOMe5a',
    LIST_PRICE_LABEL: 'plist_pricep_RLr55V8Tq',
    ZIP_LABEL: 'pzipp_XiVv2OR4A',
    PURCHASE_PRICE_LABEL: 'ppurchase_pricep_JcZVJfHwz',
    CREATION_DATE_LABEL: 'pproperty_createdp_YMvZqaHf9',
    PRICE_PER_FEET_LABEL: 'pprice_per_sq_ftp_Le2VY27N5',
    LINK_LABEL: 'pproperty_linkp_T6BWKz2nh',
    VARIABLE_EXPENSES_TITLE: 'pvariable_expensesp_tcJ6MOnQK',
    VARIABLE_EXPENSES_TABLE_TITLE: 'ptitlep_jn2prnlM7',
    VARIABLE_EXPENSES_TABLE_MONTHLY: 'pmonthlyp_3xsPC7ofr',
    VARIABLE_EXPENSES_TABLE_ANNUAL: 'pannualp_rzrxhvA8g',
    NOTES_LABEL: 'pproperty_notesp_BLwQz9YFe',
    INVESTMENT_ANANYSIS_SUBTITLE: 'pinvestment_analysisp_U8ikePUFS',
    PROJECTED_RETURN_METRICS_LABEL: 'pprojected_return_metricsp_luQ8NbFGJ',
    CAP_RATE_LABEL: 'pcap_ratep_TL6T9BXMM',
    GROSS_YIELD_LABEL: 'pgross_yieldp_V2ne2koBV',
    CASH_RETURN_LABEL: 'pcash_on_cash_returnp_U3yaePXLX',
    ACQUISITION_DETAILS_SUBTITLE:
      'p_styletext_alignstartacquisition_detailsp_2jd1ZPndH',
    FURNISHING_BUDGET_LABEL: 'pfurnishing_budgetp_JomQJhwDf',
    DOWN_PAYMENT_LABEL: 'pdown_paymentp_6iowc4dkc',
    TOTAL_STARTUP_COSTS_LABEL: 'ptotal_startup_costsp_WGTFxsbXb',
    CLOSING_COSTS_LABEL: 'pclosing_costsp_QMzkYFOaB',
    INTEREST_RATE_LABEL: 'pinterest_ratep_bCumkjumB',
    REHAB_BUDGET_LABEL: 'prehub_budgetp_YdaTywpd5',
    MORTGAGE_PAYMENT_LABEL: 'pproj_mortgage_payment_mop_e15l2oKyl',
    PROJECTIONS_SUBTITLE: 'pyear1_financial_projectionsp_ROxU2Ipqv',
    GROSS_INCOME_LABEL: 'pgross_incomep_16HkIxBji',
    NET_OPERATING_INCOME_LABEL: 'pnet_operating_incomep_7LnFS4565',
    ANNUAL_OPERATING_EXPENSES_LABEL: 'pannual_operating_expensesp_GQTnifBt4',
    INTEREST_EXPENSE_LABEL: 'pinterest_expense_on_debtp_Liqw2jrDY',
    PROPERTY_MANAGEMENT_LABEL: 'pproperty_management_feesp_RlJKPc7gg',
    PRINCIPAL_REPAYMENT_LABEL: 'pprincipal_repaymentp_rBNItTXFz',
    CAPITAL_EXPENDITURES_LABEL: 'pcapital_expendituresp_f4uJPwj98',
    ANNUAL_CASH_FLOW_LABEL: 'pannual_cash_flowp_j5F3vVyfW',
    REAL_ESTATE_TAXES_LABEL: 'preal_estate_taxesp_pS6iTRhFc',
    PROFORMA_SUBTITLE: 'pproformap_Z0GLq7kjP',
    RENT_GROWTH_ASSUMPTION_LABEL: 'prent_growth_assumptionp_ivxHr3hgq',
    EXPENSE_GROWTH_ASSUMPTION_LABEL: 'pexpense_growth_assumptionp_uHeF288Ko',
    APPRECIATION_ASSUMPTION_LABEL: 'pappreciation_assumptionp_GYa82FUZz',
    TAX_INCREASE_ASSUMPTION_LABEL: 'ptax_increase_assumptionp_mr0KLQXBY',
    MONTHLY_PROFIT_PROJECTIONS_SUBTITLE:
      'pmonthly_profitloss_projectionsp_v9DqAY15l',
    MONTHLY_PROFIT_PROJECTION_SUBTITLE:
      'pmonthly_profitloss_projectionp_jwBCjYkTw',
    OCCUPANCY_BY_MONTH_SUBTITLE: 'poccupancy_by_monthp_Rygz1ULNg',
    AVERAGE_DAILY_RATE_SUBTITLE: 'paverage_daily_rate_by_monthp_X9TMQvym0',
    YEAR_1_SUBTITLE: 'pyear_1p_PLb6Awnbi',
    YEAR_2_SUBTITLE: 'pyear_2p_MOHeiIgzD',
    YEAR_3_SUBTITLE: 'pyear_3p_kH3ECBBj3',
    YEAR_4_SUBTITLE: 'pyear_4p_Ap1WESvzt',
    YEAR_5_SUBTITLE: 'pyear_5p_nQ1t2i3Iu',
    YEAR_10_SUBTITLE: 'pyear_10p_LkiwVddSR',
    YEAR_20_SUBTITLE: 'pyear_20p_kEOEobW0H',
    YEAR_30_SUBTITLE: 'pyear_30p_IykUppIgQ',
    REVENUE_SUBTITLE: 'prevenuep_xIpSBHDUj',
    RENTAL_INCOME_ROW: 'p_rental_incomep_umxmeHBux',
    CLEANING_INCOME_ROW: 'p_cleaning_incomep_bCnCZh4pi',
    GROSS_INCOME_ROW: 'pgross_incomep_qyciK90yv',
    EXPENSES_SUBTITLE: 'pexpensesp_0wU2gQ5zK',
    OPERATING_EXPENSES_ROW: 'p_operating_expensesp_CGEMayzLU',
    CLEANING_FEE_EXPENSES_ROW: 'p_cleaning_fee_expensesp_a3YOF9srl',
    PROP_FEES_ROW: 'p_prop_mgmt_feesnbspp_Cbog9ZKTf',
    CAPITAL_EXPENDITURES_ROW: 'p_capital_expendituresp_hptG9wNBB',
    REAL_ESTATE_TAXES_ROW: 'p_real_estate_taxesp_mWRXuXtjI',
    TOTAL_EXPENSES_ROW: 'ptotal_expensesp_nKBGe7Wmh',
    NET_OPERATING_INCOME_ROW: 'pnet_operating_incomep_q0gM2CbOO',
    DEBT_SERVICE_SUBTITLE: 'pdebt_servicep_IZJsjy8XS',
    INTEREST_EXPENSE_ROW: 'p_interest_expensep_c0SKA93J8',
    PRINCIPAL_REPAIMENT_ROW: 'p_principal_repaymentp_fgaRLK1IC',
    TOTAL_DEBT_PAYMENTS_ROW: 'ptotal_debt_paymentsp_nUQUKAVY5',
    REMAINING_DEBT_ROW: 'premaining_debtp_sbLLEn7n6',
    EQUITY_ROW: 'pequityp_S8G5dMO5m',
    CASH_FLOW_ROW: 'pcash_flowp_q2Mnh1dFm',
    CAP_RATE_ROW: 'pcap_ratep_ktd9rSkX2',
    GROSS_YIELD_ROW: 'pgross_yieldp_Kk84oTTAT',
    CASH_ON_CASH_RETURN_ROW: 'pcash_on_cash_returnp_Hu6xv9UUu',
    INTERNAL_RATE_ON_RETURN_ROW: 'pinternal_rate_of_returnp_DiKQmjeht',
    REPORT_GENERATED_BY_LABEL: 'preport_generated_byp_pGnkjUblF',
    PHONE_LABEL: 'pphonep_yVVUBZMxT',
    EMAIL_LABEL: 'pemailp_IvBDup3fV',
    WEBSITE_LABEL: 'pwebsitep_RZgbWVkYw',
  },
  LOGIN_PAGE: {
    TITLE: 'psign_inp_B1mwC3Aer',
    EMAIL_LABEL: 'pemailp_9JUq2Quuj',
    EMAIL_PLACEHOLDER: 'penter_emailp_EuDzyMSMS',
    PASSWORD_LABEL: 'ppasswordp_OQ2xCIRBw',
    PASSWORD_PLACEHOLDER: 'penter_passwordp_cna9INm6C',
    KEEP_LOG_IN: 'pkeep_me_logged_inp_rFF6lYBAJ',
    FORGOT_PASSWORD: 'pforgot_passwordp_YTV0qVEeC',
    SIGN_IN_BUTTON: 'psign_inp_OC5wXW4Q2',
    NOT_ACCOUNT: 'pdont_have_an_accountp_C90UoroNv',
    SIGN_UP_LINK: 'psign_upp_oZVeeCibF',
  },
  PROFILE_PAGE: {
    TITLE: 'pprofilep_nvvzMZ2SF',
    REMOVE_PROFILE_BUTTON: 'premove_profilep_K92MyfK2M',
    GENERAL_INFO_TAB: 'pgeneral_informationp_Xe7ddULTQ',
    PASSWORD_TAB: 'ppasswordp_VTnlPJu0M',
    PDF_INFORMATION_SAVE_BUTTON: 'psavep_okucvXnZ0',
    DISCLAIMER_TAB: 'pdisclaimer_informationp_bIUdeEqXv',
    DESIRED_METRICS_TAB: 'pdesired_return_metricsp_3t40DZS31',
    UPLOAD_PHOTO_BUTTON: 'pupload_photop_rKbCyOagV',
    REMOVE_PHOTO_BUTTON: 'premove_photop_pWfG6IiAd',
    GENERAL_INFORMATION_SUBTITLE: 'pgeneral_informationp_bh91eXI0Y',
    NAME_LABEL: 'pfull_namep_6pE5eYO30',
    NAME_PLACEHOLDER: 'penter_usernamep_XpOwiS8lh',
    EMAIL_LABEL: 'pemailp_mtMrVA5QN',
    EMAIL_PLACEHOLDER: 'penter_emailp_eCN0AjoC0',
    GENERAL_INFO_BUTTON: 'psave_changesp_N1aZe9CMH',
    PASSWORD_SUBTITLE: 'ppasswordp_dyvosmdM5',
    PDF_INFORMATION_SUBTITLE: 'ppdf_informationp_Ci62EVOCg',
    PHONE_LABEL: 'pphonep_c2m9xW5h2',
    PHONE_PLACEHOLDER: 'pphonep_tCgqgVXht',
    LOCATION_LABEL: 'plocationp_9CnAdApCm',
    LOCATION_PLACEHOLDER: 'plocationp_SXvKiHlYI',
    JOB_TITLE_LABEL: 'pjob_titlep_vxqZbTeEr',
    JOB_TITLE_PLACEHOLDER: 'pjob_titlep_MbmVDbqGR',
    WEBSITE_LABEL: 'pwebsitep_PStddZhyB',
    WEBSITE_PLACEHOLDER: 'pwebsitep_Jgiu83YK0',
    SAVE_PASSWORD_BUTTON: 'psave_passwordp_PsqQAf4ml',
    CURRENT_PASS_LABEL: 'pcurrent_passwordp_CWENw4hcP',
    CURRENT_PASS_PLACEHOLDER: 'pcurrent_passwordp_Ug6nXEYpA',
    NEW_PASS_LABEL: 'pnew_passwordp_5nIH5ROog',
    NEW_PASS_PLACEHOLDER: 'pnew_passwordp_7hd5SJadU',
    CONFIRM_PASS_LABEL: 'pconfirm_new_passwordp_NEhnGvfjV',
    CONFIRM_PASS_PLACEHOLDER: 'pconfirm_new_passwordp_e1tZsOdk6',
    ONE_RULE_SUBTITLE: 'pset_colors_for_1_rulep_GXVi9Pqdu',
    CASH_ON_CASH_SUBTITLE: 'pset_colors_for_cash_on_cash_returnp_wZFdgvHY8',
    GROSS_YIELD_SUBTITLE: 'pgross_yieldp_kPG8wfsXs',
    CASH_ON_CASH_1_RULE_BUTTON: 'psavep_vLd0Jgf4v',
    REMOVE_PROFILE_TITLE: 'premove_profilep_vRG3T3CtB',
    REMOVE_PROFILE_SUBTITLE:
      'pare_you_sure_you_want_to_remove_profilep_SQVLbn0yB',
  },
  CREATE_PROPERTY_PAGE: {
    FEES_REVENUE_LABEL: 'pcleaning_fees_revenue_p_ai8q3Ja1f',
    FEES_REVENUE_PLACEHOLDER: 'pcleaning_fees_revenuep_KGCRtmaIH',
    FEES_EXPENSES_LABEL: 'pcleaning_fees_expenses_p_s9ywCRdQa',
    FEES_EXPENSES_PLACEHOLDER: 'pcleaning_fees_expensesp_JL8WAqsEC',
    DAYS_PER_STAY_LABEL: 'paverage_days_per_stayp_qJYi5kdAm',
    DAYS_PER_STAY_PLACEHOLDER: 'paverage_days_per_stayp_rf1Uxo8q8',
    RESAVE_PROJECTED_RENT:
      'presave_variable_expenses_after_changing_projected_rentp_N01UqcQAj',
    RESAVE_INFO:
      'pclick_edit_variable_expenses_for_resaving_variable_expensesp_LpMBIAQRP',
    TITLE: 'pcreate_new_propertyp_TPAHbt1JI',
    REALTOR_SUBTITLE:
      'p_styletext_alignstartinsert_link_from_zillow_or_realtorp_eVBNvk0Hx',
    REALTOR_LABEL:
      'pinsert_hyperlink_to_zillow_or_realtor_listing_fields_will_auto_populatep_L24cpTFRR',
    REALTOR_PLACEHOLDER: 'pzillow_or_realtor_linkp_IWemhtQ1E',
    GET_INFORMATION_BUTTON: 'pget_informationp_PnfbtqzHQ',
    GENERAL_SUBTITLE: 'pgeneralp_aSxl8Y60W',
    MARKET_LABEL: 'pmarketp_DP5MAgovB',
    MARKET_PLACEHOLDER: 'pmarketp_PS8mDyavH',
    NEW_MARKET_BUTTON: 'padd_new_marketp_Z1Ofse75M',
    RENTAL_TYPE_LABEL: 'prental_typep_B1Tdew34l',
    RENTAL_TYPE_PLACEHOLDER: 'prental_typep_CES0QBW6N',
    PROPERTY_DETAILS_SUBTITLE: 'pproperty_detailsp_1l34rr4BE',
    ADDRESS_LABEL: 'paddressp_ACRX00HlE',
    ADDRESS_PLACEHOLDER: 'paddressp_5FRyQH2rN',
    STATE_LABEL: 'pstatep_ocCgEqbfw',
    STATE_PLACEHOLDER: 'pstatep_zzOqSADpY',
    CITY_LABEL: 'pcityp_BGegeljVJ',
    CITY_PLACEHOLDER: 'pcityp_EcAaF7TPO',
    ZIP_LABEL: 'pzipp_Nu9TgddmJ',
    ZIP_PLACEHOLDER: 'pzipp_1CJTCFpQN',
    BEDROOMS_LABEL: 'pbedroomsp_MJ8Xm4NmW',
    BEDROOMS_PLACEHOLDER: 'pbedroomsp_qBIQmmrsF',
    BATHROOMS_LABEL: 'pbathroomsp_lu3nXwBDG',
    BATHROOMS_PLACEHOLDER: 'pbathroomsp_TOrgpNamo',
    FOOTAGE_LABEL: 'psquare_footagep_wePuh3mAW',
    FOOTAGE_PLACEHOLDER: 'psquare_footagep_BIhD3TlzM',
    GUESTS_LABEL: 'pguestsp_IGOQpSI6A',
    GUESTS_PLACEHOLDER: 'pguestsp_pXrpj3Y2p',
    NOTES_LABEL: 'pnotesp_Pwnvb3kqa',
    NOTES_PLACEHOLDER: 'pnotesp_0BOmy6Ny9',
    LOAN_SUBTITLE: 'ploan_detailsp_qSnmi6OLZ',
    LIST_PRICE_LABEL: 'plist_price_p_nu75lNwVF',
    LIST_PRICE_PLACEHOLDER: 'plist_pricep_FhSJdunEt',
    PURCHASE_PRICE_LABEL: 'ppurchase_price_p_zraUbon6t',
    PURCHASE_PRICE_PLACEHOLDER: 'ppurchase_pricep_KfJq40VvT',
    CLOSING_COSTS_LABEL: 'pclosing_costs_p_AT5VFMIVK',
    CLOSING_COSTS_LABEL_DOLLAR: 'pclosing_costs_p_cGudhF6Pw',
    CLOSING_COSTS_PLACEHOLDER: 'pclosing_costsp_E7ffg7dhr',
    DOWN_PAYMENT_LABEL: 'pdown_payment_p_bAtRUN9Q1',
    DOWN_PAYMENT_PLACEHOLDER: 'pdown_paymentp_x0aAfbKAq',
    APPRECIATION_LABEL: 'pneed_appreciation_assumptionp_uUwAQZ4dH',
    APPRECIATION_PLACEHOLDER: 'pneed_appreciation_assumptionp_wFIUmrWN3',
    INTEREST_RATE_LABEL: 'pinterest_rate_p_OwUAv5vpj',
    INTEREST_RATE_PLACEHOLDER: 'pinterest_ratep_qP64QWAbN',
    LOAN_TERM_LABEL: 'ploan_termp_DYmjDl40D',
    LOAN_TERM_PLACEHOLDER: 'ploan_termp_GhShjTXxd',
    REVENUE_SUBTITLE: 'prevenuep_EYoRnHdmj',
    IMPORT_DATA_FROM_AIRDNA: 'pimport_data_from_airdnap_PIJU4uJk9',
    POWERED_BY_AIRDNA: 'ppowered_by_airdnap_BkRYnuwnG',
    ANNUAL_PROJECTED_RENT_LABEL: 'pannual_projected_rent_p_84lajal3g',
    ANNUAL_PROJECTED_RENT_PLACEHOLDER: 'pannual_projected_rentp_MkJS0GCvH',
    MONTHLY_PROJECTED_RENT_LABEL: 'pmonthly_projected_rent_p_RDpnJAolA',
    MONTHLY_PROJECTED_RENT_PLACEHOLDER: 'pmonthly_projected_rentp_Ponzbj8OF',
    AVERAGE_DAILY_RATE_LABEL: 'paverage_daily_rate_adr_p_TZTHujm6n',
    AVERAGE_DAILY_RATE_PLACEHOLDER: 'paverage_daily_ratep_MAwEqAl6u',
    PROJECTED_OCCUPANCY_LABEL: 'pprojected_occupancy_p_FJzpiL90D',
    PROJECTED_OCCUPANCY_PLACEHOLDER: 'pprojected_occupancyp_npx2Qi8Yz',
    EXPENSES_SUBTITLE: 'pexpensesp_06cGdxnDN',
    ADD_VARIABLE_EXPENSES_SUBTITLE: 'pstep_7_add_variable_expensesp_8CMn9Wg1s',
    PROPERTY_TAXES_LABEL: 'pproperty_taxes_p_AdvP6gj10',
    PROPERTY_TAXES_PLACEHOLDER: 'pproperty_taxesp_YP1vIl2mV',
    REHUB_LABEL: 'prehabfurnishing_budget_p_s57jG7IFj',
    REHUB_PLACEHOLDER: 'prehabfurnishing_budgetp_9B7yjrOCD',
    FURNISHING_LABEL: 'pfurnishing_budgetp_jpkCoNljI',
    FURNISHING_PLACEHOLDER: 'pfurnishing_budgetp_kv7PHwXXX',
    ADD_EXPENSES_BUTTON: 'padd_variable_expensesp_crbI26prB',
    EXPENSES_BUTTON_SUBTITLE:
      'pdefine_projected_rent_value_to_add_variable_expensesp_WDCFPR4fF',
    CREATE_PROPERTY_BUTTON: 'pcreate_new_propertyp_Hkd4KDwKu',
    CREATE_PROPERTY_BUTTON_INFO:
      'pfill_variable_expenses_for_listing_site_fees_repairsmaintenance_and_property_management_feep_7N9uXMrMO',
    UPDATE_PROPERTY_TITLE: 'pupdate_propertyp_qXqziU5cT',
    SAVE_CHANGES_BUTTON: 'psave_changesp_jpVrbZm49',
    EDIT_VARIABLE_EXPENSES_BUTTON: 'pedit_variable_expensesp_cWfBxKveO',
    RENT_GROWTH_ASSUMPTION_LABEL: 'prent_growth_assumptionp_tNkEb3MMi',
    RENT_GROWTH_ASSUMPTION_PLACEHOLDER: 'prent_growth_assumptionp_XUQAojkKr',
    APPRECIATION_ASSUMPTION_LABEL: 'pappreciation_assumptionp_gGjMM9yuE',
    APPRECIATION_ASSUMPTION_PLACEHOLDER: 'pappreciation_assumptionp_aZl7OSawZ',
    EXPENSE_GROWTH_ASSUMPTION_LABEL: 'pexpense_growth_assumptionp_PqFypzWOl',
    EXPENSE_GROWTH_ASSUMPTION_PLACEHOLDER:
      'pexpense_growth_assumptionp_vAOtiFAR3',
    TAX_INCREASE_ASSUMPTION_LABEL: 'ptax_increase_assumptionp_AF99eWuz2',
    TAX_INCREASE_ASSUMPTION_PLACEHOLDER: 'ptax_increase_assumptionp_KeFaFOyCN',
  },
  PROPERTIES_PAGE: {
    TITLE: 'ppropertiesp_mVXlJPX6E',
    CREATE_NEW_PROPERTY_BUTTON: 'pcreate_new_propertyp_XW82ehH3I',
    PHOTO_COL: 'pphotop_pMCSY83nl',
    MARKET_NAME_COL: 'pmarket_namep_jmVdQlCy0',
    PROPERTY_DETAILS_COL: 'pproperty_detailsp_gaCygBmTT',
    ADDRESS_COL: 'paddressp_l20qTqtxZ',
    REVENUE_COL: 'prevenuep_4QT6co0vs',
    RESET_FILTER_BUTTON: 'preset_filtersp_PKY4klbNU',
    STARTUP_COSTS_COL: 'pstartup_costsp_mtPifNN6L',
    ANNUAL_EXPENSES_COL: 'pannual_expensesp_SWY5Z9qwD',
    CASH_FLOW_COL: 'pcash_flowp_1xYR7gCHl',
    CAP_RATE_COL: 'pcap_ratep_Cg2UCLudw',
    GROSS_COL: 'pgross_yieldp_pNBxxtZcC',
    CASH_ON_CASH_COL: 'pcash_on_cash_returnp_EWH5lHnRq',
    DELETE_TITLE: 'pdelete_propertyp_ApepxUSbG',
    DELETE_SUBTITLE:
      'pare_you_sure_you_want_to_delete_this_propertyp_Bj2FLUo7a',
    SEARCH_PLACEHOLDER: 'psearch_by_addressp_GfJz9YbAw',
    SEARCH_BUTTON: 'psearchp_GT26Pe58m',
    FILTER_BUTTON: 'pfilterp_YxYyhlutM',
  },
  CREATE_MARKET_FORM: {
    CREATE_MARKET_TITLE: 'padd_new_marketp_MNm21jlz8',
    MARKET_NAME_LABEL: 'pmarket_namep_i9fOyinh0',
    MARKET_NAME_PLACEHOLDER: 'pmarket_namep_GJmBr7BI9',
    STATES_LABEL: 'pstatesp_5sDisKTp5',
    STATES_PLACEHOLDER: 'pstatesp_7MvxtxvMY',
    CITIES_LABEL: 'pcitiesp_5gJRJWNHN',
    CITIES_PLACEHOLDER: 'pcitiesp_aXir3q3Ld',
    SAVE_CHANGES_BUTTON: 'psave_changesp_ar2VziYEJ',
    CREATE_MARKET_BUTTON: 'pcreate_marketp_93BIcNPcG',
    EDIT_MARKET: 'pedit_marketp_Lo6MXPEV0',
    DELETE_MARKET_TITLE: 'pdelete_marketp_MzX69fa5r',
    DELETE_MARKET_SUBTITLE:
      'pare_you_sure_you_want_to_delete_this_marketp_HfD5Q4IbG',
  },
  MARKETS_PAGE: {
    MARKET_DATA_SUBHEADER: 'pmarket_datap_rnMXEHnMU',
    AVERAGE_SUBHEADER: 'paverage_p_hqxmuLuHx',
    WARNING_TOOLTIP:
      'pyou_have_to_add_at_least_3_properties_to_see_more_accurate_average_valuesp_wIxLm73WH',
    SEARCH_BUTTON: 'psearchp_u3YDlDSs1',
    SEARCH_PLACEHOLDER: 'psearch_by_market_namep_PD4io2Xdz',
    TITLE: 'pmarketsp_0Z81LwHnd',
    ADD_NEW_MARKET_BUTTON: 'padd_new_marketp_yiOh0DxAk',
    MARKET_PAGE_COL: 'pmarket_namep_svO99ABP3',
    PROPERTY_COUNT_COL: 'pproperty_countp_aM4lfb94F',
    AVERAGE_DAILY_RATE: 'pdaily_ratep_gd4YqGS99',
    AVERAGE_LIST_PRICE_COL: 'paverage_list_pricep_syRMfBa4b',
    AVERAGE_REVENUE_COL: 'paverage_revenuep_7U04PmqyC',
    AVERAGE_BEDROOMS_COL: 'paverage_bedroomsp_Mn11qqlZk',
    AVERAGE_BATHROOMS_COL: 'paverage_bathroomsp_HsFGSmlp0',
    AVERAGE_PROPERTY_TAXES_COL: 'paverage_property_taxesp_SRVg1tToA',
    AVERAGE_OCCUPANCY_COL: 'paverage_occupancyp_EGqdbFlGU',
    AVERAGE_CAP_RATE: 'paverage_cap_ratep_hVTzSCxeH',
    AVERAGE_GROSS: 'paverage_gross_yieldp_wdDMc1IR2',
    AVERAGE_REVENUE_PER_BEDROOM_COL: 'paverage_revenue_per_bedroomp_WjQ4L3s9J',
    AVERAGE_MORTGAGE_COL: 'paverage_mortgagep_ENH4XgFEP',
    AVERAGE_CASH_ON_CASH_COL: 'paverage_cash_on_cash_returnp_oAFNXc6ea',
    AVERAGE_NOI_COL: 'paverage_noip_HSA7g7H5X',
  },
  DASHBOARD_PAGE: {
    TITLE: 'pdashboardp_2JhS2yHGr',
    TOTAL_NUMBER: 'ptotal_number_of_propertiesp_X5dgHwBKE',
    ALL_PROPERTIES_BUTTON: 'pall_propertiesp_NrQJEabva',
    CASH_ON_CASH_TABLE_TITLE:
      'p_styletext_alignstarttop_5_opportunities_by_cash_on_cash_returnnbspp_jO7OeuRQv',
    CASH_ON_CASH_TABLE_CITY_COL: 'pcityp_yV1pt5tgc',
    CASH_ON_CASH_TABLE_ADDRESS_COL: 'paddressp_eSnbLhfcG',
    CASH_ON_CASH_TABLE_COC_RETURN: 'pcoc_returnp_CliSHct7e',
    NOI_TABLE_TITLE: 'ptop_5_opportunities_by_noip_c7TY9eQcE',
    NOI_TABLE_CITY_COL: 'pcityp_BxzeQUxFR',
    NOI_TABLE_ADDRESS_COL: 'paddressp_E0Mbj1E7i',
    NOI_TABLE_CASH_FLOW_COL: 'pcash_flowp_rH63xjQte',
    RECENTLY_TABLE_TITLE: 'p10_recently_added_propertiesp_WjO4vLDHQ',
    RECENTLY_TABLE_MARKET_NAME_COL: 'pmarket_namep_duyRW9Wyk',
    RECENTLY_TABLE_ADDRESS_COL: 'paddressp_KlBueNQ6P',
    RECENTLY_TABLE_ONE_PERCENT_COL: 'p1_rulep_xRRhGy11E',
    RECENTLY_TABLE_PROJECTED_RENT_COL: 'pprojected_rentp_JcFKUovV0',
    RECENTLY_TABLE_DOWN_PAYMENT_COL: 'pdown_paymentp_q7V0Opcj0',
    RECENTLY_TABLE_LOAN_AMOUNT_COL: 'ploan_amountp_qjFHK2A7O',
    RECENTLY_TABLE_MONTHLY_PAYMENT_COL: 'pmonthly_paymentp_y7sx7Rnie',
    RECENTLY_TABLE_REHUB_COL: 'prehubfurnishing_budgetp_xizKG9Ket',
    RECENTLY_TABLE_CLOSING_COSTS_COL: 'pclosing_costsp_btec5xpWt',
    RECENTLY_TABLE_PROPERTY_TAXES_COL: 'pproperty_taxesp_NU8QmxVnR',
    RECENTLY_TABLE_INVESTMENT_TOTAL_COL: 'pinvestment_totalp_F7DbGojAc',
    RECENTLY_TABLE_CASH_FLOW_COL: 'pyr_1_cash_flowp_sU8aDpyYa',
    RECENTLY_TABLE_CASH_ON_CASH_COL: 'pcash_on_cash_returnp_ZBZm3VARr',
    RECENTLY_TABLE_CAP_RATE: 'pcap_ratep_TDszq0RnD',
    RECENTLY_TABLE_GROSS: 'pgross_yieldp_OS60OSFOR',
    VIEW_MORE_BUTTON: 'pview_morep_W7UEdpUlc',
  },
  VARIABLE_EXPENSES_FORM: {
    LISTING_SITE_FEES_LABEL: 'plisting_site_fees_p_lg47HDOFW',
    MANAGEMENT_LABEL: 'pproperty_management_fee_p_YBWAvrIno',
    REPAIRS_LABEL: 'prepairsmaintenance_p_LA7FLmUrx',
  },
};

export const SNIPPET_TYPES = {
  label: 'Label',
  button: 'Button',
  input: 'Input',
  title: 'Title',
  other: 'Other',
};

export default SNIPPETS;

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, formValues, reduxForm } from 'redux-form';
import { passwordsMustMatch, required } from '../../../utils/validators';
import Checkbox from '../../components/Checkbox/Checkbox';
import InfoAlert from '../../components/InfoAlert/InfoAlert';
import Input from '../../components/Input/Input';
import styles from './RegistrationForm.module.css';
import { getSnippetBySlug } from '../../../helpers/snippets';
import SNIPPETS from '../../../constants/snippets';

function TermsLabel() {
  return (
    <p className={styles.terms}>
      I agree to the{' '}
      <a href="/" className="link">
        terms of service
      </a>
    </p>
  );
}

function RegistrationForm({ handleSubmit, onSubmit, terms, form }) {
  const formSubmitData = useSelector((state) => state.formSubmit);
  const snippets = useSelector((state) => state.snippets.snippets);

  return formSubmitData.form === form && formSubmitData.successMessage ? (
    <div>
      <InfoAlert type="success" message={formSubmitData.successMessage} />
    </div>
  ) : (
    <form onSubmit={handleSubmit((values) => onSubmit(values, form))}>
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.REGISTRATION_PAGE.NAME_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="text"
        name="name"
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.REGISTRATION_PAGE.NAME_LABEL
        )}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.REGISTRATION_PAGE.EMAIL_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="email"
        name="email"
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.REGISTRATION_PAGE.EMAIL_LABEL
        )}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.REGISTRATION_PAGE.PASSWORD_PLACEHOLDER,
          true
        )}
        validate={[required]}
        type="password"
        name="password"
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.REGISTRATION_PAGE.PASSWORD_LABEL
        )}
        component={Input}
      />
      <Field
        placeholder={getSnippetBySlug(
          snippets,
          SNIPPETS.REGISTRATION_PAGE.CONFIRM_PLACEHOLDER,
          true
        )}
        validate={[required, passwordsMustMatch]}
        type="password"
        name="password_confirmation"
        label={getSnippetBySlug(
          snippets,
          SNIPPETS.REGISTRATION_PAGE.CONFIRM_LABEL
        )}
        component={Input}
      />
      <Field name="terms" label={TermsLabel} component={Checkbox} />
      <button
        type="submit"
        disabled={!terms}
        className="button primary-button full-width"
      >
        {getSnippetBySlug(snippets, SNIPPETS.REGISTRATION_PAGE.SIGN_UP_BUTTON)}
      </button>
    </form>
  );
}

RegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'registration-form',
})(formValues({ terms: 'terms' })(RegistrationForm));

import React from 'react';
import { reduxForm, Field, formValues } from 'redux-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  nonNegativeNumber,
  number,
  percent,
  required,
} from '../../../../utils/validators';
import Input from '../../../components/Input/Input';
import styles from './CreateVariableExpenseForm.module.css';
import Card from '../../../components/Card/Card';
import Textarea from '../../../components/TextArea/TextArea';
import Checkbox from '../../../components/Checkbox/Checkbox';
import CURRENCY_TYPES, { CURRENCY_LABEL } from '../../../../constants/currency';
import Multiselect from '../../../components/MultiSelect/MultiSelect';
import {
  formatNumberWithComma,
  onBlurFormatter,
  onFocusFormatter,
  removeCommasFromNumber,
} from '../../../../utils/formatters';
import ROUTES from '../../../../constants/routes';

function CreateVariableExpenseForm({
  handleSubmit,
  onSubmit,
  initialValues,
  change,
  measurements,
}) {
  const parameters = useParams();
  const navigate = useNavigate();

  const onChangeDefaultValuesClick = () => {
    navigate(`${ROUTES.ADMIN.FLUID_DEFAULT_VALUES_BASE_PATH}/${parameters.id}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.section}>
        <Card title="Variable Expense Details">
          <Field
            placeholder="Name"
            validate={[required]}
            type="text"
            name="name"
            label="Name of Variable Expense"
            initialState={initialValues.name}
            component={Input}
          />
          <Field
            placeholder="Currency"
            name="measurements"
            label="Currency"
            disabled={parameters.id}
            validate={[required]}
            isSingle
            options={Object.values(CURRENCY_TYPES).map((value) => ({
              value,
              label: CURRENCY_LABEL[value],
            }))}
            component={Multiselect}
          />

          <Field
            placeholder="Tooltip"
            type="text"
            name="description"
            label="Tooltip"
            initialState={initialValues.description}
            component={Textarea}
          />
          <Field
            name="default"
            label={() => 'Is default'}
            component={Checkbox}
          />
          {measurements && initialValues.type === 'static' && (
            <Field
              name="default_value"
              validate={
                measurements.value === CURRENCY_TYPES.DOLLAR
                  ? [required, number, nonNegativeNumber]
                  : [required, number, percent]
              }
              type="text"
              label="Default value"
              placeholder="Default value"
              prefix={
                measurements.value === CURRENCY_TYPES.DOLLAR
                  ? CURRENCY_LABEL.currency
                  : null
              }
              suffix={
                measurements.value === CURRENCY_TYPES.PERCENT
                  ? CURRENCY_LABEL.percent
                  : null
              }
              onFocusFormatter={(e) => onFocusFormatter(e, change)}
              onBlurFormatter={(value) => onBlurFormatter(value, true)}
              normalize={(value) => removeCommasFromNumber(value)}
              format={(value) => {
                if (!value) {
                  return '';
                }
                return formatNumberWithComma(removeCommasFromNumber(value));
              }}
              component={Input}
            />
          )}
          {parameters.id && initialValues.type === 'fluid' && (
            <button
              type="button"
              className="button outlined-secondary-button"
              onClick={onChangeDefaultValuesClick}
            >
              Change default values
            </button>
          )}
          <button className="button primary-button center">
            Save Variable Expense
          </button>
        </Card>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'create-variable-expense-form',
  enableReinitialize: true,
})(formValues('measurements')(CreateVariableExpenseForm));

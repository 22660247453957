import axios from 'axios';
import ENDPOINTS from '../../constants/endpoints';
import { API } from '../action-types/api';
import { requestStart, requestEnd } from '../actions/api';

const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type !== API) {
      // only apply middleware to actions of type API
      return;
    }

    const { url, method, data, onSuccess, onFailure, form, isMultipart } =
      action.payload;

    let dataOrParameters;

    // Adds support to POST and PUT requests with data
    if (Array.isArray(url)) {
      dataOrParameters = method.map((methodItem) =>
        ['GET', 'DELETE'].includes(methodItem) ? 'params' : 'data'
      );
    } else {
      dataOrParameters = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';
    }

    // axios configs
    axios.defaults.baseURL = 'https://realestate-api.cases-exore.pro/api';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    const token = localStorage.getItem('token');

    dispatch(requestStart()); // Action to notify that the api call is starting.

    let preRequest = Promise.resolve();
    let dataToSend = data;
    if (url === ENDPOINTS.AUTH.REGISTER) {
      preRequest = axios.get(
        'https://realestate-api.cases-exore.pro/sanctum/csrf-cookie'
      );
    } else if (isMultipart) {
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      const formData = new FormData();
      for (const entry of Object.entries(data)) {
        formData.append(entry[0], entry[1]);
      }
      formData.append('_method', 'PUT');
      dataToSend = formData;
    }

    if (Array.isArray(url)) {
      preRequest.then(() => {
        Promise.all(
          url.map((urlItem, index) =>
            axios.request({
              url: urlItem,
              method: method[index],
              ...(dataToSend && {
                [dataOrParameters[index]]: dataToSend[index],
              }),
              headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
              },
            })
          )
        )
          .then((response) => {
            dispatch(requestEnd());

            if (Array.isArray(onSuccess)) {
              for (const [index, data] of response.entries()) {
                onSuccess[index](dispatch, form, data.data);
              }
            } else {
              onSuccess(
                dispatch,
                form,
                response.map((index) => index.data)
              );
            }
          })
          .catch((error) => {
            dispatch(requestEnd());
            onFailure(dispatch, form, error.response);
          });
      });
    } else {
      preRequest.then(() => {
        axios
          .request({
            url,
            method,
            [dataOrParameters]: dataToSend,
            headers: {
              ...(token && { Authorization: `Bearer ${token}` }),
            },
          })
          .then(({ data }) => {
            dispatch(requestEnd());
            onSuccess(dispatch, form, data);
          })
          .catch((error) => {
            dispatch(requestEnd());
            onFailure(dispatch, form, error.response);
          });
      });
    }
  };

export default apiMiddleware;

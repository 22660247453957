import React from 'react';
import styles from './Card.module.css';

function Card({
  title,
  actionButton,
  children,
  className,
  fullWidth,
  isContentVisible = true,
  onClick = () => {},
  disabled,
  active = false,
}) {
  return (
    <div
      className={`${styles.cardWrapper} ${fullWidth ? styles.fullWidth : ''}`}
    >
      {title && (
        <div
          className={`${styles.title} ${active ? styles.active : ''} ${
            disabled ? styles.disabled : onClick ? styles.clickable : ''
          }`}
          onClick={!disabled && onClick}
        >
          {title && <h3 className="subtitle">{title}</h3>}
          <div className={styles.button}>{actionButton}</div>
        </div>
      )}
      {children && isContentVisible && (
        <div className={`${styles.card} ${className || ''}`}>{children}</div>
      )}
    </div>
  );
}

export default Card;

import React from 'react';
import styles from './InfoAlert.module.css';

function InfoAlert({ message, type }) {
  return (
    <div
      className={`${styles.container} ${
        type === 'success' ? styles.success : ''
      }`}
    >
      <p>{message}</p>
    </div>
  );
}

export default InfoAlert;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import ROUTES from '../../../../constants/routes';
import Pagination from '../../../components/Pagination/Pagination';
import styles from './SnippetGroupsPage.module.css';
import Dialog from '../../../components/Dialog/Dialog';
import {
  deleteSnippetGroup,
  getSnippetGroups,
} from '../../../../redux/actions/snippetGroups';
import Breadcrumbs from '../../../components/Breadcrumb/Breadcrumb';

const ITEMS_PER_PAGE = 10;

function SnippetGroupsPage() {
  const currentItems = useSelector((state) => state.snippetGroups.groups);
  const total = useSelector((state) => state.snippetGroups.total);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState(
    +new URLSearchParams(location.search).get('page') || 0
  );

  const getSnippetGroupsData = (page) => {
    dispatch(getSnippetGroups({ page: page + 1, per_page: ITEMS_PER_PAGE }));
  };

  const handlePageClick = (event) => {
    setSelectedPage(event.selected);
    navigate({
      pathname: window.location.pathname,
      search: `?page=${event.selected}`,
    });
  };

  useEffect(() => {
    getSnippetGroupsData(selectedPage);
  }, [selectedPage]);

  const editSnippet = (id) => {
    navigate(`${ROUTES.ADMIN.GROUPS}/${id}/edit`);
  };

  const removeSnippetGroup = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <Dialog
          title="Delete Snippet Group"
          text="Are you sure you want to delete this snippet group?"
          buttons={[
            {
              type: 'button',
              text: 'Delete',
              onClick: () => {
                dispatch(
                  deleteSnippetGroup(id, () =>
                    dispatch(
                      getSnippetGroups({
                        page: selectedPage + 1,
                        per_page: ITEMS_PER_PAGE,
                      })
                    )
                  )
                );
                onClose();
              },
              className: 'secondary-button',
            },
            {
              type: 'button',
              text: 'Cancel',
              onClick: onClose,
              className: 'secondary-button',
            },
          ]}
        />
      ),
    });
  };

  return (
    <div>
      <div className={styles.header}>
        <h2 className="subtitle">Snippet Groups -- {total}</h2>
        <button
          type="button"
          onClick={() => {
            navigate(ROUTES.ADMIN.CREATE_SNIPPET_GROUP);
          }}
          className="button withImg primary-button -thin"
        >
          <span className="material-icons">add</span>
          <span>Create New Snippet Group</span>
        </button>
      </div>
      <Breadcrumbs />
      {total === 0 ? (
        'No snippet groups yet'
      ) : (
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td className={styles.buttons}>
                        <div>
                          <button
                            type="button"
                            className="button action-button"
                            onClick={() => editSnippet(item.id)}
                          >
                            <span className="material-icons">edit</span>
                          </button>
                          <button
                            type="button"
                            className="button action-button"
                            onClick={() => removeSnippetGroup(item.id)}
                          >
                            <span className="material-icons">delete</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        onPageChange={handlePageClick}
        selectedPage={selectedPage}
        pageCount={Math.ceil(total / ITEMS_PER_PAGE)}
      />
    </div>
  );
}

export default SnippetGroupsPage;

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { passwordsMustMatch, required } from '../../../utils/validators';
import InfoAlert from '../../components/InfoAlert/InfoAlert';
import Input from '../../components/Input/Input';
import styles from './ResetPasswordForm.module.css';

function ResetPasswordForm({ handleSubmit, onSubmit, form }) {
  const formSubmitData = useSelector((state) => state.formSubmit);

  return formSubmitData.form === form && formSubmitData.successMessage ? (
    <div className={styles.infoMessage}>
      <InfoAlert type="success" message={formSubmitData.successMessage} />
    </div>
  ) : (
    <form onSubmit={handleSubmit((values) => onSubmit(values, form))}>
      <Field
        placeholder="Enter password"
        validate={[required]}
        type="password"
        name="password"
        label="New password"
        component={Input}
      />
      <Field
        placeholder="Confirm password"
        validate={[required, passwordsMustMatch]}
        type="password"
        name="password_confirmation"
        label="Confirm new password"
        component={Input}
      />
      <button type="submit" className="button primary-button full-width">
        Change Password
      </button>
    </form>
  );
}

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'reset-password-form',
})(ResetPasswordForm);

import React from 'react';
import { reduxForm, Field } from 'redux-form';

import ENDPOINTS from '../../../../constants/endpoints';
import { required } from '../../../../utils/validators';
import Multiselect from '../../../components/MultiSelect/MultiSelect';

function SnippetFilterForm({ onSubmit, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        placeholder="Group"
        name="group_id"
        async
        url={`${ENDPOINTS.DATA.SNIPPET_GROUP}`}
        label="Group"
        component={Multiselect}
      />
      <button className="button primary-button center">Apply Filter</button>
    </form>
  );
}

export default reduxForm({
  form: 'snippet-filter-form',
})(SnippetFilterForm);

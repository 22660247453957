import ENDPOINTS from '../../constants/endpoints';
import {
  SET_DASHBOARD_CONTENT,
  SET_RECENT_PROPERTIES,
  SET_TOP_FIVE_BY_COC,
  SET_TOP_FIVE_BY_NOI,
} from '../action-types/dashboard';
import { apiAction } from './api';

export const getDashboardData = () => {
  return apiAction({
    url: [
      ENDPOINTS.DASHBOARD.TOP_FIVE_NOI,
      ENDPOINTS.DASHBOARD.TOP_FIVE_COC,
      ENDPOINTS.DASHBOARD.RECENT_ADDED_PROPERTIES,
      ENDPOINTS.DASHBOARD.PROPERTIES_COUNT,
    ],
    method: ['GET', 'GET', 'GET', 'GET'],
    onSuccess: (dispatch, _, response) => {
      const data = {
        topByNoi: response[0].data,
        topByCoc: response[1].data,
        recentProperties: response[2].data,
        count: response[3].data.count_properties_system,
      };
      dispatch(setDashboardData(data));
    },
  });
};

export const getTopFiveNoi = (parameters) => {
  return apiAction({
    url: ENDPOINTS.DASHBOARD.TOP_FIVE_NOI,
    method: 'GET',
    onSuccess: (dispatch, _, response) => {
      dispatch(setTopFiveNoi(response.data));
    },
    data: parameters,
  });
};

export const getTopFiveCoc = (parameters) => {
  return apiAction({
    url: ENDPOINTS.DASHBOARD.TOP_FIVE_COC,
    method: 'GET',
    onSuccess: (dispatch, _, response) => {
      dispatch(setTopFiveCoc(response.data));
    },
    data: parameters,
  });
};

export const getRecentProperties = (parameters) => {
  return apiAction({
    url: ENDPOINTS.DASHBOARD.RECENT_ADDED_PROPERTIES,
    method: 'GET',
    onSuccess: (dispatch, _, response) => {
      dispatch(setRecentProperties(response.data));
    },
    data: parameters,
  });
};

export const setTopFiveNoi = (data) => {
  return {
    type: SET_TOP_FIVE_BY_NOI,
    data,
  };
};

export const setTopFiveCoc = (data) => {
  return {
    type: SET_TOP_FIVE_BY_COC,
    data,
  };
};

export const setRecentProperties = (data) => {
  return {
    type: SET_RECENT_PROPERTIES,
    data,
  };
};

export const setDashboardData = (data) => {
  return {
    type: SET_DASHBOARD_CONTENT,
    data,
  };
};

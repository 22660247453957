import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import { setMenuItemActive } from '../../../redux/actions/menu';
import UserProfilePage from '../../pages/UserProfilePage/UserProfilePage';
import Header from '../Header/Header';
import styles from './PrivatePaths.module.css';
import MarketsPage from '../../pages/MarketsPage/MarketsPage';
import PropertiesPage from '../../pages/PropertiesPage/PropertiesPage';
import CreatePropertyPage from '../../pages/CreatePropertyPage/CreatePropertyPage';
import PropertyDetailsPage from '../../pages/PropertyDetailsPage/PropertyDetailsPage';
import HomePage from '../../pages/HomePage/HomePage';
import DesiredReturnMetricsPage from '../../pages/DesiredReturnMetricsPage/DesiredReturnMetricsPage';
import MarketDetailsPage from '../../pages/MarketDetailsPage/MarketDetailsPage';

function PrivatePaths() {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setMenuItemActive(location.pathname));
    if (document.querySelector('#react-confirm-alert')) {
      document.querySelector('#react-confirm-alert').remove();
    }
  }, [location]);
  return (
    <>
      <Header />
      <div className={styles.content}>
        <Routes>
          <Route
            path={ROUTES.USER.PUBLIC_PROPERTY}
            element={<PropertyDetailsPage />}
          />
          <Route path={ROUTES.USER.HOME} element={<HomePage />} />
          <Route path={ROUTES.USER.MARKETS} element={<MarketsPage />} />
          <Route path={ROUTES.USER.PROPERTIES} element={<PropertiesPage />} />
          <Route
            path={ROUTES.USER.CREATE_PROPERTY}
            element={<CreatePropertyPage />}
          />
          <Route
            path={ROUTES.USER.EDIT_PROPERTY}
            element={<CreatePropertyPage />}
          />
          <Route path={ROUTES.USER.PROFILE} element={<UserProfilePage />} />
          <Route
            path={ROUTES.USER.DESIRED_MERTICS}
            element={<DesiredReturnMetricsPage />}
          />
          <Route
            path={ROUTES.USER.PROPERTY_DETAILS}
            element={<PropertyDetailsPage />}
          />
          <Route
            path={ROUTES.USER.MARKET_DETAILS}
            element={<MarketDetailsPage />}
          />
          <Route
            path="*"
            element={
              <Navigate
                replace
                to={
                  user.last_login
                    ? ROUTES.USER.HOME
                    : ROUTES.USER.DESIRED_MERTICS
                }
              />
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default PrivatePaths;

import React from 'react';

export const formatDollarValues = (value, isExpenses = false) => {
  if (isExpenses) {
    return (
      <span className="error">
        (${formatNumericValueWithRounding(Math.abs(value))})
      </span>
    );
  }

  return value < 0 ? (
    <span className="error">(${formatNumericValueWithRounding(-value)})</span>
  ) : (
    `$${formatNumericValueWithRounding(value)}`
  );
};

export const formatPercentValues = (value, withWholeNumberCheck = false) => {
  const isWhole = value === Math.floor(value);
  return isWhole && withWholeNumberCheck
    ? `${value}%`
    : `${formatNumberWithComma(value ? value.toFixed(2) : '0.00')}%`;
};

export const formatNumberWithComma = (value) => {
  if (!value && value !== 0) {
    return value;
  }
  const number =
    String(value)[0] === '-' ? String(value).slice(1) : String(value);

  let s = '';
  let temporary;
  const number_ = number.toString().split('.');
  let n = number_[0];
  while (n.length > 3) {
    temporary = n.slice(Math.max(0, n.length - 3));
    s = `,${temporary}${s}`;
    n = n.slice(0, -3);
  }
  if (n) s = n + s;
  if (number_[1]) s += `.${number_[1]}`;
  return (
    (String(value)[0] === '-' ? '-' : '') +
    (number[number.length - 1] === '.' ? `${s}.` : s)
  );
};

export const removeCommasFromNumber = (value) => {
  if (!value) {
    return value;
  }
  return value.toString().replace(/,/g, '');
};

export const formatNumericValueWithRounding = (value) => {
  return formatNumberWithComma(Math.round(value));
};

// todo replace local formatters in CreatePropertyForm Component

export const onBlurFormatter = (value, optional = false) => {
  const stringValue = String(
    value,
    (+removeCommasFromNumber(stringValue)).toFixed(2)
  );
  if (stringValue === '') {
    return optional ? '' : '0.00';
  }

  return stringValue.length > 0
    ? (+removeCommasFromNumber(stringValue)).toFixed(2)
    : value;
};

export const onFocusFormatter = (e, change) => {
  if (
    String(e.target.value).length > 0 &&
    Number.isInteger(+removeCommasFromNumber(e.target.value))
  ) {
    if (+e.target.value === 0) {
      change(e.target.name, '');
    } else {
      change(
        e.target.name,
        (+removeCommasFromNumber(e.target.value)).toFixed(0)
      );
    }
  }
};

import logo from 'public/assets/images/logo.png';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import { logOut } from '../../../redux/actions/auth';
import { setMenuItemActive } from '../../../redux/actions/menu';
import Avatar from '../Avatar/Avatar';
import Dropdown from '../Dropdown/Dropdown';
import styles from './Header.module.css';

function Header() {
  const user = useSelector((state) => state.auth.user);
  const menu = useSelector((state) => state.menu.menu);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(logOut());
  };

  const goToProfile = () => {
    navigate(ROUTES.USER.PROFILE);
  };

  const goToMetrics = () => {
    navigate(ROUTES.USER.DESIRED_MERTICS);
  };

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.header}>
        <div className={styles.left}>
          {/* <div
            className={styles.logo}
            onClick={() => navigate(ROUTES.USER.HOME)}
          >
            <img src={logo} alt="Application Logo" />
          </div> */}
          <div className={styles.navigation}>
            <ul>
              {menu.map((item, index) => (
                <li key={index}>
                  <NavLink
                    onClick={() => dispatch(setMenuItemActive(item.url))}
                    style={{
                      color: item.active ? 'var(--color-primary)' : '',
                    }}
                    to={item.url}
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.avatar}>
            <Avatar src={user.thumb} />
          </div>
          <Dropdown
            title={user.name}
            customMenuClassName={styles.userDropdown}
            menu={[
              { title: 'Profile', onClick: goToProfile, icon: 'person' },
              {
                title: 'Preferences',
                onClick: goToMetrics,
                icon: 'auto_graph',
              },
              { title: 'Log Out', onClick: logout, icon: 'logout' },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
